import { gql } from '@apollo/client';

export const PAYMENT_ADDED = gql`
subscription paymentCreated($auctionid: String!, $biddernumber: Float) {
  paymentCreated(auctionid: $auctionid, biddernumber: $biddernumber) {
    datetime
    method
    methodrate
    amount
    feeamount
    notes
    type
    paymentid
    biddernumber
    auctionid
    referencenumber
  }
}
`;

export const PAYMENT_DELETED = gql`
subscription paymentDeleted($auctionid: String!, $biddernumber: Float) {
  paymentDeleted (auctionid: $auctionid, biddernumber: $biddernumber)
}
`;