

//TODO: navbar-transparent for non fixed screens
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { getLoggedInUser } from "utils/useToken";
import { useHistory } from "react-router-dom";
import {SubDirectory} from "utils/defaults";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Container,
  NavbarToggler,
} from "reactstrap";

function AdminNavbar(props) {
  // const [collapseOpen] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-black");
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    // if (window.innerWidth < 993 && collapseOpen) {
    //   setcolor("bg-white");
    // } else {
      setcolor("navbar-black");
    // }
  };

  // this function is to open the Search modal

  let history = useHistory();

  const GoToLink = (link) => { 
    if(link === "profile")
      history.push("/" + SubDirectory + "/user-profile");
    else if(link === "companyswitch")
      history.push("/" + SubDirectory + "/switch-company");
    else if(link === "auctionswitch"){
      history.push("/" + SubDirectory + "/switch-auction");
    }
  }

  return (
    <>
      <Navbar id="mainNav" style={{top:0}} className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <NavbarBrand onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
            <div className='mw-profileStats'>
              <span className='mw-NavTitle'>{props.NavBarData.Name}</span> <span className='mw-navLink' onClick={() => GoToLink('profile')}>Edit</span> <br />
              
              {props.NavBarData.CompanyName &&
                <>
                  <span className='mw-NavTitle'>{props.NavBarData.CompanyName}</span> {' '}
                    {getLoggedInUser().px3.main === 13 &&
                      <span className='mw-navLink' onClick={() => GoToLink('companyswitch')}>Change</span> 
                    }
                  <br />
                </>
              }

              {getLoggedInUser().px3.main === 13 && getLoggedInUser().company === 0 &&
                <><span className='mw-navLink' onClick={() => GoToLink('companyswitch')}>Select Company</span> <br /></>
              }

              {props.NavBarData.AuctionName &&
                <>
                <span className='mw-NavTitle'>{props.NavBarData.AuctionName}</span> {' '}
                  {getLoggedInUser().px3.company &&
                    <span className='mw-navLink' onClick={() => GoToLink('auctionswitch')}>Change</span> 
                  }
                <br />
                </>
              }
            </div>
        </Container>
      </Navbar>
 
    </>
  );
}

export default AdminNavbar;
