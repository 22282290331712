import React,  { useEffect, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {ITEM_ADDED } from "../graphql/subscriptions/items";
import { GET_ALL_ITEMS } from "../graphql/queries/items";
import '../assets/css/customAnimation.css';
import { formatNumber } from "utils/functions";
import { DBItemList } from "./auction/dbItemList";
import { GET_DISPLAYBOARD } from "graphql/queries/displayboard";
const { Col, Row, FormGroup, Input, Button } = require("reactstrap");

function useAnimationState(children, resetNewItem){
  const [animatedItems, setItems] = useState(null);
  const itemRef = useRef(animatedItems)
  const setAnimatedItems = (newState)=>{
    itemRef.current = newState;
    return setItems(newState);
  }
  const priorItem = useRef();
  
  function animationEnd(){
    // update the animatedItems to remove the first item
    setAnimatedItems(itemRef.current.slice(1));
    resetNewItem({});
    console.log("ANIMATION ENDED");
  }
  
  useEffect(()=>{
    var key = `${children.key}_animated`;
    
    if(animatedItems == null){
      // initial render of the component does not need a transition animation
      setAnimatedItems([<div style={{opacity:0}} key={key}>{children}</div>]);

      // setAnimatedItems([<div key={key} className={"slideOut"} onAnimationEnd={animationEnd}>{children}</div>]);
      priorItem.current = children;
    } else {
      // on updates to the children, updated the animated chilren array to contain the new element
      // the first element in the array should be wrapped with the slide out animation
      var priorItemKey = `${priorItem.current.key}_animated`;
      setAnimatedItems([<div key={priorItemKey.current} className={"slideOut"} onAnimationEnd={animationEnd}>{priorItem.current}</div>]);
    }
  }, [children])
  
  return animatedItems;
}

function AnimationComponent(props){
  var animatedItems = useAnimationState(props.children, props.resetNewItem);
  return <div className={"animatedContainer"}>
    {animatedItems}
  </div>
}

function CardDisplay(props){
    return (
      <div >
        <p className={"title"}>{props.cardData.name}</p>
        <p>Price: {props.cardData.price ? formatNumber(props.cardData.price) : 0}</p>
        <p>Seller: {props.cardData.sellerbiddernumber}</p>
        <p>Buyer: {props.cardData.buyerbiddernumber}</p>
      </div>
    )
}

function MainDisplay(props){
  const [cardPos, setCardPos] = useState(0);
  
  var currentCard = <CardDisplay cardData={props.cards} key={cardPos}  />;

  if (Object.keys(props.cards).length > 0) {
    return (
        <AnimationComponent  resetNewItem = {props.resetNewItem} >{currentCard}</AnimationComponent>
    )
  }
  else{
    return <></>
  }
}

const DisplayBoard = () =>{
  const [newestItem, setNewItem] = useState({});
  const [dbAuctionID, setDBAuctionID] = useState(undefined)
  const [tmpDbAuctionID, setTmpDBAuctionID] = useState(undefined)
  const [getDisplayBoardAuction] = useLazyQuery(GET_DISPLAYBOARD);

  const LoadDisplayBoard = () => {
    getDisplayBoardAuction({ variables: {code: tmpDbAuctionID}})
    .then((res) => {
      if(res.data) {
        if(res.data.getDisplayBoardAuction !== "0")
          setDBAuctionID(res.data.getDisplayBoardAuction)
      }
    })
  }
  const {data, subscribeToMore } = useQuery(GET_ALL_ITEMS,{
    variables: {auctionid: dbAuctionID},
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    skip: !dbAuctionID ? true : false
  });
  
  useEffect(() => {
    let itemCreateUnsubscribe;
    if(dbAuctionID){
     itemCreateUnsubscribe = subscribeToMore({
        document: ITEM_ADDED,
        variables:{auctionid: dbAuctionID, biddernumber: 0},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          let newItem = subscriptionData.data.itemCreated;
          console.log("New ITEM", newItem);
          setNewItem(newItem);
          
          // const a = {
          //   getAllItems: prev.getAllItems.concat(newItem),
          // };
  
          // const returnResult =  Object.assign({}, prev, a);

          const a = {...prev.getAllItems};
          const mergedResullt = [newItem, a];
          const returnResult = Object.assign({}, prev, { getAllItems: mergedResullt })
          return {
            getAllItems: returnResult
          }
        },
      }); 
    }
  
    if (itemCreateUnsubscribe) return () => itemCreateUnsubscribe()
  }, [subscribeToMore, data, dbAuctionID]);
  

  return (
    <div>
      {dbAuctionID && 
      <>
        <div className='px3-animatedbox'>
          <MainDisplay cards={newestItem} resetNewItem = {setNewItem} />
        </div>

        <Row>
          <Col md="12" className="dbTable">
            <DBItemList  title = {"Auction Item List"} auctionid={dbAuctionID}  /> 
          </Col>
        </Row>
      </>
    }

    {!dbAuctionID && 
      <Row>
        <Col md="4" style={{color:"#FFF"}}>
          <FormGroup>
            <label>Display Board Code:</label>
            <Input
              type="text"
              name="name"
              style={{borderColor:"#FFF"}}
              maxLength={60}
              autoComplete = "off"
              value = {tmpDbAuctionID}
              onChange={e => setTmpDBAuctionID(e.target.value)}
            />
          </FormGroup>

        <Button 
          className="btn-fill" 
          color="info" 
          type="button" 
          onClick={
            () => { LoadDisplayBoard() }
          }
          >
            Load Display Board
          </Button>
        </Col>
      </Row>
    }
    </div>
  )
}

export default DisplayBoard;