import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import { CREATE_ITEM } from "../../graphql/mutations/items";
import { useMutation } from "@apollo/client";
import { getLoggedInUser } from "utils/useToken";
import { handleInputFocus } from "utils/functions";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { SAConfirm } from "utils/functions";
import { PopupError } from "utils/functions";

function AddItem(props){
  const alert = useAlert();
  const inputRef = useRef();
  const inputRef3 = useRef();

  const NoSale =  () => {
    createItem({ variables: { thedata: {
      sellerbiddernumber : itemdata.sellerbiddernumber,
      buyerbiddernumber : 0,
      price: 0.00,
      description: itemdata.description,
      name: itemdata.name + " - No Sale",
      qty: 1,
      lastmodifiedbyid: getLoggedInUser().userid,
      auctionid: getLoggedInUser().auctionid,
      typeid: itemdata.typeid,
      lastmodifiedbyuser: getLoggedInUser().name
    }}})
    .then((data2) => {
        if(!data2.errors){
          ResetInput();
          setResult( () => data2['data']['createItem']['success'])

          if(!stickyItem) inputRef.current.focus();
          else {
            if(props.usersettings.auctionadmin.ItemFields["1"] !== "buyer")
              inputRef3.current.focus()
            else
              inputRef.current.focus()
          }
        }
      }
    )
    .catch((err) => console.log(err));
  }

  const [createItem] = useMutation(CREATE_ITEM, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });

  const [stickySeller, setStickySeller] = useState(0);
  const [stickyItemType, setStickyItemType] = useState(false);
  const [stickyItem, setStickyItem] = useState(false);

  const toggleSticky = (stickywhat) => {
    if(stickywhat === "seller"){
      if(stickySeller === 0 && itemdata.sellerbiddernumber > 0) setStickySeller(itemdata.sellerbiddernumber)
      else {
        setStickySeller(0);
        setStickyItem(false);
      }
    }
    else if(stickywhat === "itemtype"){
      setStickyItemType(!stickyItemType);
      setStickyItem(false);
    }
    else if(stickywhat === "item"){
      setStickyItem(!stickyItem);
      setStickyItemType(false);
      setStickySeller(0);
    }
  }
  

  const ResetInput = () => {
    if(stickyItem){
      setItemData({
        sellerbiddernumber : itemdata.sellerbiddernumber,
        buyerbiddernumber : 0,
        price: itemdata.price,
        description: itemdata.description,
        name: itemdata.name,
        qty: 1, 
        lastmodifiedbyid: getLoggedInUser().userid,
        auctionid: getLoggedInUser().auctionid,
        typeid: itemdata.typeid,
        lastmodifiedbyuser: getLoggedInUser().name
      });
    }
    else {
      setItemData({
        sellerbiddernumber : stickySeller,
        buyerbiddernumber : 0,
        price: 0.00,
        description: '',
        name: '',
        qty: 1, 
        lastmodifiedbyid: getLoggedInUser().userid,
        auctionid: getLoggedInUser().auctionid,
        typeid: stickyItemType ? itemdata.typeid : '0',
        lastmodifiedbyuser: getLoggedInUser().name
      });
    }

    setResult( () => [])
  }

  const ForceResetInput = () => {
      setItemData({
        sellerbiddernumber : 0,
        buyerbiddernumber : 0,
        price: 0.00,
        description: '',
        name: '',
        qty: 1, 
        lastmodifiedbyid: getLoggedInUser().userid,
        auctionid: getLoggedInUser().auctionid,
        typeid: '0',
        lastmodifiedbyuser: getLoggedInUser().name
      });

    setStickyItem(false);
    setStickyItemType(false);
    setStickySeller(0);

    setResult( () => [])

    inputRef.current.focus();
  }

  const [itemdata, setItemData] = useState({
    sellerbiddernumber : stickySeller,
    buyerbiddernumber : 0,
    price: 0.00,
    description: '',
    name: '',
    qty: 1,
    lastmodifiedbyid: getLoggedInUser().userid,
    auctionid: getLoggedInUser().auctionid,
    typeid: '0',
    lastmodifiedbyuser: getLoggedInUser().name
  });

  const [lastitemdata, setLastItemData] = useState({
    sellerbiddernumber : stickySeller,
    buyerbiddernumber : 0,
    price: 0.00,
    description: '',
    name: '',
    qty: 1,
    lastmodifiedbyid: getLoggedInUser().userid,
    auctionid: getLoggedInUser().auctionid,
    typeid: '0',
    lastmodifiedbyuser: getLoggedInUser().name
  });

  const [result, setResult] = useState([]);

  const ConfirmAddItem = () => {
    setResult([]);
    let tmpItemData = {...itemdata};
    if(tmpItemData.buyerbiddernumber === 0){
      tmpItemData.price = 0;
      tmpItemData.name = tmpItemData.name + " - No Sale";
    }
    createItem({ variables: { thedata: tmpItemData } })
    .then((data2) => {
        if(!data2.errors){
          setLastItemData(itemdata);
          ResetInput();
          setResult( () => data2['data']['createItem']['success'])
          if(!stickyItem) inputRef.current.focus();
          else {
            if(props.usersettings.auctionadmin.ItemFields["1"] !== "buyer")
              inputRef3.current.focus()
            else
              inputRef.current.focus()
          }
        }
      }
    )
    .catch((err) => console.log(err));
  }
  const AddItem = () => {
    if(itemdata.price > 50 && itemdata.typeid === "0")
      SAConfirm("Confirm", "This item price is over $50.00. Click cancel if you meant to mark this as a different item type.", ConfirmAddItem)
    else
      ConfirmAddItem();
  }

  const RecallLastItem = () => {
    setItemData(lastitemdata);
  }

  useEffect(() => {
    if(result){
      if(result.length > 0){
        result.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [result, alert]);

  useEffect(() => {
    console.log("!!!");
      if(!stickyItem) inputRef.current.focus();
      else {
        if(props.usersettings.auctionadmin.ItemFields["1"] !== "buyer")
          inputRef3.current.focus()
        else
          inputRef.current.focus()
      }
  }, [props.itemUpdated, stickyItem]);

  useEffect(() => {
    inputRef.current.focus();
  }, [stickyItemType, stickySeller]);

  const NameField = () => {
    return(
      <FormGroup>
        <label>* Item:</label>
        <input
          className="form-control"
          // ref={inputRef}
          ref={props.usersettings.auctionadmin.ItemFields["1"] === "name" ? inputRef : null}
          placeholder="Item"
          type="text"
          autoFocus
          name="name"
          readOnly = {stickyItem}
          maxLength={60}
          value={itemdata.name}
          autoComplete = "off"
          onChange={ (e) => {
            setItemData(prevState => {
              return { ...prevState, name: e.target.value}
            })
          }}
        />
      </FormGroup>
    )
  }

  const SellerField = () => {
    return (
      <FormGroup>
        <label>* Seller Number:</label>
        <Input
          value={itemdata.sellerbiddernumber}
          placeholder="Seller Number"
          type="number"
          ref={props.usersettings.auctionadmin.ItemFields["1"] === "seller" ? inputRef : null}
          name="sellerbiddernumber"
          autoComplete = "off"
          onFocus={handleInputFocus}
          readOnly = {stickySeller > 0 ? true : stickyItem ? true : false}
          onChange={ (e) => {
            if(e.target.value !== ""){
              setItemData(prevState => {
                return { ...prevState, sellerbiddernumber: parseInt(e.target.value)}
              })
            }
            else{
              setItemData(prevState => {
                return { ...prevState, sellerbiddernumber: parseInt(0)}
              })
            }
          }}
        />
      </FormGroup>
    )
  }

  const QuantityField = () => {
    return(
      <FormGroup>
        <label>* Quantity: </label>
        <Input
          value={itemdata.qty}
          placeholder="Qty"
          type="number"
          ref={props.usersettings.auctionadmin.ItemFields["1"] === "quantity" ? inputRef : null}
          onFocus={handleInputFocus}
          name="qty"
          autoComplete = "off"
          onChange={ (e) => {
            if(e.target.value !== ""){
              setItemData(prevState => {
                return { ...prevState, qty: parseInt(e.target.value)}
              })
            }
            else{
              setItemData(prevState => {
                return { ...prevState, qty: parseInt(1)}
              })
            }
          }}
        />
      </FormGroup>
    )
  }

  const BuyerField = () => {
    return(
      <FormGroup>
        <label>Buyer Number: </label>
        <input
          value={itemdata.buyerbiddernumber}
          placeholder="Buyer Number"
          type="number"
          ref={props.usersettings.auctionadmin.ItemFields["1"] === "buyer" ? inputRef : inputRef3}
          className="form-control"
          name="buyerbiddernumber"
          onFocus={handleInputFocus}
          autoComplete = "off"
          onChange={ (e) => {
            if(e.target.value !== ""){
              setItemData(prevState => {
                return { ...prevState, buyerbiddernumber: parseInt(e.target.value)}
              })
            }
            else{
              setItemData(prevState => {
                return { ...prevState, buyerbiddernumber: parseInt(0)}
              })
            }
          }}
        />
      </FormGroup>
    )
  }

  const PriceField = () => {
    return (
      <FormGroup>
        <label >
          * Price:
        </label>
        <Input
          placeholder="0" 
          type="number" 
          step="0.01"
          ref={props.usersettings.auctionadmin.ItemFields["1"] === "price" ? inputRef : null}
          name="price"
          readOnly = {stickyItem}
          value={itemdata.price}
          autoComplete = "off"
          onFocus={handleInputFocus}
          onChange={(e) => {
            if(e.target.value !== ""){
              setItemData(prevState => {
                return { ...prevState, price: parseFloat(e.target.value)}
              })
            }
            else{
              setItemData(prevState => {
                return { ...prevState, price: parseFloat(0)}
              })
            }
          }}
          />
      </FormGroup>
    )
  }

  const TypeField = () => {
    return(
      <FormGroup>
        <label>Type:</label>
        <select className='form-control'
          name="typeid"
          ref={props.usersettings.auctionadmin.ItemFields["1"] === "type" ? inputRef : null}
          readOnly={stickyItemType ? true : stickyItem ? true : false}
          value = {itemdata.typeid}
          onChange={ (e) => {
            setItemData(prevState => {
              return { ...prevState, typeid: e.target.value}
            })
          }}
        >
          <option value='0'>Normal</option>
          {props.types && props.types.getAllTypes && 
              props.types.getAllTypes.map((type) => {
              return(
                <option key={type.typeid} value={type.typeid}>{type.name}</option>
              )
            })
          }
        </select>
      </FormGroup>
    )
  }

  const DescriptionField = () => {
    return (
      <FormGroup>
        <label>Description (Shift + Enter for new line):</label>
        <Input
          cols="80"
          value={itemdata.description}
          placeholder="Here can be your description"
          rows="4"
          ref={props.usersettings.auctionadmin.ItemFields["1"] === "description" ? inputRef : null}
          type="textarea"
          name="description"
          readOnly = {stickyItem}
          autoComplete = "off"
          onChange={ (e) => {
            setItemData(prevState => {
              return { ...prevState, description: e.target.value}
            })
          }}
        />
      </FormGroup>
    )
  }

  const ItemFields = {
    "name" : NameField(),
    "seller": SellerField(),
    "quantity": QuantityField(),
    "buyer": BuyerField(),
    "price": PriceField(),
    "type": TypeField(),
    "description": DescriptionField(),
  }

  return (
    <Card>
    <CardHeader>
      <CardTitle tag="h4">
        Add New Item
        <br />
         <span className="float-right ">
          <CustomInput
            checked={stickySeller > 0 ? true : false}
            className="table-search-switch custom2"
            type="switch"
            name="customSwitch5"
            id="customSwitch5"
            onChange={() => toggleSticky("seller")}
            label="Sticky Seller"
          />
        </span>
    
        <span className="float-right ">
          <CustomInput
            checked={stickyItemType}
            className="table-search-switch custom2"
            type="switch"
            name="customSwitch7"
            id="customSwitch7"
            onChange={() => toggleSticky("itemtype")}
            label="Sticky Type"
          />
        </span>

        <span className="float-right ">
          <CustomInput
            checked={stickyItem}
            className="table-search-switch custom2"
            type="switch"
            name="customSwitch9"
            id="customSwitch9"
            onChange={() => toggleSticky("item")}
            label="Sticky Item"
          />
        </span>

      </CardTitle>
    </CardHeader>
      <CardBody>
        <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => AddItem() }>
          <Form>
            <Row>
                <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Auction ID (disabled)</label>
                  <Input
                    defaultValue={getLoggedInUser().auctionid}
                    disabled
                    placeholder="Auction"
                    type="text"
                    autoComplete = "off"
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Auction Name (disabled)</label>
                  <Input
                    defaultValue={ getLoggedInUser().auctionlist[(getLoggedInUser().auctionlist).map(a => a.auctionid).indexOf(getLoggedInUser().auctionid)].auctionname }
                    disabled
                    placeholder="Auction"
                    type="text"
                    autoComplete = "off"
                  />
                </FormGroup>
              </Col>
              </Row>
              <Row>
              <Col className="pr-md-1" md="4">
                {ItemFields[props.usersettings.auctionadmin.ItemFields["1"]]}
                {/* { ItemFields["name"] } */}
              </Col>
              <Col  md="4">
              {ItemFields[props.usersettings.auctionadmin.ItemFields["2"]]}
                {/* { ItemFields["seller"]} */}
              </Col>
              <Col md="4">
              {ItemFields[props.usersettings.auctionadmin.ItemFields["3"]]}
                {/* { ItemFields['quantity']} */}
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="4">
              {ItemFields[props.usersettings.auctionadmin.ItemFields["4"]]}
                {/* { ItemFields['buyer'] } */}
              </Col>
              <Col  md="4">
              {ItemFields[props.usersettings.auctionadmin.ItemFields["5"]]}
                {/* { ItemFields['price'] } */}
              </Col>
              <Col md="4">
              {ItemFields[props.usersettings.auctionadmin.ItemFields["6"]]}
                {/* { ItemFields['type'] } */}
              </Col>
            </Row>
            <Row>
              <Col md="12">
              {ItemFields[props.usersettings.auctionadmin.ItemFields["7"]]}
                {/* { ItemFields['description'] } */}
              </Col>
            </Row>
          </Form>
        </KeyboardEventHandler>
      </CardBody>
      <CardFooter>
        <Button 
        className="btn-fill" 
        color="info" 
        type="button" 
        onClick={e => {
          e.preventDefault();
          AddItem();
        }}
        >
          Add Item
        </Button>

        <Button 
        className="btn-fill" 
        color="danger" 
        type="button" 
        style={{float:'right'}}
        onClick={ ()=>NoSale()}
        >
          No Sale
        </Button>

        <Button 
        className="btn-fill" 
        color="info" 
        type="button" 
        style={{float:'right'}}
        onClick={e => {
          e.preventDefault();
          ForceResetInput();
        }}
        >
          Reset Form
        </Button>

      {lastitemdata.name !== "" ? 
        <Button 
        className="btn-fill" 
        color="success" 
        type="button" 
        style={{float:'right', color:"black"}}
        onClick={e => {
          e.preventDefault();
          RecallLastItem();
        }}
        >
          Recall Last Item
        </Button> 
      : null }
      </CardFooter>
    </Card>
  )
}
export default AddItem;