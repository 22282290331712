import { getLoggedInUser } from "utils/useToken";
import { GET_PAYMENT_METHODS } from "graphql/queries/paymentmethods";
import { GET_REPORT_PAYMENTS } from "graphql/queries/payment";
import { useEffect, useState } from "react";
import { formatNumber } from "utils/functions";
import { useLazyQuery, useQuery } from "@apollo/client";
import XTable from "components/custom/table";
import { GET_UNSOLD_ITEMS } from "graphql/queries/items";
import { GET_ALL_COMPANY_USERS } from "graphql/queries/users";
import { ClubMemberID } from "utils/defaults";

const {  CardBody, Card, Col, Row, FormGroup, CardHeader, CardTitle } = require("reactstrap");

function AttendanceReport(){
  const { data: cud, loading: cul, error: cuerror, onError:  cuonerror, subscribeToMore, refetch } = useQuery(GET_ALL_COMPANY_USERS,{
    variables: {
      companyid: getLoggedInUser().company,
      auctionid: getLoggedInUser().auctionid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [auctioncustomerlist, setAuctionCustomerList] = useState([]);

  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      Cell: (props) =>(
        <span>{props.row.original.firstname + " " + props.row.original.lastname}</span>
      )
    },
    {
      Header: "Address",
      accessor: "price",
      className: "t-cell-2 text-centered",
      Cell: (props) =>(
        <span>{props.row.original.address + " " + props.row.original.city + " " + props.row.original.state + ", "+ props.row.original.zip}</span>
      )
    },
    {
      Header: "Phone",
      accessor: "phone",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "Email",
      accessor: "email",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "Club Member",
      accessor: "clubmember",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "Email Opt In",
      accessor: "emailoptin",
      className: "t-cell-3 text-centered mwTable-15",
      Cell: (props) =>(
        <span>{props.value === 1 ? "Yes" : "No" }</span>
      )
    },
  ];

  useEffect(() => {
    const onCompleted = (cud) => { 
      if(cud){
        //map auction customer list
        let auctioncustomerlist = [];

        cud.getAllUserCompany.map((value, key) => {
          if(value.auctionlist){
            const main = value.auctionlist.filter(a => a.auctionid === getLoggedInUser().auctionid);
              if(main){
                main.map((value2, key2) => {
                  const ClubMember = (value2.types.find((e) => e === ClubMemberID) ? "Yes" : "No");
                  const a = Object.assign({}, value, {biddernumber: value2.biddernumber, auctioncustomerid: value2.id, clubmember: ClubMember})
                  return ( auctioncustomerlist.push(a) );
                })
              }
            }

            return true
          })
            setAuctionCustomerList(() => auctioncustomerlist);
      }
    }
    if (onCompleted || cuonerror) {
      if (onCompleted && !cul && !cuerror) {
        onCompleted(cud);
      } else if (cuonerror && !cul && cuerror) {
        cuonerror(cuerror);
      }
    }
  }, [cul, cud, cuerror, cuonerror]);

  return (
    <div className="content">
      <Card>
      <CardHeader>
        <CardTitle tag="h4">Auction Attendance</CardTitle>
      </CardHeader>
        <CardBody>

            {auctioncustomerlist &&
              <XTable  defaultPageSize={9999} defaultSort = {[{ id: "clubmember", desc: true}] } columns={listHeader} loading={false} data={auctioncustomerlist} filterbutton={false} />
            }
        </CardBody>
      </Card>
    </div>
  )
}

export default AttendanceReport;