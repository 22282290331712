import { gql } from '@apollo/client';

export const USER_COMPANY_ADDED = gql`
subscription userCreated($companyid: String!) {
  userCreated(companyid: $companyid) {
    userid
    firstname
    lastname
    email
    address
    address2
    city
    state
    zip
    country
    phone
    emailoptin
    notes
    datetimecreated
    datetimelastlogin
    securitylevel
    active
    lastupdated
    lastmodifiedbyid
    username
    ucactive
    auctionlist{
      auctionid
      biddernumber
      id
      types
      active
    }
  }
}
`;

export const USER_UPDATED = gql`
subscription userUpdated($companyid: String!) {
  userUpdated(companyid: $companyid) {
    userid
    firstname
    lastname
    email
    address
    address2
    city
    state
    zip
    country
    phone
    emailoptin
    notes
    datetimecreated
    datetimelastlogin
    securitylevel
    active
    lastupdated
    lastmodifiedbyid
    username
    ucactive
    auctionlist{
      auctionid
      biddernumber
      id
      types
      active
    }
  }
}
`;