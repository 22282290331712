export const DefaultUserPassword = "Px3Auction2022$";
export const SubDirectory = "auction";

export const PRODUCTION = true;

export const DevWebSocketURL = "ws://localhost:9000/subscriptions";
export const DevHttpURL = "http://localhost:9000/graphql";

export const SellerType = {
  typeid: "DF0D3BFB-CC0F-4DB6-A74C-0DF7BE132AC4",
  name: "Seller",
  description: "Customer is a Seller",
  active: true,
  target: 2
  // lastmodifiedbyid
  // lastupdated
}

export const BuyerType = {
  typeid: "351FBBC9-C0DE-4E22-90B4-767B268F40E7",
  name: "Buyer",
  description: "Customer is a Buyer",
  active: true,
  target: 2
  // lastmodifiedbyid
  // lastupdated
}

export const ClubMemberID = "8AC66551-BEAC-4484-936D-E84C928F614F";