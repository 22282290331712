import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalFooter,
  CustomInput
} from "reactstrap";
import { getLoggedInUser } from "utils/useToken";
import { useAlert } from "react-alert";
import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import XTable from "components/custom/table";
import { GET_TYPES } from "graphql/queries/types";
import {TYPE_ADDED, TYPE_UPDATED } from "../../graphql/subscriptions/types";
import { UPDATE_TYPE, CREATE_TYPE } from "../../graphql/mutations/types";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";

function TypeList(props){
  const alert = useAlert();

  const { data: td, loading: tl, error: terror, onError:  tonerror, subscribeToMore, refetch } = useQuery(GET_TYPES,{
    variables: {
      companyid: getLoggedInUser().company,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [livedata, setLiveData] = useState(true);
  const toggleLiveData = () => {
    if(!livedata)  refetch()
    setLiveData(!livedata);
  };

  const [typelist, setTypeList] = useState([{}]);
  const [createType] = useMutation(CREATE_TYPE, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  const [updateType] = useMutation(UPDATE_TYPE, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  
  const [typeError, setTypeError] = useState([]);
  const [modal, setModal] = useState(false);
  const [typemodaldata, setModalTypeData] = useState({
    name : '',
    description : '',
    target: 1,
    active : 'true',
  });


  const RowClick = (t) => SetWindow("EditAuction", t);
  
  const toggle = () => setModal(!modal);

  const SetWindow = (actionid, t = undefined) => {
    // let actionid = "";
    // actionid = event.target.getAttribute('actionid');
    // if(actionid == null)
      // actionid = event.target.parentNode.getAttribute('actionid');

    if(actionid === "NewType"){
      setModalTypeData({
        name : '',
        description : '',
        target: 1,
        active : true,
        buttonTitle: 'Create Type',
        buttonFunction: newType,
      })
    }
    else if(actionid === "EditType"){
      // let editTypeId = event.target.parentNode.getAttribute('typeid');
      let editTypeId = t.original.typeid
      if(editTypeId !== undefined){
        const index = typelist.map(type => type.typeid).indexOf(editTypeId);
        setModalTypeData(() => typelist[index]);
        setModalTypeData(prevState => {
          return { ...prevState, buttonTitle: "Update Type", buttonFunction: editType}
        })
      }
    }
    toggle();
  }

  const newType = (t) => {
    setTypeError([]);
    let ActiveStatus = false;
    if(t.active === 'true' || t.active === true) ActiveStatus = true;

    const newtype = {
      companyid: getLoggedInUser().company,
      name: t.name,
      target: parseFloat(t.target),
      description: t.description,
      active: ActiveStatus,
      lastmodifiedbyid: getLoggedInUser().userid,
    };

      createType({ variables: { thedata: newtype } }).then( (res) => {
        if(!res.errors){
          setTypeError(() => ["Type Created Successfully"]);
          setModal(false);
          if(props.AuctionRefetch) props.AuctionRefetch();
        }
      })
  }

  const editType = (t) =>{
    setTypeError([]);
    let ActiveStatus = false;
    if(t.active === 'true' || t.active === true) ActiveStatus = true;

    const updatetype = {
      typeid: t.typeid,
      companyid: getLoggedInUser().company,
      name: t.name,
      target: parseInt(t.target),
      description: t.description,
      active: ActiveStatus,
      lastmodifiedbyid: getLoggedInUser().userid,
    };

    updateType({
      variables: {thedata: updatetype}
    }).then( (res) => {
      if(!res.errors){
        setTypeError(() => ["Type Updated Successfully"]);
        setModal(false);
      }
    })
  }

  useEffect(() => {
    if(typeError){
      if(typeError.length > 0){
        typeError.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [typeError, alert]);

  useEffect(() => {
    const onCompleted = (td) => { 
      if(td){
        setTypeList( () => td.getAllTypes);
      }
    }
    if (onCompleted || tonerror) {
      if (onCompleted && !tl && !terror) {
        onCompleted(td);
      } else if (tonerror && !tl && terror) {
        tonerror(terror);
      }
    }
  }, [tl, td, terror, tonerror]);


  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
      className: "t-cell-2 text-centered",
    },
    {
      Header: "Target",
      accessor: "target",
      className: "t-cell-4 text-center",
      Cell: (props) => (
        (props.value === 1) ? "Item" : "User"
      )
    },
    {
      Header: "Status",
      accessor: "active",
      className: "t-cell-4 text-center mwTable-15",
      Cell: (props) => (
        (props.value === true) ? "Active" : "Not Active"
      )
    },
    {
      Header: "Edit",
      accessor: 'typeid',
      className: "t-cell-4 text-center mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) => (
        //onClick={ toggle }
        <span typeid={props.value} onClick={() => SetWindow("EditType", props.row)} ><i className="tim-icons icon-pencil"></i></span>
      )
    },
  ];

  //subscriptions

useEffect(() => {
  let typeCreateUnsubscribe;

  if(livedata){
   typeCreateUnsubscribe = subscribeToMore({
      document: TYPE_ADDED,
      variables:{companyid: getLoggedInUser().company},
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        const newType = subscriptionData.data.typeCreated;
        
        const mergedResullt = [newType, ...prev.getAllTypes];
        const returnResult = Object.assign({}, prev, { getAllTypes: mergedResullt })
        return {
          getAllTypes: returnResult
        }
      },
    }); 
  }

  if (typeCreateUnsubscribe) return () => typeCreateUnsubscribe()
}, [subscribeToMore, td, livedata]);


useEffect(() => {
  let typeUpdateUnsubscribe;

  if(livedata){
   typeUpdateUnsubscribe = subscribeToMore({
      document: TYPE_UPDATED,
      variables:{companyid: getLoggedInUser().company},
      updateQuery: (prev, { subscriptionData }) => {
        const newType = subscriptionData.data.typeUpdated;
        let MainList = [...prev.getAllTypes];
        const index = MainList.map(type => type.typeid).indexOf(newType.typeid);
  
        MainList[index] = newType;
        return {
          getAllTypes: MainList
        }
      }
    }); 
  }

  if (typeUpdateUnsubscribe) return () => typeUpdateUnsubscribe()
}, [subscribeToMore, td, livedata]);

// useEffect(() => {
//   let typeDeleteUnsubscribe;

//   if(livedata){
//    typeDeleteUnsubscribe = subscribeToMore({
//       document: TYPE_DELETED,
//       variables:{companyid: getLoggedInUser().company},
//       updateQuery: (prev, { subscriptionData }) => {
//         const newType = subscriptionData.data.typeDeleted;
//         let OldList = [...prev.getAllTypes];
//         const newList = OldList.filter((type) => type.typeid !== newType);  //typeDeleted6
//         return {
//           getAllTypes: newList
//         };
//       },
//     }); 
//   }

//   if (typeDeleteUnsubscribe) return () => typeDeleteUnsubscribe()
// }, [subscribeToMore, td, livedata]);

//
 
  return(
<>
  <Card>
    <CardHeader>
        <Button 
          className="btn-fill" 
          color="info" 
          type="button" 
          onClick={() => SetWindow("NewType")}
          style={{float:'right'}}
          >
          Add Type
        </Button>
        <CardTitle tag="h4">Types</CardTitle>
    </CardHeader>
    <CardBody>
      {/* <span className="float-left ">
        <CustomInput
          checked={livedata}
          className="table-search-switch"
          type="switch"
          name="customSwitch4"
          id="customSwitch4"
          onChange={toggleLiveData}
          label="Show Live Data"
        />
      </span> */}
        {typelist &&
          <XTable RowClick={RowClick} columns={listHeader} loading={false} data={typelist} filterbutton={props.filter} />
        }
    </CardBody>
    <CardFooter>
    
    </CardFooter>
  </Card>

  {typemodaldata &&
    <Modal className='mw-modalItem' isOpen={modal} toggle={toggle}  modaldata = {typemodaldata} autoFocus={true}>
      <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => typemodaldata.buttonFunction(typemodaldata) }>  
        <ModalBody>
          <Row>
          <Col md="12">
            <Card>
            <CardHeader>
              <CardTitle tag="h4">Type</CardTitle>
            </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>* Name:</label>
                        <Input
                          value={typemodaldata.name}
                          placeholder="Name"
                          type="text"
                          autoComplete = "off"
                          autoFocus
                          onChange={ (e) => {
                            setModalTypeData(prevState => {
                              return { ...prevState, name: e.target.value}
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>* Target:</label>
                        <select className='form-control'
                          name="target"
                          value={typemodaldata.target}
                          onChange={ (e) => {
                            setModalTypeData(prevState => {
                              return { ...prevState, target: e.target.value}
                            })
                          }}
                        >
                          <option value={1}>Item</option>
                          <option value={2}>User</option>
                          
                        </select>
                      </FormGroup>
                    </Col>
                    </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>* Status:</label>
                        <select className='form-control'
                          name="typeid"
                          value={typemodaldata.active}
                          onChange={ (e) => {
                            setModalTypeData(prevState => {
                              return { ...prevState, active: e.target.value}
                            })
                          }}
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                          
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Description  (Shift + Enter for new line):</label>
                        <Input
                          cols="80"
                          value={typemodaldata.description}
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                          name="description"
                          autoComplete = "off"
                          onChange={ (e) => {
                            setModalTypeData(prevState => {
                              return { ...prevState, description: e.target.value}
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          </Row>
      
        </ModalBody>
        <ModalFooter>
          <Button 
            className="btn-fill" 
            color="info" 
            type="button" 
            onClick = {() => typemodaldata.buttonFunction(typemodaldata)}
          >{typemodaldata.buttonTitle} </Button> {' '} 

          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </KeyboardEventHandler>
    </Modal>
}
</>
  )
}
export default TypeList;