
import { useMutation, useQuery } from "@apollo/client";
import { useAlert } from "react-alert";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { handleInputFocus } from "utils/functions";
import { getLoggedInUser } from "utils/useToken";
import { GET_PAYMENT_METHODS } from "graphql/queries/paymentmethods";
import { ADD_PAYMENT } from "graphql/mutations/payment";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";
import { maxLengthCheck } from "utils/functions";

function NewPayment(props){
  const alert = useAlert();
  const inputRef = useRef()

  const { data: pmd, loading: pml, error: pmerror, onError:  pmonerror } = useQuery(GET_PAYMENT_METHODS,{
    variables: {
      companyid: getLoggedInUser().company,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [createPayment] = useMutation(ADD_PAYMENT, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });

  const [pmlist, setPmList] = useState([]);
  const [dynostats, setDynoStats] = useState({});
  const [currentrate, setRate] = useState(0);

  const [result, setResult] = useState([]);
  const [paymentdata, setpaymentdata] = useState({
    notes: '',
    referencenumber: 0,
    amount: 0,
    methodid: "0",
  });

  const UpdatePaymentData = (e) => {
    let value = e.target.value;

    if(e.target.type === 'number'){
      if(value === '') value = 0;
      else value = parseFloat(value);
    }
    setpaymentdata(prevState => {
      return { ...prevState, [e.target.name]: value}
    })
  }

  const UpdateFee = (e) => {
    let Fee = 0;
    if(e.target.value !== 0)
      Fee = pmlist.find(p => p.paymentmethodid === e.target.value).rate / 100;
      
    setRate(Fee);
    const AmountRequired = parseFloat(  parseFloat(paymentdata.amount) + parseFloat(paymentdata.amount) * Fee ).toFixed(2);

     setDynoStats(prevState => {
      return { 
        ...prevState, 
        amountrequired: AmountRequired
      }
    })
  }

  const AddPayment = () => {
    console.log(props.stats.totalremaining);
    if(parseFloat(paymentdata.amount) > Math.abs(parseFloat(props.stats.totalremaining))){
      // setResult(() => ["Payment is over the amount required"])
      PopupError("Payment is over the amount required");
    }
    else{
      const sendData = {
          auctionid: getLoggedInUser().auctionid,
          userid: props.data.userid,
          methodid: paymentdata.methodid,
          amount: parseFloat(paymentdata.amount),
          type: props.stats.totalremaining > 0 ? 'Payout' : 'Collection',
          notes: paymentdata.notes,
          lastmodifiedbyid: getLoggedInUser().userid,
          biddernumber: props.data.auctionlist.find(n => n.auctionid === getLoggedInUser().auctionid).biddernumber,
          feeamount: parseFloat(dynostats.paymentfees),
          referencenumber: parseFloat(paymentdata.referencenumber) ,
          amount2: parseFloat(dynostats.newamountremaining)
        }
      createPayment({
        variables: {thedata: sendData}
      }).then( (res) => {
        if(!res.errors){
        setResult(() => ["Payment Added Successfully"]);

          setpaymentdata(prevState => {
            return { 
              amount: 0,
              notes: '',
              methodid: "0",
              referencenumber: 0,
            }
          })
          inputRef.current.focus();
          setRate(0);
          }
        }
      );
    }
  }

  useEffect(() => {
    console.log(currentrate);
    const FeesFromPayment = (parseFloat(paymentdata.amount) * parseFloat(currentrate));
    const AmountRemaining =  parseFloat(Math.abs(props.stats.totalremaining)) -  parseFloat(paymentdata.amount);
    const AmountWithPayment = parseFloat(parseFloat(paymentdata.amount) + parseFloat(FeesFromPayment));
    console.log("Setting Dyno", props.stats.totalremaining);
    setDynoStats(prevState => {
      return { 
        ...prevState, 
        amountrequired: AmountWithPayment.toFixed(2),
        newamountremaining: AmountRemaining.toFixed(2),
        paymentfees: FeesFromPayment.toFixed(2)
      }
    })
  }, [currentrate, paymentdata.amount, dynostats.amountrequired]);

  useEffect(() => {
      if(props.stats) {
        setDynoStats(prevState => {
          return { ...prevState, paymentfees: 0, newamountremaining: Math.abs(props.stats.totalremaining).toFixed(2), amountrequired:parseFloat(0).toFixed(2)}
        })
      }
  }, [props.stats]);

useEffect(() => {
  if(result){
    if(result.length > 0){
      result.map((x) => {
        return alert.show(x);
      })
    }
  }
}, [result, alert]);

useEffect(() => {
  const onCompleted = (pmd) => { 
    if(pmd){
      //value={ props.stats.totalremaining > 0 ? 'Debit - Auction Pay Out' : 'Credit - Auction Collect' }
      console.log("props stats", props.stats)
      if(props.stats.totalremaining > 0)
        setPmList( () => pmd.getAllPaymentMethods.filter((e) => e.type === 1));
      else
        setPmList( () => pmd.getAllPaymentMethods.filter((e) => e.type === 2));
    }
  }
  if (onCompleted || pmonerror) {
    if (onCompleted && !pml && !pmerror) {
      onCompleted(pmd);
    } else if (pmonerror && !pml && pmerror) {
      pmonerror(pmerror);
    }
  }
}, [pml, pmd, pmerror, pmonerror, props.stats]);
  return (
    <>
    {props.data &&
      <Card>
        <CardHeader>
          <CardTitle tag="h4">New Payment
          {props.editmode &&
            <>
              <br />
              <Button style={{float:"right"}} color="info" onClick={() => AddPayment()}>Add Payment</Button>
            </>
          }
          </CardTitle>
        </CardHeader>
        <CardBody>
          <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => AddPayment() }>
            <Form>
                <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                        <Row>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Name</label>
                              <Input
                                type="text"
                                name="name"
                                value={props.data.firstname + ' ' + props.data.lastname}
                                readOnly={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Bidder #</label>
                              <Input
                                type="number"
                                name="name"
                                value={props.data.biddernumber}
                                readOnly={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Payment Type</label>
                              <Input
                                type="text"
                                name="name"
                                value={ props.stats.totalremaining > 0 ? 'Debit - Auction Pay Out' : 'Credit - Auction Collect' }
                                readOnly={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label> * Amount: </label>
                              <input
                                className="form-control"
                                autoFocus
                                ref={inputRef}
                                placeholder="0" 
                                type="number" 
                                step="0.01"
                                name="amount"
                                onFocus={handleInputFocus}
                                autoComplete = "off"
                                value= {paymentdata.amount }
                                onChange={ e => UpdatePaymentData(e) }
                                />
                            </FormGroup>
                          </Col>

                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>* Method: </label>
                              <select className='form-control'
                                name="methodid"
                                value={paymentdata.methodid}
                                readOnly={!props.editmode}
                                onChange={ e => {
                                    UpdatePaymentData(e);
                                    UpdateFee(e);
                                  }
                                }
                              >
                                <option value={0}>Select a Method</option>
                                {pmlist && pmlist.length > 0 &&
                                    pmlist.map((type) => {
                                    return(
                                      <option
                                      key={type.paymentmethodid} value={type.paymentmethodid}>{type.name + ' - ' + type.rate.toFixed(2) +'%'}</option>
                                    )
                                  })
                                }
                              </select>
                            </FormGroup>
                          </Col>
                      
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Reference #</label>
                              <Input
                                value={paymentdata.referencenumber}
                                placeholder="0000"
                                type="number"
                                onFocus={handleInputFocus}
                                name="referencenumber"
                                autoComplete = "off"
                                maxLength={9}
                                onInput={e => maxLengthCheck(e)}
                                onChange={ e => UpdatePaymentData(e) }
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Amount With Fees</label>
                              <Input
                                type="number"
                                name="amountentered"
                                value={dynostats.amountrequired}
                                readOnly={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Fees from Payment</label>
                              <Input
                                type="number"
                                name="paymentfees"
                                value={dynostats.paymentfees}
                                readOnly={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Remaining After Payment</label>
                              <Input
                                type="number"
                                name="newamountremaining"
                                value={dynostats.newamountremaining}
                                readOnly={true}
                                className={dynostats.newamountremaining === "0.00" ? 'zeroremaining' : ''}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Notes (Shift + Enter for new line):</label>
                              <Input
                                cols="80"
                                value={paymentdata.notes}
                                placeholder="Enter Notes"
                                rows="4"
                                type="textarea"
                                name="notes"
                                readOnly={!props.editmode}
                                autoComplete = "off"
                                onChange={ (e) => {
                                  setpaymentdata(prevState => {
                                    return { ...prevState, notes: e.target.value}
                                  })
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
            </Form>
          </KeyboardEventHandler>
        </CardBody>
        <CardFooter>
          {props.editmode &&
            <>
              <Button style={{float:"right"}} color="info" onClick={() => AddPayment()}>Add Payment</Button>
            </>
          }
        </CardFooter>
      </Card>
    }
  </>
  )
}

export default NewPayment;