import React, { useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter
  // useAsyncDebounce,
} from "react-table";

import { Table } from "reactstrap";
import {
  Label,
  Input,
  CustomInput,
  FormGroup,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import {matchSorter} from "match-sorter";
import classNames from "classnames";
// import "../../assets/css/table.css";
import sortasc from "../../assets/img/sort_asc.png";
import sortdesc from "../../assets/img/sort_desc.png";
import sortboth from "../../assets/img/sort_both.png";
import loaderimage from "../../assets/img/loader-table.gif"; 
import { makeid } from "utils/functions";


const Table2 = ({RowClick, columns, data, loading = true }) => {



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <>
      <Table {...getTableProps()} bordered responsive className='mwTable'>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <React.Fragment key={index}>
              <tr  className="theader" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index2) => (
                  <th key={index2} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="5" className="text-center">
                <img src={loaderimage} alt="Loading..." />
              </td>
            </tr>
          </tbody>
        ) : (
          <>
       
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr onClick = { RowClick ? () => RowClick(row) : undefined } key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, i2) => {
                        return (
                          <td key={i2}
                            {...cell.getCellProps({
                              className: cell.column.className
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
          </>
        )}
      </Table>
    </>
  );
};

export default Table2;