import { gql } from '@apollo/client';

export const ADD_PAYMENT = gql`
mutation createPayment($thedata : CreatePaymentInput!) {
  createPayment(data: $thedata) {
    paymentid
  }
}
`;

export const DELETE_PAYMENT = gql`
mutation deletePayment($thedata : String!) {
  deletePayment(paymentid: $thedata) 

}
`;
