import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import { formatDate } from "utils/date";
import { getLoggedInUser } from "utils/useToken";
import { useAlert } from "react-alert";

import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { GET_AUCTION_LIST } from "graphql/queries/auction";
import {AUCTION_ADDED, AUCTION_UPDATED } from "../../graphql/subscriptions/auctions";
import { UPDATE_AUCTION, CREATE_AUCTION } from "../../graphql/mutations/auction";
import XTable from "components/custom/table";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { format, parseISO } from "date-fns";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";


function AuctionInfo(props){
  const alert = useAlert();

  const { data: auctiond, loading: auctionl, error: auctionerror, onError:  auctiononerror, subscribeToMore, refetch } = useQuery(GET_AUCTION_LIST,{
    variables: {
      companyid: getLoggedInUser().company,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  let [updateAuction] = useMutation(UPDATE_AUCTION, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  let [createAuction] = useMutation(CREATE_AUCTION, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  
  const [auctionError, setAuctionError] = useState([]);

  const [modal, setModal] = useState(false);
  const [auctionlist, setAuctionList] = useState([{}]);
  
  const [auctionmodaldata, setModalAuctionData] = useState({
    nickname : '',
    location : '',
    password : '',
    datetimestart : '',
    datetimeend : '',
    description : '',
    active : '',
    code: ''
  });

  const RowClick = (t) => SetWindow("EditAuction", t);
  
  const toggle = () => setModal(!modal);

  const SetWindow = (actionid, t = undefined) => {
    // let actionid = "";
    // actionid = event.target.getAttribute('actionid');
    // if(actionid == null)
      // actionid = event.target.parentNode.getAttribute('actionid');

    if(actionid === "NewAuction"){
      setModalAuctionData({
        nickname : '',
        location : '',
        password : '',
        datetimestart : '',
        datetimeend : '',
        description : '',
        active : 'true',
        buttonTitle: 'Create Auction',
        buttonFunction: newAuction,
      })
    }
    else if(actionid === "EditAuction"){
      // let editAuctionID = event.target.parentNode.getAttribute('auctionid');
      let editAuctionID = t.original.auctionid;
      if(editAuctionID !== undefined){
        const index = auctionlist.map(auction => auction.auctionid).indexOf(editAuctionID);
        setModalAuctionData(() => auctionlist[index]);
        setModalAuctionData(prevState => {
          return { ...prevState, buttonTitle: "Update Auction", buttonFunction: editAuction}
        })
      }
    }
    toggle();
  }

  const newAuction = (t) => {
    setAuctionError([]);

    let ActiveStatus = false;
    if(t.active === 'true' || t.active === true) ActiveStatus = true;

    let restricted = false;
    if(t.password !== undefined){
      if(t.password.trim() !== "")  restricted = true;
    }
    
    let sdt = '';
    let edt = '';
    if(t.datetimestart !== "") sdt = parseISO(t.datetimestart);
    if(t.datetimeend !== "") edt = parseISO(t.datetimeend);

    const newauction = {
      companyid: getLoggedInUser().company,
      nickname: t.nickname,
      location: t.location,
      password: t.password,
      restricted: restricted,
      datetimestart: sdt,
      datetimeend: edt,
      active: ActiveStatus,
      description: t.description,
      lastmodifiedbyid: getLoggedInUser().userid,
    };

      createAuction({ variables: { thedata: newauction } }).then( (res) => {
        if(!res.errors){
          setAuctionError(() => ["Auction Created Successfully"]);
          let currentStorage = getLoggedInUser();
          currentStorage.auctionlist.push(
            {
              auctionid: res['data']['createAuction']['auctionid'],
              auctionname: newauction.nickname,
              companyid: getLoggedInUser().company
            }
          );
    
          localStorage.setItem('px3auction', JSON.stringify(currentStorage));
          setModal(false);
        }
      })
  }

  const editAuction = (t) =>{
    setAuctionError([]);
    let ActiveStatus = false;
    if(t.active === 'true' || t.active === true) ActiveStatus = true;

    const updateauction = {
      auctionid: t.auctionid,
      nickname: t.nickname,
      location: t.location,
      password: t.password,
      datetimestart: parseISO(t.datetimestart),
      datetimeend: parseISO(t.datetimeend),
      active: ActiveStatus,
      description: t.description,
      lastmodifiedbyid: getLoggedInUser().userid,
      companyid: getLoggedInUser().company,
    };

    updateAuction({
      variables: {thedata: updateauction}
    }).then( (res) => {
      if(!res.errors){
        setAuctionError(() => ["Auction Updated Successfully"]);
        setModal(false);
      }
    })
  }
  const [livedata, setLiveData] = useState(true);

  useEffect(() => {
    if(auctionError){
      if(auctionError.length > 0){
        auctionError.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [auctionError, alert]);
  
  const toggleLiveData = () => {
    if(!livedata)  refetch()
    setLiveData(!livedata);
  };
  
  useEffect(() => {
    const onCompleted = (auctiond) => { 
      if(auctiond){
        setAuctionList( () => auctiond.getAllAuctions);
      }
    }
    if (onCompleted || auctiononerror) {
      if (onCompleted && !auctionl && !auctionerror) {
        onCompleted(auctiond);
      } else if (auctiononerror && !auctionl && auctionerror) {
        auctiononerror(auctionerror);
      }
    }
  }, [auctionl, auctiond, auctionerror, auctiononerror]);

//subscriptions

useEffect(() => {
  let auctionCreateUnsubscribe;

  if(livedata){
   auctionCreateUnsubscribe = subscribeToMore({
      document: AUCTION_ADDED,
      variables:{companyid: getLoggedInUser().company},
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newAuction = subscriptionData.data.auctionCreated;

        const mergedResullt = [newAuction, ...prev.getAllAuctions];
        const returnResult = Object.assign({}, prev, { getAllAuctions: mergedResullt })
        return {
          getAllAuctions: returnResult
        }
      },
    }); 
  }

  if (auctionCreateUnsubscribe) return () => auctionCreateUnsubscribe()
}, [subscribeToMore, auctiond, livedata]);


useEffect(() => {
  let auctionUpdateUnsubscribe;

  if(livedata){
   auctionUpdateUnsubscribe = subscribeToMore({
      document: AUCTION_UPDATED,
      variables:{companyid: getLoggedInUser().company},
      updateQuery: (prev, { subscriptionData }) => {
        const newAuction = subscriptionData.data.auctionUpdated;
        let MainList = [...prev.getAllAuctions];
        const index = MainList.map(auction => auction.auctionid).indexOf(newAuction.auctionid);
  
        MainList[index] = newAuction;
        return {
          getAllAuctions: MainList
        }
      }
    }); 
  }

  if (auctionUpdateUnsubscribe) return () => auctionUpdateUnsubscribe()
}, [subscribeToMore, auctiond, livedata]);

// useEffect(() => {
//   let auctionDeleteUnsubscribe;

//   if(livedata){
//    auctionDeleteUnsubscribe = subscribeToMore({
//       document: AUCTION_DELETED,
//       variables:{companyid: getLoggedInUser().company},
//       updateQuery: (prev, { subscriptionData }) => {
//         const newAuction = subscriptionData.data.auctionDeleted;
//         let OldList = [...prev.getAllAuctions];
//         const newList = OldList.filter((auction) => auction.auctionid !== newAuction);  //ES6
//         return {
//           getAllAuctions: newList
//         };
//       },
//     }); 
//   }

//   if (auctionDeleteUnsubscribe) return () => auctionDeleteUnsubscribe()
// }, [subscribeToMore, auctiond, livedata]);

//
  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "nickname",
    },
    {
      Header: "Start",
      accessor: "datetimestart",
      className: "t-cell-2 text-centered",
      Cell: (props) => (
        formatDate(props.value)
      )
    },
    {
      Header: "End",
      accessor: "datetimeend",
      className: "t-cell-3 text-centered",
      Cell: (props) => (
        formatDate(props.value)
      )
      
    },
    {
      Header: "Status",
      accessor: "active",
      className: "t-cell-4 text-center mwTable-15",
      Cell: (props) => (
        (props.value === true) ? "Active" : "Not Active"
      )
    },
    {
      Header: "Edit",
      accessor: 'auctionid',
      className: "t-cell-4 text-center mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) => (
        //onClick={ toggle }
        <span auctionid={props.value} onClick={ () => SetWindow("EditAuction", props.row) } ><i className="tim-icons icon-pencil"></i></span>
      )
    },
  ];

  return(
<>    
    <Card>
      <CardHeader>
     
        <Button 
          className="btn-fill" 
          color="info" 
          type="button" 
          onClick={ () => SetWindow("NewAuction") }
          style={{float:'right'}}
          >
          Create Auction
        </Button>
        <CardTitle tag="h4">Auctions</CardTitle>
      
      </CardHeader>
      <CardBody>
        {/* <span className="float-left ">
          <CustomInput
            checked={livedata}
            className="table-search-switch"
            type="switch"
            name="customSwitch2"
            id="customSwitch2"
            onChange={toggleLiveData}
            label="Show Live Data"
          />
        </span> */}
        {auctionlist &&
        
          <XTable RowClick={RowClick} columns={listHeader} loading={false} data={auctionlist} filterbutton={props.filter} />
        }
      </CardBody>
      <CardFooter>
        
      </CardFooter>
    </Card>


{auctionmodaldata &&
    <Modal className='mw-modalItem' isOpen={modal} toggle={toggle}  modaldata = {auctionmodaldata} autoFocus={true}>
      <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => auctionmodaldata.buttonFunction(auctionmodaldata) }>
        <ModalBody>
          <Row>
          <Col md="12">
            <Card>
            <CardHeader>
              <CardTitle tag="h4">Auction</CardTitle>
            </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>* Nickname:</label>
                        <Input
                          value={auctionmodaldata.nickname}
                          placeholder="Nickname"
                          type="text"
                          autoComplete = "off"
                          autoFocus
                          onChange={ (e) => {
                            setModalAuctionData(prevState => {
                              return { ...prevState, nickname: e.target.value}
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>* Location: </label>
                        <Input
                          placeholder="Location"
                          type="text"
                          name="name"
                          value={auctionmodaldata.location}
                          autoComplete = "off"
                          onChange={ (e) => {
                            setModalAuctionData(prevState => {
                              return { ...prevState, location: e.target.value}
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label > Password (Optional): </label>
                        <Input
                          placeholder="" 
                          type="text" 
                          step="0.01"
                          name="price"
                          autoComplete = "off"
                          value= {auctionmodaldata.password}
                          onChange={(e) => {
                            setModalAuctionData(prevState => {
                              return { ...prevState, password: e.target.value}
                            })
                          }}
                          />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label >
                          * Start Date: 
                        </label>
                        <Datetime 
                          value={parseISO(auctionmodaldata.datetimestart)}
                          onChange={(e) => {
                            if(e._d !== undefined){
                              setModalAuctionData(prevState => {
                                return { ...prevState, datetimestart: format(e._d, "yyyy-MM-dd HH:mm")}
                              })
                            }
                            else{
                              setModalAuctionData(prevState => {
                                return { ...prevState, datetimestart: ''}
                              })
                            }
                          }}
                        /> 
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label >
                          * End Date:
                        </label>
                        <Datetime 
                          value={parseISO(auctionmodaldata.datetimeend)}
                          onChange={(e) => {
                            if(e._d !== undefined){
                              setModalAuctionData(prevState => {
                                return { ...prevState, datetimeend: format(e._d, "yyyy-MM-dd HH:mm")}
                              })
                            }
                            else{
                              setModalAuctionData(prevState => {
                                return { ...prevState, datetimeend: ''}
                              })
                            }
                          }}
                        /> 
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>* Status:</label>
                        <select className='form-control'
                          name="typeid"
                          value={auctionmodaldata.active}
                          onChange={ (e) => {
                            setModalAuctionData(prevState => {
                              return { ...prevState, active: e.target.value}
                            })
                          }}
                        >
                          <option value='true'>Active</option>
                          <option value='false'>Inactive</option>
                          
                        </select>
                      </FormGroup>
                    </Col>
                    {auctionmodaldata.code && 
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Display Board Code: </label>
                        <Input
                          placeholder="code"
                          type="text"
                          name="name"
                          value={auctionmodaldata.code}
                          readOnly={true}
                        />
                      </FormGroup>
                    </Col>
                    }
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Description  (Shift + Enter for new line): </label>
                        <Input
                          cols="80"
                          value={auctionmodaldata.description}
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                          name="description"
                          autoComplete = "off"
                          onChange={ (e) => {
                            setModalAuctionData(prevState => {
                              return { ...prevState, description: e.target.value}
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          </Row>
      
        </ModalBody>
        <ModalFooter>
          <Button 
            className="btn-fill" 
            color="info" 
            type="button" 
            onClick = {() => auctionmodaldata.buttonFunction(auctionmodaldata)}
          >{auctionmodaldata.buttonTitle} </Button> {' '} 

          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
        </KeyboardEventHandler>
    </Modal>
}
</>
  )
}
export default AuctionInfo;