import AuctionCustomer from "views/AuctionCustomer.js";
import Login from "views/login.js";
import Logout from "views/logout";
import ManageCompany from "views/ManageCompany";
import Customers from "views/Customers";
import SwitchAuction from "views/SwitchAuction";
import {SubDirectory} from "utils/defaults";
import SwitchCompany from "views/SwitchCompany";
import CreateCompany from "views/CreateCompany";
import DisplayBoard from "views/DisplayBoard";
import Profile from "views/Profile";
import CustomerProfile from "views/CustomerProfile";
import Dashboard from "views/Dashboard";
import Status from "views/Status";
import Receipt from "views/Receipt";
import CompanyUsers from "views/users/CompanyUsers";
import Settings from "components/custom/Settings";
import StressTest from "views/StressTest";

var routes = [
  {
    showOnNav: true,
    path: "/dashboard",
    opath: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: false,
    restricted: false,
    auctionRequired: true,
    companyRequired: true
  },
  {
    showOnNav: true,
    path: "/manage-company",
    opath: "/manage-company",
    name: "Manage Company",
    icon: "tim-icons icon-settings",
    component: ManageCompany,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: true,
    restricted: true,
    auctionRequired: false,
    companyRequired: true
  },
  {
    showOnNav: true,
    path: "/auction-customer",
    opath: "/auction-customer",
    name: "Auction",
    icon: "tim-icons icon-money-coins",
    component: AuctionCustomer,
    layout: "/" + SubDirectory,
    auctionAdmin: true,
    companyAdmin: false,
    restricted: true,
    auctionRequired: true,
    companyRequired: true
  },
  // {
  //   showOnNav: true,
  //   path: "/auction",
  //   name: "Auctions",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Dashboard,
  //   layout: "/" + SubDirectory,
  //   auctionAdmin: false,
  //   companyAdmin: true,
  //   restricted: true,
  // },
  // {
  //   showOnNav: true,
  //   path: "/company",
  //   name: "Companies",
  //   icon: "tim-icons icon-atom",
  //   component: Icons,
  //   layout: "/" + SubDirectory,
  //   auctionAdmin: false,
  //   companyAdmin: false,
  //   restricted: true,
  // },
  {
    showOnNav: true,
    path: "/customers",
    opath: "/customers",
    name: "Customers",
    icon: "tim-icons icon-single-02",
    component: Customers,
    layout: "/" + SubDirectory,
    auctionAdmin: true,
    companyAdmin: false,
    restricted: true,
    auctionRequired: true,
    companyRequired: true
  },
  {
    showOnNav: true,
    path: "/auction-status",
    opath: "/auction-status",
    name: "Status",
    icon: "tim-icons icon-paper",
    component: Status,
    layout: "/" + SubDirectory,
    auctionAdmin: true,
    companyAdmin: false,
    restricted: true,
    auctionRequired: true,
    companyRequired: true
  },
  {
    showOnNav: false,
    path: "/print-receipt",
    opath: "/print-receipt",
    name: "Receipt",
    icon: "tim-icons icon-paper",
    component: Receipt,
    layout: "/" + SubDirectory,
    auctionAdmin: true,
    companyAdmin: false,
    restricted: true,
    auctionRequired: true,
    companyRequired: true
  },
  // {
  //   showOnNav: true,
  //   path: "/reports",
  //   opath: "/reports",
  //   name: "Reports",
  //   icon: "tim-icons icon-align-center",
  //   component: Template,
  //   layout: "/" + SubDirectory,
  //   auctionAdmin: true,
  //   companyAdmin: false,
  //   restricted: true,
  //   auctionRequired: true,
  //   companyRequired: true
  // },
  // {
  //   showOnNav: true,
  //   path: "/payment",
  //   opath: "/payment",
  //   name: "Payments",
  //   icon: "tim-icons icon-align-center",
  //   component: Template,
  //   layout: "/" + SubDirectory,
  //   auctionAdmin: true,
  //   companyAdmin: false,
  //   restricted: true,
  //   auctionRequired: true,
  //   companyRequired: true
  // },
  // {
  //   showOnNav: true,
  //   path: "/flags",
  //   opath: "/flags",
  //   name: "Flags",
  //   icon: "tim-icons icon-align-center",
  //   component: Template,
  //   layout: "/" + SubDirectory,
  //   auctionAdmin: true,
  //   companyAdmin: false,
  //   restricted: true,
  //   auctionRequired: true,
  //   companyRequired: true
  // },

  {
    showOnNav: false,
    path: "/stress-test",
    opath: "/stress-test",
    name: "Stress Test",
    icon: "tim-icons icon-align-center",
    component: StressTest,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: true,
    restricted: true,
    auctionRequired: false,
    companyRequired: true
  },

  {
    showOnNav: false,
    path: "/login",
    opath: "/login",
    name: "Login",
    icon: "tim-icons icon-align-center",
    component: Login,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: false,
    restricted: false,
    auctionRequired: false
  },
  {
    showOnNav: true,
    path: "/company-users",
    opath: "/company-users",
    name: "Company Users",
    icon: "tim-icons icon-align-center",
    component: CompanyUsers,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: true,
    restricted: true,
    auctionRequired: false,
    companyRequired: true
  },
  {
    showOnNav: true,
    path: "/display-board",
    opath: "/display-board",
    name: "Display Board",
    icon: "tim-icons icon-align-center",
    component: DisplayBoard,
    layout: "/" + SubDirectory,
    auctionAdmin: true,
    companyAdmin: false,
    restricted: true,
    auctionRequired: false,
    fullscreen: true
  },
  {
    showOnNav: true,
    path: "/logout",
    opath: "/logout",
    name: "Logout",
    icon: "tim-icons icon-simple-remove",
    component: Logout,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: false,
    restricted: false,
    auctionRequired: false
  },
  {
    showOnNav: false,
    path: "/user-profile",
    opath: "/user-profile",
    name: "Profile",
    icon: "tim-icons icon-align-center",
    component: Profile,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: false,
    restricted: false,
    auctionRequired: false,
    fullscreen: true
  },
  {
    showOnNav: false,
    path: "/switch-auction",
    opath: "/switch-auction",
    name: "Switch-Auction",
    icon: "tim-icons icon-align-center",
    component: SwitchAuction,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: true,
    restricted: true,
    auctionRequired: false,
    companyRequired: true
  },
  {
    showOnNav: false,
    path: "/switch-company",
    opath: "/switch-company",
    name: "Switch-Company",
    icon: "tim-icons icon-align-center",
    component: SwitchCompany,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: true,
    restricted: true,
    auctionRequired: false,
    companyRequired: false
  },

  {
    showOnNav: false,
    path: "/create-company",
    opath: "/create-company",
    name: "Create-Company",
    icon: "tim-icons icon-align-center",
    component: CreateCompany,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: true,
    restricted: true,
    auctionRequired: false,
    companyRequired: false
  },
  {
    showOnNav: false,
    path: "/customer-profile",
    opath: "/customer-profile",
    name: "Customer Profile",
    icon: "tim-icons icon-align-center",
    component: CustomerProfile,
    layout: "/" + SubDirectory,
    auctionAdmin: false,
    companyAdmin: false,
    restricted: false,
    auctionRequired: false,
    companyRequired: false
  },

];
export default routes;
