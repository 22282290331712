
import React, { useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";


import Login from "views/login";
import {getLoggedInUser} from '../../utils/useToken';

import {SubDirectory} from "utils/defaults";
import SwitchAuction from "views/SwitchAuction";
import SwitchCompany from "views/SwitchCompany";
var ps;

function Admin(props) {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);

  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  // React.useEffect(() => {
  //   if(logintoken) {

  //   if (navigator.platform.indexOf("Win") > -1) {
  //     document.documentElement.className += " perfect-scrollbar-on";
  //     document.documentElement.classList.remove("perfect-scrollbar-off");
  //     ps = new PerfectScrollbar(mainPanelRef.current, {
  //       suppressScrollX: true,
  //     });
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       document.documentElement.classList.add("perfect-scrollbar-off");
  //       document.documentElement.classList.remove("perfect-scrollbar-on");
  //     }
  //   };
  // }
  // });
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   if (mainPanelRef.current) {
  //     mainPanelRef.current.scrollTop = 0;
  //   }
  // }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };

  const [logintoken, setToken] = useState(getLoggedInUser().logintoken);

  let companyinfo = {};
  let auctioninfo = {};
  let name;

  if(logintoken){
    if(getLoggedInUser().auctionid && getLoggedInUser().auctionid !== 0)
      auctioninfo = getLoggedInUser().auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid);
    else
      auctioninfo.auctionname = "";

    if(getLoggedInUser().px3.main === 13){
      if(getLoggedInUser().company && getLoggedInUser().company !== 0)
        companyinfo = getLoggedInUser().companylist.find(a => a.companyid === getLoggedInUser().company);
      else
        companyinfo.companyname = "";
    }
    else{
      companyinfo.companyname = getLoggedInUser().companyname;
    }

    name = getLoggedInUser().name;
  }
  else{
    name = "";
    auctioninfo.auctionname = "";
    companyinfo.companyname = "";
  }

  const [navbardata, setNavbarData] = useState({
    "Name" : name,
    "CompanyName" : companyinfo.companyname,
    "AuctionName" : auctioninfo.auctionname
  });

  React.useEffect(()=>{
    setNavbarData(navbardata)
  }, [navbardata]);
    

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/" + SubDirectory) {

        //mw
        if
          ( prop.restricted && ( 
            (prop.auctionAdmin && !getLoggedInUser().px3.auction) ||
            (prop.companyAdmin && !getLoggedInUser().px3.company) ||
            (!prop.companyAdmin && !prop.auctionAdmin && getLoggedInUser().px3.main !== 13)
            ) 
          ) return false;

        let TheComponent, ThePath, NewPath;

        if(!navbardata.CompanyName && prop.companyRequired){
          TheComponent = SwitchCompany;
          NewPath = "/switch-company";
        }
        
        if(navbardata.CompanyName){
          if(!navbardata.AuctionName && prop.auctionRequired){
            TheComponent = SwitchAuction;
            NewPath = "/switch-auction";
          }
          else{
            TheComponent = prop.component;
            NewPath = prop.opath
            //original NewPath = prop.layout + prop.path
          }
        }

        if(!prop.auctionRequired && !prop.companyRequired){
          TheComponent = prop.component;
          NewPath = prop.opath
        }

        routes[key].path = NewPath;
        ThePath = prop.layout + routes[key].path;
            
        return (
          <Route
            path={ThePath}
            // component={prop.component}
            key={key}
            render={(prop) => <TheComponent NavBarData = {navbardata} SetNavBarData = {setNavbarData} />}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Dashboard";
  };

  // const { token, setToken } = useToken();
  

  if(!logintoken && location.pathname !== "/auction/display-board") {
    return (
      <BackgroundColorContext.Consumer>
        {({ color, changeColor }) => (
          <React.Fragment>
            <Login setToken={setToken} SetNavBarData = {setNavbarData} />
          </React.Fragment>
      )}
      </BackgroundColorContext.Consumer>
    )
  }

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            
            {location.pathname !== "/auction/display-board" &&
              <Sidebar
                routes={routes}
                logo={{
                  outterLink:"http://192.168.1.64:3000/",
                  text: "CMC Auction",
                  imgSrc: logo,
                }}
                toggleSidebar={toggleSidebar}
                NavBarData = {navbardata} 
                SetNavBarData = {setNavbarData}
              />
            }

            <div className="main-panel" ref={mainPanelRef} data={color}>
            
            {location.pathname !== "/auction/display-board" &&
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
                NavBarData = {navbardata} 
                SetNavBarData = {setNavbarData}
              />
            }
              <Switch>
                {getRoutes(routes)}

                {navbardata.CompanyName && navbardata.AuctionName &&
                  <Redirect from="*" to={"/" + SubDirectory + "/dashboard"} />
                }

                {!navbardata.CompanyName && navbardata.AuctionName &&
                  <Redirect from="*" to={"/" + SubDirectory + "/switch-company"} />
                }

                {navbardata.CompanyName && !navbardata.AuctionName &&
                  <Redirect from="*" to={"/" + SubDirectory + "/switch-auction"} />
                }

                {!navbardata.CompanyName && !navbardata.AuctionName &&
                  <Redirect from="*" to={"/" + SubDirectory + "/switch-company"} />
                }

              </Switch>
              {
                // we don't want the Footer to be rendered on map page
                // location.pathname === "/admin/maps" ? null : <Footer fluid />
              }
            </div>
          </div>
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
