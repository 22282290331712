import Stats from 'components/custom/Stats';
import EditProfile from 'components/custom/EditProfile';
import '../assets/css/print.css';
import { PaymentList } from './PaymentList';
import { useQuery } from '@apollo/client';
import { GET_ALL_ITEMS } from 'graphql/queries/items';
import { useEffect, useState } from 'react';
import { getLoggedInUser } from 'utils/useToken';
import { formatNumber } from 'utils/functions';
import XTable from 'components/custom/table';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Table2 from 'components/custom/receiptTable2';

function Receipt(props){
  const { loading, error, data, onError } = useQuery(GET_ALL_ITEMS,{
    variables: {
      auctionid: getLoggedInUser().auctionid,
      biddernumber: props.biddernumber,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [itembuylist, setBuyerList] = useState([{}]);
  const [itemselllist, setSellerList] = useState([{}]);
  const [printready, setPrintReady] = useState([{
    stats: false,
    profile: false,
    payments: false,
    itemsbought: false,
    itemssold: false
  }])

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        let buyeritemarray = [];
        let selleritemarray = [];

        data.getAllItems.map((item) => {
          const TheItem = {
            sellerbiddernumber:item.sellerbiddernumber, 
            buyerbiddernumber :item.buyerbiddernumber,
            price: item.price.toFixed(2),
            description: item.description,
            name: item.name,
            qty: item.qty,
            lastmodifiedbyid: item.lastmodifiedbyid,
            auctionid: item.auctionid,
            itemid: item.itemid,
            datetime: item.datetime,
            lastupdated: item.lastupdated,
            sellername: item.sellername,
            buyername: item.buyername,
            typeid: item.typeid,
            lastmodifiedbyuser: item.lastmodifiedbyuser
          }

          if(item.buyerbiddernumber === props.biddernumber){
            return(
              buyeritemarray.push( TheItem )
            )
          }
          else if(item.sellerbiddernumber === props.biddernumber){
            return(
              selleritemarray.push( TheItem )
            )
          }
          else return false
        })

        setBuyerList(() => buyeritemarray);
        setSellerList(() => selleritemarray);

        setTimeout( () => 
          setPrintReady(prevState => {
            return { ...prevState, itemsbought: true, itemssold: true}
          })  
        , 500);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError, props.biddernumber]);

  useEffect(() => {
    console.log(printready);
    if(printready.stats && printready.profile && printready.payments && printready.itemsbought && printready.itemssold){
      window.print();
      setPrintReady(prevState => {
        return { ...prevState, itemsbought: false, itemssold: false, stats: false, profile: false, payments: false}
      })  
    }
  }, [printready]);

  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "name",
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Price",
      accessor: "price",
      className: "t-cell-2 text-centered",
      Cell: (props) =>(
        <span>{props.row.original.qty > 1 ? formatNumber(props.value * props.row.original.qty) + " (" + formatNumber(props.value) + "/ea)" : formatNumber(props.value)}</span>
      )
    },
    {
      Header: "Description",
      accessor: "description",
      className: "t-cell-2 text-centered",
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Buyer",
      accessor: "buyerbiddernumber",
      className: "t-cell-3 text-centered mwTable-15",
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },
    {
      Header: "Seller",
      accessor: "sellerbiddernumber",
      className: "t-cell-4 text-center mwTable-15",
      Cell: (props) =>(
        <span>{props.value}</span>
      )
    },

  ];

  return (
    <div className="content print" >

      <div className="printsection"><EditProfile setPrintReady ={ setPrintReady } printReady = {printready} auctionedit={false} userid={props.userid} /></div>
      <div className="printsection"><Stats setPrintReady ={ setPrintReady } printReady = {printready} biddernumber={props.biddernumber}  page={"dashboard"} /></div>
      <div className="printsection"><PaymentList setPrintReady ={ setPrintReady } printReady = {printready} editmode = {false} biddernumber = {props.biddernumber} print={true} /> </div>
      
      <div className="printsection"><EditProfile setPrintReady ={ setPrintReady } printReady = {printready} auctionedit={false} userid={props.userid} /></div>
      <div className="printsection"><Stats setPrintReady ={ setPrintReady } printReady = {printready} biddernumber={props.biddernumber}  page={"dashboard"} /></div>
      <div className="printsection"><PaymentList setPrintReady ={ setPrintReady } printReady = {printready} editmode = {false} biddernumber = {props.biddernumber} print={true} /> </div>

      <div className="printsection">
        <Card>
          <CardHeader>Items Bought</CardHeader>
          <CardBody>
            <Table2  columns={listHeader} loading={false} data={itembuylist} />
          </CardBody>
        </Card>
      </div>

      <div className="printsection">
        <Card>
          <CardHeader>Items Sold</CardHeader>
          <CardBody>
            <Table2 columns={listHeader} loading={false} data={itemselllist}/>
          </CardBody>
        </Card>
      </div>

    </div>
  )
}

export default Receipt;