import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ModalBody,
  Modal,
  ModalFooter,
  CustomInput
} from "reactstrap";
import { getLoggedInUser } from "utils/useToken";
import { useAlert } from "react-alert";

import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import XTable from "components/custom/table";
import { GET_PAYMENT_METHODS } from "graphql/queries/paymentmethods";
import {PAYMENTMETHOD_ADDED, PAYMENTMETHOD_UPDATED } from "../../graphql/subscriptions/paymentsmethods";
import { UPDATE_PAYMENT, CREATE_PAYMENT } from "../../graphql/mutations/paymentmethods";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";

function PaymentMethodList(props){
  const alert = useAlert();

  const { data: pmd, loading: pml, error: pmerror, onError:  pmonerror, subscribeToMore, refetch } = useQuery(GET_PAYMENT_METHODS,{
    variables: {
      companyid: getLoggedInUser().company,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [livedata, setLiveData] = useState(true);
  const toggleLiveData = () => {
    if(!livedata)  refetch()
    setLiveData(!livedata);
  };

  const [pmlist, setPmList] = useState([{}]);

  let [updatePayment] = useMutation(UPDATE_PAYMENT, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  let [createPayment] = useMutation(CREATE_PAYMENT, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  
  const [paymentMethodError, setPaymentMethodError] = useState([]);
  const [modal, setModal] = useState(false);
  const [paymentmodaldata, setModalPaymentData] = useState({
    name : '',
    url : '',
    username: '',
    password : '',
    rate: 0,
    type: 1,
    active : true,
  });

  const RowClick = (t) => SetWindow("EditAuction", t);
  
  const toggle = () => setModal(!modal);

  const SetWindow = (actionid, t = undefined) => {
    // let actionid = "";
    // actionid = event.target.getAttribute('actionid');
    // if(actionid == null)
    //   actionid = event.target.parentNode.getAttribute('actionid');

    if(actionid === "NewPayment"){
      setModalPaymentData({
        name : '',
        url : '',
        username: '',
        password : '',
        rate: 0,
        active : true,
        type: 1,
        buttonTitle: 'Create Payment Method',
        buttonFunction: newPayment,
      })
    }
    else if(actionid === "EditPayment"){
      // let editPaymentID = event.target.parentNode.getAttribute('paymentmethodid');
      let editPaymentID = t.original.paymentmethodid;
      if(editPaymentID !== undefined){
        const index = pmlist.map(payment => payment.paymentmethodid).indexOf(editPaymentID);
        setModalPaymentData(() => pmlist[index]);
        setModalPaymentData(prevState => {
          return { ...prevState, buttonTitle: "Update Payment Method", buttonFunction: editPayment}
        })
      }
    }
    toggle();
  }

  const newPayment = (t) => {
    setPaymentMethodError([]);
    let ActiveStatus = false;
    if(t.active === 'true' || t.active === true) ActiveStatus = true;

    const newpayment = {
      companyid: getLoggedInUser().company,
      name: t.name,
      url: t.url,
      username: t.username,
      password: t.password,
      rate: t.rate,
      active: ActiveStatus,
      type: t.type,
      lastmodifiedbyid: getLoggedInUser().userid,
    };

    createPayment({ variables: { thedata: newpayment } }).then( (res) => {
      if(!res.errors){
        setPaymentMethodError(()=>["Payment Created Successfully"]);
        setModal(false);
      }
    })
  }

  const editPayment = (t) =>{
    setPaymentMethodError([]);

    let ActiveStatus = false;
    if(t.active === 'true' || t.active === true) ActiveStatus = true;

    const updatepayment = {
      paymentmethodid: t.paymentmethodid,
      companyid: getLoggedInUser().company,
      name: t.name,
      url: t.url,
      username: t.username,
      password: t.password,
      rate: t.rate,
      active: ActiveStatus,
      type: t.type,
      lastmodifiedbyid: getLoggedInUser().userid,
    };

    updatePayment({
      variables: {thedata: updatepayment}
    }).then( (res) => {
      if(!res.errors){
        setPaymentMethodError(()=>["Payment Updated Successfully"]);
        setModal(false);
      }
    })
  }

  useEffect(() => {
    if(paymentMethodError){
      if(paymentMethodError.length > 0){
        paymentMethodError.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [paymentMethodError, alert]);

  useEffect(() => {
    const onCompleted = (pmd) => { 
      if(pmd){
        setPmList( () => pmd.getAllPaymentMethods);
      }
    }
    if (onCompleted || pmonerror) {
      if (onCompleted && !pml && !pmerror) {
        onCompleted(pmd);
      } else if (pmonerror && !pml && pmerror) {
        pmonerror(pmerror);
      }
    }
  }, [pml, pmd, pmerror, pmonerror]);



  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "name",
    },
    {
      Header: "Transaction Type",
      accessor: "type",
      className: "t-cell-2 text-centered",
      Cell: (props) => (
        props.value === 1 ? "Payout" : "Collection"
      )
    },
    {
      Header: "Rate",
      accessor: "rate",
      className: "t-cell-2 text-centered",
      Cell: (props) => (
        props.value ? props.value.toFixed(2) + '%' : "0.00%"
      )
    },
    {
      Header: "Status",
      accessor: "active",
      className: "t-cell-4 text-center mwTable-15",
      Cell: (props) => (
        (props.value === true) ? "Active" : "Not Active"
      )
    },
    {
      Header: "Edit",
      accessor: 'paymentmethodid',
      className: "t-cell-4 text-center mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) => (
        <span paymentmethodid={props.value} onClick={() => SetWindow("EditPayment", props.row)} ><i className="tim-icons icon-pencil"></i></span>
      )
    },
  ];

  //subscriptions

  useEffect(() => {
    let paymentMethodCreateUnsubscribe;
  
    if(livedata){
     paymentMethodCreateUnsubscribe = subscribeToMore({
        document: PAYMENTMETHOD_ADDED,
        variables:{companyid: getLoggedInUser().company},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          } 
          const newPaymentMethod = subscriptionData.data.paymentMethodCreated;

          const mergedResullt = [newPaymentMethod, ...prev.getAllPaymentMethods];
          const returnResult = Object.assign({}, prev, { getAllPaymentMethods: mergedResullt })
          return {
            getAllPaymentMethods: returnResult
          }
        },
      }); 
    }
  
    if (paymentMethodCreateUnsubscribe) return () => paymentMethodCreateUnsubscribe()
  }, [subscribeToMore, pmd, livedata]);
  
  useEffect(() => {
    let paymentMethodUpdateUnsubscribe;
  
    if(livedata){
     paymentMethodUpdateUnsubscribe = subscribeToMore({
        document: PAYMENTMETHOD_UPDATED,
        variables:{companyid: getLoggedInUser().company},
        updateQuery: (prev, { subscriptionData }) => {
          const newPayment = subscriptionData.data.paymentMethodUpdated;
          let MainList = [...prev.getAllPaymentMethods];
          const index = MainList.map(payment => payment.paymentmethodid).indexOf(newPayment.paymentmethodid);
    
          MainList[index] = newPayment;
          return {
            getAllPaymentMethods: MainList
          }
        }
      }); 
    }
  
    if (paymentMethodUpdateUnsubscribe) return () => paymentMethodUpdateUnsubscribe()
  }, [subscribeToMore, pmd, livedata]);
  
  // useEffect(() => {
  //   let paymentMethodDeleteUnsubscribe;
  
  //   if(livedata){
  //    paymentMethodDeleteUnsubscribe = subscribeToMore({
  //       document: PAYMENTMETHOD_DELETED,
  //       variables:{companyid: getLoggedInUser().company},
  //       updateQuery: (prev, { subscriptionData }) => {
  //         const newPayment = subscriptionData.data.paymentMethodDeleted;
  //         let OldList = [...prev.getAllPaymentMethods];
  //         const newList = OldList.filter((payment) => payment.paymentmethodid !== newPayment);  //ES6
  //         return {
  //           getAllPaymentMethods: newList
  //         };
  //       },
  //     }); 
  //   }
  
  //   if (paymentMethodDeleteUnsubscribe) return () => paymentMethodDeleteUnsubscribe()
  // }, [subscribeToMore, pmd, livedata]);
  

//
 
return(
  <>
  <Card>
    <CardHeader>
        <Button 
          className="btn-fill" 
          color="info" 
          type="button" 
          onClick = {() => SetWindow("NewPayment")}
          style={{float:'right'}}
          >
          Add Method
        </Button>
      <CardTitle tag="h4">Payment Methods</CardTitle>
    </CardHeader>
    <CardBody>
      {/* <span className="float-left ">
        <CustomInput
          checked={livedata}
          className="table-search-switch"
          type="switch"
          name="customSwitch3"
          id="customSwitch3"
          onChange={toggleLiveData}
          label="Show Live Data"
        />
      </span> */}
        {pmlist &&
          <XTable RowClick={RowClick} columns={listHeader} loading={false} data={pmlist} filterbutton={props.filter} />
        }
    </CardBody>
    <CardFooter>
    
    </CardFooter>
  </Card>

  {paymentmodaldata &&
    <Modal className='mw-modalItem' isOpen={modal} toggle={toggle}  modaldata = {paymentmodaldata} autoFocus={true}>
      <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => paymentmodaldata.buttonFunction(paymentmodaldata) }>
        <ModalBody>
          <Row>
          <Col md="12">
            <Card>
            <CardHeader>
              <CardTitle tag="h4">Payment Method</CardTitle>
            </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>* Name:</label>
                        <Input
                          value={paymentmodaldata.name}
                          placeholder="Name"
                          type="text"
                          autoComplete = "off"
                          autoFocus
                          onChange={ (e) => {
                            setModalPaymentData(prevState => {
                              return { ...prevState, name: e.target.value}
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Rate:</label>
                        <Input
                          placeholder="Rate"
                          type="number"
                          name="name"
                          value={parseFloat(paymentmodaldata.rate)}
                          autoComplete = "off"
                          onChange={ (e) => {
                            if(e.target.value !== ""){
                              setModalPaymentData(prevState => {
                                return { ...prevState, rate: parseFloat(e.target.value)}
                              })
                            }
                            else{
                              setModalPaymentData(prevState => {
                                return { ...prevState, rate: parseFloat(0)}
                              })
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label >
                          URL:
                        </label>
                        <Input
                          placeholder="" 
                          type="text" 
                          step="0.01"
                          name="price"
                          autoComplete = "off"
                          value= {paymentmodaldata.url}
                          onChange={(e) => {
                            setModalPaymentData(prevState => {
                              return { ...prevState, url: e.target.value}
                            })
                          }}
                          />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label >
                          Username:
                        </label>
                        <Input
                          placeholder="Username" 
                          type="text" 
                          step="0.01"
                          name="price"
                          autoComplete = "off"
                          value= {paymentmodaldata.username}
                          onChange={(e) => {
                            setModalPaymentData(prevState => {
                              return { ...prevState, username: e.target.value}
                            })
                          }}
                          />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label >
                          Password:
                        </label>
                        <Input
                          placeholder="" 
                          type="text" 
                          step="0.01"
                          name="price"
                          autoComplete = "off"
                          value= {paymentmodaldata.password}
                          onChange={(e) => {
                            setModalPaymentData(prevState => {
                              return { ...prevState, password: e.target.value}
                            })
                          }}
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>* Status:</label>
                        <select className='form-control'
                          name="typeid"
                          value={paymentmodaldata.active}
                          onChange={ (e) => {
                            setModalPaymentData(prevState => {
                              return { ...prevState, active: e.target.value}
                            })
                          }}
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                          
                        </select>
                      </FormGroup>
                    </Col>

                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>* Transaction Type:</label>
                        <select className='form-control'
                          name="type"
                          value={paymentmodaldata.type}
                          onChange={ (e) => {
                            setModalPaymentData(prevState => {
                              return { ...prevState, type: parseInt(e.target.value)}
                            })
                          }}
                        >
                          <option value={1}>Payout</option>
                          <option value={2}>Collection</option>
                          
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          </Row>
      
        </ModalBody>
        <ModalFooter>
          <Button 
            className="btn-fill" 
            color="info" 
            type="button" 
            onClick = {() => paymentmodaldata.buttonFunction(paymentmodaldata)}
          >{paymentmodaldata.buttonTitle} </Button> {' '} 

          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </KeyboardEventHandler>
    </Modal>
}
</>  
  )
}
export default PaymentMethodList;