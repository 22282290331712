import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import {stateArray} from "utils/arrays";
import { CREATE_COMPANY } from "../graphql/mutations/company";
import { useMutation } from "@apollo/client";
import { getLoggedInUser } from "utils/useToken";
import { validatePhoneNumber, validateEmail } from "utils/functions";
import { PopupError } from "utils/functions";

function CreateCompany(props){
  let history = useHistory();
  const [companyinfo, setCompanyInfo] = useState({
    lastmodifiedbyid: getLoggedInUser().userid,
    active: true,
    adminid: "",
    address2: "",
    notes: "",
    state: "",
    email:"",
    name: "",
    contactname: "",
    phone: "",
    address: "",
    city: "",
    zip: 0
  });
  const [companyInfoError, setCompanyInfoError] = useState([]);

  const [createCompany] = useMutation(CREATE_COMPANY, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });

  const createNew = () => {
    if(!validatePhoneNumber(companyinfo.phone))
      setCompanyInfoError(["Invalid Phone Number"]);
    else if(!validateEmail(companyinfo.email))
      setCompanyInfoError(["Invalid Email"]);
    else{
      setCompanyInfoError([]);
      createCompany({ variables: { thedata: companyinfo } })
      .then((data2) => {
        if(!data2.errors){
          let currentStorage = getLoggedInUser();
          currentStorage.companylist.push(
            {
              companyid: data2['data']['createCompany']['companyid'],
              companyname: companyinfo.name
            }
          );
    
          localStorage.setItem('px3auction', JSON.stringify(currentStorage));
          history.push("switch-company");
          // ResetInput();
        //Redirect and add to localStorage companylist
        }
      })
      .catch((err) => console.log(err));
    }
  }

  return (
    <div className="content">
    <Row style={{'margin': '0'}}>
      <Col md="12" >
        <Card>
          <Row>
            <Col md="12" className="mwResult">
              { companyInfoError &&
                companyInfoError.map((x) => {
                  return ( <span key={x}> {x} <br /> </span>)
                })
              }
            </Col>
          </Row>
          <CardHeader>
            <CardTitle tag="h4">Create Company </CardTitle>
          </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>* Company Name:</label>
                      <Input
                        value={companyinfo.name}
                        placeholder="Company Name"
                        type="text"
                        autoComplete = "off"
                        autoFocus
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, name: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col  md="6">
                    <FormGroup>
                      <label>* Contact Name:</label>
                      <Input
                        value={companyinfo.contactname}
                        placeholder="Contact Name"
                        type="text"
                        autoComplete = "off"
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, contactname: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Email:</label>
                      <Input
                      value={companyinfo.email}
                        placeholder="Email"
                        type="email"
                        autoComplete = "off"
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, email: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col  md="6">
                    <FormGroup>
                      <label>* Phone:</label>
                      <Input
                      value={companyinfo.phone}
                        placeholder="123-456-7890"
                        type="tel"
                        autoComplete = "off"
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, phone: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>* Address:</label>
                      <Input
                      value={companyinfo.address}
                        placeholder="Address"
                        type="text"
                        autoComplete = "off"
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, address: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col  md="6">
                    <FormGroup>
                      <label>Address 2</label>
                      <Input
                      value={companyinfo.address2}
                        placeholder="Address 2"
                        type="text"
                        autoComplete = "off"
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, address2: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>* City:</label>
                      <Input
                      value={companyinfo.city}
                        placeholder="City"
                        type="text"
                        autoComplete = "off"
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, city: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col  md="4">
                    <FormGroup>
                          <label>State:</label>
                          <select className='form-control'
                            name="state"
                            value={companyinfo.state}
                            onChange={ (e) => {
                              setCompanyInfo(prevState => {
                                return { ...prevState, state: e.target.value}
                              })
                            }}
                          >
                            <option value="">Select a State</option>
                          {stateArray.map((value, key) => {
                            return (
                              <option value={value.abbreviation} key={key}>
                                {value.name}
                              </option>
                            );
                          })}
                            
                          </select>
                        </FormGroup>

                  </Col>
                  <Col  md="4">
                    <FormGroup>
                      <label>* Zip</label>
                      <Input
                      value={companyinfo.zip}
                        placeholder="Zip"
                        type="text"
                        autoComplete = "off"
                        onChange={ (e) => {
                          if(e.target.value !== ""){
                            setCompanyInfo(prevState => {
                              return { ...prevState, zip: parseInt(e.target.value)}
                            })
                          }
                          else{
                            setCompanyInfo(prevState => {
                              return { ...prevState, zip: parseInt(0)}
                            })
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {getLoggedInUser().px3.main === 13 && 
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Notes</label>
                        <Input
                        value={companyinfo.notes}
                          cols="80"
                          placeholder="Notes"
                          rows="4"
                          type="textarea"
                          name="description"
                          autoComplete = "off"
                          onChange={ (e) => {
                            setCompanyInfo(prevState => {
                              return { ...prevState, notes: e.target.value}
                            })
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                }
              </Form>
            </CardBody>
            <CardFooter>
              <Button 
              className="btn-fill" 
              color="info" 
              type="button" 
              onClick ={ createNew }
              >
                Create
              </Button>
            </CardFooter>
          </Card>
      </Col>
    </Row>
  </div>
  )
}

export default CreateCompany;