import { gql } from '@apollo/client';

export const CREATE_USER = gql`
mutation createUser($thedata : CreateUserInput!) {
  createUser(data: $thedata) {
    userid
    firstname
    lastname
    email
    address
    address2
    city
    state
    zip
    country
    phone
  }
}
`;
export const UPDATE_SETTINGS= gql`
mutation updateSettings($thedata : String!, $userid : String!) {
  updateSettings(data: $thedata, userid: $userid) 
}
`;

export const UPDATE_USER = gql`
mutation updateUser($thedata : UpdateUserInput!) {
  updateUser(data: $thedata) {
    userid
  }
}
`;

export const DEACTIVATE_USER = gql`
mutation deactivateUser($auctionid : String!, $biddernumber : Float!) {
  deactivateUser(auctionid: $auctionid, biddernumber: $biddernumber) 
}
`;

export const DEACTIVATE_ALL_USERS = gql`
mutation deactivateAllUsers($auctionid : String!) {
  deactivateAllUsers(auctionid: $auctionid) 
}
`;