import { gql } from '@apollo/client';

export const GET_PAYMENT_METHODS = gql`
query getAllPaymentMethods($companyid : String!) {
  getAllPaymentMethods(companyid: $companyid) {
    name
    url
    username
    password
    rate
    lastmodifiedbyid
    paymentmethodid
    lastupdated
    active
    type
  }
}
`;