import { getLoggedInUser } from "utils/useToken";
import React from "react";
import { useHistory } from "react-router-dom";
import { SubDirectory } from "utils/defaults";

const { CardHeader, CardBody, CardFooter, Card, Col, Row, CardTitle, FormGroup, Button } = require("reactstrap");

function SwitchCompany(props){
  let history = useHistory();

  let tmpCompany;
  const SelectCompany = () => {
    if(tmpCompany !== "" && tmpCompany){
      let currentStorage = getLoggedInUser();
      currentStorage.company = tmpCompany;
      currentStorage.auctionid = 0;

      localStorage.setItem('px3auction', JSON.stringify(currentStorage));
       
      let companyinfo = {}; 

      if(getLoggedInUser().company)
        companyinfo = getLoggedInUser().companylist.find(a => a.companyid === getLoggedInUser().company);
      else
        companyinfo.companyname = "";
      
      props.SetNavBarData(prevState => {
        return{ ...prevState, CompanyName: companyinfo.companyname, AuctionName: ""}
      });

      history.push("/" + SubDirectory + "/dashboard");
      // history.goBack();
    }
  }

  const CreateNewCompany = () =>{
    history.push("/" + SubDirectory + "/create-company");
  }

  return (
    <div className="content">
    <Row style={{'margin': '0'}}>
    <Col lg="6" md="8" sm = "12" className="mw-Login">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Company Selection</CardTitle>
          </CardHeader>
          <CardBody>
            <p>Please select an company before continuing</p>
            <FormGroup>
              <select 
                onChange={(e) => tmpCompany = e.target.value}
                className='form-control mw-select'>
                <option value=''>Select a Company</option>
                  {
                    getLoggedInUser().companylist.map((companyname) => {
                        return(
                          <option key={companyname.companyid} value={companyname.companyid}>{companyname.companyname}</option>
                        )
                    })
                  }
              </select>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button 
              className="btn-fill" 
              color="info" 
              type="button" 
              onClick={ () => SelectCompany()}
            >
              Continue
            </Button>

            <Button 
              className="btn-fill float-right" 
              color="info" 
              type="button" 
              onClick={ () => CreateNewCompany()}
            >
              Create
            </Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </div>
  )
}

export default SwitchCompany;