import { gql } from '@apollo/client';

export const ITEM_ADDED = gql`
subscription ItemCreated($auctionid: String!, $biddernumber: Float) {
  itemCreated(auctionid: $auctionid, biddernumber: $biddernumber) {
    sellerbiddernumber 
    buyerbiddernumber 
    price
    description
    name
    qty
    lastmodifiedbyid
    auctionid
    itemid
    datetime
    lastupdated
    sellername
    buyername
    typeid
		lastmodifiedbyuser
  }
}
`;

export const ITEM_DELETED = gql`
subscription ItemDeleted($auctionid: String!, $biddernumber: Float) {
  itemDeleted (auctionid: $auctionid, biddernumber: $biddernumber)
}
`;

export const ITEM_DELETED_ADMIN = gql`
subscription ItemDeletedAdmin($auctionid: String!, $biddernumber: Float) {
  itemDeletedAdmin (auctionid: $auctionid, biddernumber: $biddernumber)
}
`;

export const ITEM_UPDATED = gql`
subscription ItemUpdated($auctionid: String!, $biddernumber: Float) {
  itemUpdated(auctionid: $auctionid, biddernumber: $biddernumber){
    sellerbiddernumber 
    buyerbiddernumber 
    price
    description
    name
    qty
    lastmodifiedbyid
    auctionid
    itemid
    datetime
    lastupdated
    sellername
    buyername
    typeid
		lastmodifiedbyuser
  } 
}
`;
