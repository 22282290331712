import { gql } from '@apollo/client';

export const CREATE_PAYMENT = gql`
mutation createPaymentMethod($thedata : CreatePaymentMethodInput!) {
  createPaymentMethod(data: $thedata) {
    paymentmethodid
    name
    url
    username
    password
    rate
    lastmodifiedbyid
    lastupdated
    active
    type
  }
}
`;


export const UPDATE_PAYMENT = gql`
mutation updatePaymentMethod($thedata : UpdatePaymentMethodInput!) {
  updatePaymentMethod(data: $thedata) {
    paymentmethodid
  }
}
`;
