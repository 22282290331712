
// export default function useToken() {
//   const getToken = () => {
//     const tokenString = sessionStorage.getItem('px3auction');
//     // console.log(tokenString);
//     const userToken = JSON.parse(tokenString);
//     console.log("usertoken", userToken?.logintoken);
//     return userToken?.logintoken
//   };

//   const [token, setToken] = useState(getToken());

//   const saveToken = userToken => {
//     console.log('save');
//     sessionStorage.setItem('px3auction', JSON.stringify(userToken));
//     setToken(userToken.token);
//   };

//   return {
//     setToken: saveToken,
//     token
//   }
// }

// export const getToken = () => {
//   const tokenString = sessionStorage.getItem('px3auction');
//   const userToken = JSON.parse(tokenString);
//   return userToken?.logintoken
// };

export const getLoggedInUser = () => {
  const tokenString = localStorage.getItem('px3auction');
  const userToken = JSON.parse(tokenString);

  if(userToken)
    return userToken
  else{
    return {
      logintoken: false,
      userid: false,
      px3:false,
      name:false
    }
  }
};

 