import React, {useState} from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import AuctionInfo from "./company/auctioninfo";
import CompanyInfo from "./company/companyinfo";
import PaymentMethodList from "./company/paymentmethodinfo";
import TypeList from "./company/typesinfo";

function ManageCompany()  { 
  const [view, setView] = useState("all");
  const [filters, setFilters] = useState({
    "auctions": false,
    "types": false,
    "payments": false,
  })

  const ResetFilters = () =>{
    setFilters({
      "auctions": false,
      "types": false,
      "payments": false,
    })
  }

  const UpdateView = (view) => {
    setView(view);
    ResetFilters();
    setFilters(prevState => {
      return { ...prevState, [view]: true}
    })
  }
  
      return (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Select Your View</h4>
                    <Button onClick={()=>UpdateView("profile")} color="secondary">Profile</Button>
                    <Button onClick={()=>UpdateView("auctions")} color="secondary">Auctions</Button>
                    <Button onClick={()=>UpdateView("payments")} color="secondary">Payment Methods</Button>
                    <Button onClick={()=>UpdateView("types")} color="secondary">Types</Button>
                    <Button onClick={()=>UpdateView("all")} color="secondary">All</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {view === "all" ?
              <div>
                <Row>
                  <Col lg="6" md="12" sm = "12" >
                      <CompanyInfo />
                  </Col>
                  <Col lg="6" md="12" sm = "12" >
                    <AuctionInfo filter={filters.auctions} />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="12" sm = "12" >
                    <PaymentMethodList filter={filters.payments} />
                  </Col>
                  <Col lg="6" md="12" sm = "12" >
                    <TypeList filter={filters.types} />
                  </Col>
                </Row>
              </div>
              :
              <Row>
                <Col md="12">
                  {
                    view === "profile" ? <CompanyInfo /> : 
                    view === "auctions" ? <AuctionInfo filter={filters.auctions} /> :
                    view === "payments" ? <PaymentMethodList filter={filters.payments} /> :
                    view === "types" ? <TypeList filter={filters.types} /> : ''
                  }
                </Col>
              </Row>
            }
          </div>
      );
    }
export default ManageCompany;
