
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import Admin from "layouts/Admin/Admin.js";

import "assets/scss/template.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from 'apollo-link-error';
import { getLoggedInUser } from "utils/useToken";
import { setContext } from '@apollo/client/link/context';



import {SubDirectory} from "utils/defaults";
import { APIKey } from "utils/config";
import { PRODUCTION } from "utils/defaults";
import { DevWebSocketURL } from "utils/defaults";
import { DevHttpURL } from "utils/defaults";
import { ProductionHttpURL } from "utils/config";
import { ProductionWebSocketURL } from "utils/config";

const alertOptions = {
  timeout: 6000,
  position: positions.BOTTOM_CENTER
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: APIKey,
    }
  }
});

let WebSocketURL = DevWebSocketURL;
let HttpURL = DevHttpURL;

if(PRODUCTION){
  WebSocketURL = ProductionWebSocketURL;
  HttpURL = ProductionHttpURL;
}

const wsLink = new WebSocketLink({
  uri: WebSocketURL,
  options: {
    reconnect: true,
  },
});

const httpLink = new HttpLink({
  uri: HttpURL,
  credentials: "include",
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);
  }
  if (networkError) {
    console.log('networkError', networkError);
  }
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  errorLink
});

ReactDOM.render(
    <Provider template={AlertTemplate} {...alertOptions}>
  <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <Route path={"/" + SubDirectory} render={(props) => <Admin {...props} />} />
            {/* <Redirect from="/" to={"/" + SubDirectory + "/dashboard"} /> */}

                {getLoggedInUser().company && getLoggedInUser().auctionid &&
                  <Redirect from="*" to={"/" + SubDirectory + "/dashboard"} />
                }

                {!getLoggedInUser().company && getLoggedInUser().auctionid &&
                  <Redirect from="*" to={"/" + SubDirectory + "/switch-company"} />
                }

                {getLoggedInUser().company && !getLoggedInUser().auctionid &&
                  <Redirect from="*" to={"/" + SubDirectory + "/switch-auction"} />
                }

                {!getLoggedInUser().company && !getLoggedInUser().auctionid &&
                  <Redirect from="*" to={"/" + SubDirectory} />
                  // <Redirect from="*" to={"/" + SubDirectory + "/switch-company"} />
                }
          </Switch>
        </BrowserRouter>
  </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);
