import { gql } from '@apollo/client';

export const GET_USER_LOGIN = gql`
query GetUserLogin($username : String!, $password : String!) {
  getUserLogin(username: $username, password: $password) {
    firstname
    lastname
    mainsec
    userid
    logintoken
    companyid
    auctionlist
  }
}
`;

export const GET_ALL_COMPANY_USERS = gql`
query getAllUserCompany($companyid : String!, $auctionid : String) {
  getAllUserCompany(companyid: $companyid, auctionid: $auctionid) {
    userid
    firstname
    lastname
    email
    address
    address2
    city
    state
    zip
    country
    phone
    emailoptin
    notes
    datetimecreated
    datetimelastlogin
    securitylevel
    active
    lastupdated
    lastmodifiedbyid
    username
    ucactive
    auctionlist{
      auctionid
      biddernumber
      id
      types
      active
      totalbought
      totalsold
    }
  }
}
`;
export const GET_USER_INFO = gql`
query getUserCompanyById($companyid : String!, $userid : String!) {
  getUserCompanyById(companyid: $companyid, userid: $userid) {
    userid
    firstname
    lastname
    email
    address
    address2
    city
    state
    zip
    country
    phone
    emailoptin
    notes
    settings
    datetimecreated
    datetimelastlogin
    securitylevel
    active
    lastupdated
    lastmodifiedbyid
    username
    ucactive
    auctionlist{
      auctionid
      biddernumber
      id
      types
      active
    }
  }
}
`;


export const GET_USER_SETTINGS = gql`
query getUserCompanyById($companyid : String!, $userid : String!) {
  getUserCompanyById(companyid: $companyid, userid: $userid) {
    settings
  }
}
`;