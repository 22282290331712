import React from "react";

import { 
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CustomInput,  } from "reactstrap";

import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import XTable from "../../components/custom/table";
import { useHistory } from "react-router-dom";

function CompanyUserList(props){
  let history = useHistory();

  const RowClick = (t) => GoToCustomerProfile(t.original.userid);

  const GoToCustomerProfile = (index) =>{
    history.push({
      pathname: '/auction/user-profile',
      state: {userid: index}
    })
  }
  
  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "firstname",
      Cell: (props) => (
        <span>{props.row.original.firstname + ' ' + props.row.original.lastname} </span>
      ),
    },
    {
      Header: "Username",
      accessor: "username",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "View",
      accessor: 'userid',
      className: "t-cell-4 text-center mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) => (
        <span  onClick={() => GoToCustomerProfile(props.value) }><i className="tim-icons icon-pencil"></i></span>
      )
    },
  ];

  
  return (
    <>
     <Card>
      <CardHeader>
        <CardTitle tag="h4">Customer List</CardTitle>
      </CardHeader>
      <CardBody>
        {/* <span className="float-left ">
          <CustomInput
            checked={props.liveDataStatus}
            className="table-search-switch"
            type="switch"
            name="customSwitch2CustomerList2221"
            id="customSwitch2CustomerList2221"
            onChange={props.setLiveData}
            label="Show Live Data"
          />
        </span> */}

        <XTable RowClick = {RowClick} columns={listHeader} loading={false} data={props.userlist} filterbutton={props.filter} />
      </CardBody>
    </Card>
</>

  )
}
export default CompanyUserList;