import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  CustomInput
} from "reactstrap";
import { CREATE_USER } from "../../graphql/mutations/customers";
import { useMutation } from "@apollo/client";
import { getLoggedInUser } from "utils/useToken";
import {stateArray, countryArray} from "utils/arrays";
import {DefaultUserPassword} from "utils/defaults";
import { handleInputFocus } from "utils/functions";
import { useAlert } from "react-alert";

import Autocomplete from "react-autocomplete";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";


function AddCustomer(props){
  const alert = useAlert();
  const inputRef = useRef()
  const inputRef2 = useRef()

  const [createUser] = useMutation(CREATE_USER, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  const [result, setResult] = useState([]);
  
  const [searchExisting, setSearchExisting] = useState('');

  const [checkboxes, setCheckboxes] = useState([{typeid: 'auctionadmin', value: false}])

  useEffect(() => {
    if(props.customertypes.length > 0) {
      props.customertypes.map(obj => {
        if(obj.typeid !== undefined)
          return setCheckboxes(checkboxes => [...checkboxes, {typeid:obj.typeid, value:false}])
        else return false
      });
    }
}, [props.customertypes]);

  const ResetInput = () => {
    setUserData({
      userid: '',
      firstname: '',
      lastname: '',
      emailaddress: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: 0,
      country: 'US',
      phone: '',
      biddernumber: 0,
      emailoptin: false,
      types:[],
      auctionadmin:false,
      notes:'',
      newcustomer:true,
      username: '',
      password: DefaultUserPassword,
      lastmodifiedbyid: getLoggedInUser().userid,
      auctionid: getLoggedInUser().auctionid,
      companyid: getLoggedInUser().company,
      lastmodifiedbyuser: getLoggedInUser().name,
      auctioncustomer: true
    });

    setSearchExisting('');
    inputRef.current.focus();

    let org = checkboxes;
    let newArray = [];
      org.map(obj => {
        obj.value = false;
        return newArray.push(obj);
      });
    setCheckboxes(newArray)
  }

  
  useEffect(() => {
    if(result){
      if(result.length > 0){
        result.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [result, alert]);
  
  const [userdata, setUserData] = useState({
    userid: '',
    firstname: '',
    lastname: '',
    emailaddress: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: 0,
    country: 'US',
    phone: '',
    biddernumber: 0,
    emailoptin: false,
    types:[],
    auctionadmin:false,
    notes:'',
    newcustomer:true,
    username: '',
    password: DefaultUserPassword,
    lastmodifiedbyid: getLoggedInUser().userid,
    auctionid: getLoggedInUser().auctionid,
    companyid: getLoggedInUser().company,
    lastmodifiedbyuser: getLoggedInUser().name,
    auctioncustomer: true
  });

  const userAutoFill = (info) =>{
    setUserData({...userdata, 
      username:info.username,
      userid:info.userid,
      firstname:info.firstname,
      lastname:info.lastname,
      emailaddress:info.email,
      address1:info.address,
      address2:info.address2,
      city:info.city,
      state:info.state,
      zip:info.zip,
      country:info.country,
      phone:info.phone,
      notes:info.notes,
      emailoptin: false,
      newcustomer:false,
    });

    inputRef2.current.focus();
  }

  const CreateCustomer = () => {
    setResult([]);
    createUser({ variables: { thedata: userdata } })
    .then((data2) => {
        if(!data2.errors){
          //TODO: Modify cookie if needed
          if(data2.data.createUser.userid === getLoggedInUser().userid){
            let oldAuctionList = getLoggedInUser().auctionlist;
            let currentStorage = getLoggedInUser();
            
            let auctioninfo = getLoggedInUser().auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid);
            auctioninfo.biddernumber = userdata.biddernumber;

            let newAuctionList = oldAuctionList.filter(a => a.auctionid !== getLoggedInUser().auctionid);
            newAuctionList.push(auctioninfo);

            currentStorage.auctionlist = newAuctionList;
            localStorage.setItem('px3auction', JSON.stringify(currentStorage));
          }
          setResult( () => [userdata.firstname + " " + userdata.lastname + " - Customer Added Successfully"])
          setTimeout( () => ResetInput(), 500);
        }
      }
    )
    .catch((err) => console.log(err));
  }
  useEffect(() => {
    if(userdata.newcustomer){
      const RandomNumber =  Math.floor(1000 + Math.random() * 9000);
      let FirstCharacter;

      if(userdata.firstname !== '')
        FirstCharacter = (userdata.firstname).charAt(0);

      if(userdata.firstname !== '' && userdata.lastname !== ''){
        const NewUsername = FirstCharacter + userdata.lastname + RandomNumber
        setUserData({...userdata, username: NewUsername});
      }
    }
  }, [userdata.firstname, userdata.lastname]);

  const NewCustomerOnChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseInt(value);
      }
      else{
        if(e.target.type !== "textarea" && (e.target.name === "firstname" || e.target.name === "lastname"))
          value = value.trim();
      }

      if(type === 'boolean') value = !!parseInt(value);

      setUserData(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setUserData(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }
  return (
    <Card>
    <CardHeader>
      <CardTitle tag="h4">Add New Customer
        <Button 
          className="btn-fill" 
          color="info" 
          type="button" 
          style={{float:"right"}}
          onClick={e => CreateCustomer() }
          >
            Add User
          </Button>
          <br style={{clear:"both"}} />
       </CardTitle>
      <CardTitle tag="h4">Username: {userdata.username} <span className='mw-Right'> Default Password: {DefaultUserPassword}</span></CardTitle>
    </CardHeader>
      <CardBody>
          <Form>
            <Row>
                <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Auction ID (disabled)</label>
                  <Input
                    defaultValue={getLoggedInUser().auctionid}
                    disabled
                    placeholder="Auction"
                    type="text"
                    autoComplete = "off"
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Auction Name (disabled)</label>
                  <Input
                    defaultValue={ getLoggedInUser().auctionlist[(getLoggedInUser().auctionlist).map(a => a.auctionid).indexOf(getLoggedInUser().auctionid)].auctionname }
                    disabled
                    placeholder="Auction"
                    type="text"
                    autoComplete = "off"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
                <Col className="pr-md-1" md="9">
                  <FormGroup>
                    <label>Search Existing</label>
                    <Autocomplete
                      ref={inputRef}
                      inputProps={{ id: 'states-autocomplete', className:'form-control', placeholder:'First Name', autoFocus: true }}
                      wrapperStyle={{ position: 'relative', display: 'block' }}
                      getItemValue={(item) => item.firstname + ' ' + item.lastname}
                      items={props.autocompleteList}
                      renderItem={(item, isHighlighted) =>
                        <div style={{ background: isHighlighted ? 'lightgray' : 'white' }} key = {item.userid}>
                          {item.firstname + ' ' + item.lastname}
                        </div>
                      }
                      shouldItemRender={(item, value) =>{
                        const a = item.firstname + ' ' + item.lastname;
                        return(
                          a.toLowerCase().indexOf(value.toLowerCase()) > -1
                        )
                      }} 

                      renderMenu={(items, value) => (
                        <div className="menu">
                          {value === '' ? (
                            <div className="item" style={{color:'#FFF'}}>
                            </div>
                          )
                            : items.length === 0 ? (
                            <div className="item" style={{color:'#FFF'}}>No matches for {value}</div>
                          ) : items}
                        </div>
                      )}
            
                      value={searchExisting }
                      onChange={ (event, value) => {
                        setSearchExisting(value)
                      }}
                      onSelect={ (value, item) => {
                        userAutoFill(item);
                        setSearchExisting(value);
                      }}
                    />
                </FormGroup>
              </Col>

              <Col className="pr-md-1" md="3">
                <FormGroup style={{textAlign: 'center'}}>
                  <label>New Customer</label>
                  <CustomInput
                    checked={userdata.newcustomer}
                    className="table-search-switch"
                    type="switch"
                    name="customSwitchNewCustomer"
                    id="customSwitchNewCustomer"
                    onChange={ (e) => {
                      setUserData(prevState => {
                        return { ...prevState, newcustomer: !userdata.newcustomer}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
      
          <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={(e) => CreateCustomer() }>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* First Name: </label>
                  <Input
                    placeholder="First Name"
                    type="text"
                    name="firstname"
                    value={userdata.firstname}
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                  <label >
                    * Last Name:
                  </label>
                  <Input
                    placeholder="Last Name" 
                    type="text" 
                    name="lastname"
                    value={userdata.lastname}
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onChange={ e => NewCustomerOnChange(e) }
                    />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label>Email:</label>
                  <Input
                    value={userdata.emailaddress}
                    placeholder="Email Address"
                    type="text"
                    name="emailaddress"
                    autoComplete = "off"
                    readOnly={!userdata.newcustomer}
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* Address:</label>
                  <Input
                    value={userdata.address1}
                    placeholder="Address"
                    type="text"
                    name="address1"
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                  <label>* City: </label>
                  <Input
                    value={userdata.city}
                    placeholder="City"
                    type="text"
                    name="city"
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label>* State: </label>
                  <select className='form-control'
                    name="state"
                    value={userdata.state}
                    readOnly={!userdata.newcustomer}
                    onChange={ e => NewCustomerOnChange(e) }
                  >
                    <option value="">Select State</option>
                    {stateArray.map((value, key) => {
                      return (
                        <option value={value.abbreviation} key={key}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* Zip Code: </label>
                  <Input
                    placeholder="12345"
                    type="number"
                    name="zip"
                    value={userdata.zip}
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onFocus={handleInputFocus}
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                  <label >
                    Address 2:
                  </label>
                  <Input
                    placeholder="Address 2" 
                    type="text" 
                    name="address2"
                    value={userdata.address2}
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onChange={ e => NewCustomerOnChange(e) }
                    />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label>* Country: </label>
                  <select className='form-control'
                    name="country"
                    readOnly={!userdata.newcustomer}
                    onChange={ e => NewCustomerOnChange(e) }
                  >
                    {countryArray.map((value, key) => {
                      return (
                        <option value={value.code} key={key}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Phone:</label>
                  <Input
                    placeholder="Phone"
                    type="text"
                    name="phone"
                    value={userdata.phone}
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* Bidder Number: </label>
                  <input
                    className="form-control"
                    placeholder="0"
                    ref={inputRef2}
                    type="number"
                    name="biddernumber"
                    value={userdata.biddernumber}
                    autoComplete = "off"
                    onFocus={handleInputFocus}
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                  <label >
                    Email Opt In:
                  </label>
                  <select className='form-control'
                    name="emailoptin"
                    readOnly={!userdata.newcustomer}
                    onChange={ e => NewCustomerOnChange(e, 'boolean') }
                  >
                  <option value='0'>No</option>
                  <option value='1'>Yes</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            {props.customertypes && checkboxes.length > 0 &&
              <Row>
                <Col className = "pr-md-1" md="12">
                <label>Customer Type:</label>
                  <FormGroup check>
                  {getLoggedInUser().px3.company &&
                      <Label check>
                        <Input 
                          type="checkbox" 
                          className = 'px3Checkbox' 
                          value = {1}
                          checked={  checkboxes.find((e)=>e.typeid === "auctionadmin").value  }
                          onChange = {() => {
                            let newValue = false;
                            if(!userdata.auctionadmin)
                              newValue = true;
                          
                            let orgCheckboxArray = checkboxes;
                            let newCheckboxArray = orgCheckboxArray;

                            newCheckboxArray = orgCheckboxArray.filter(type => type.typeid !== 'auctionadmin');
                            newCheckboxArray.push({typeid: 'auctionadmin', value: newValue})

                            NewCustomerOnChange({name: "auctionadmin", value: newValue}, 'array')

                            setCheckboxes(newCheckboxArray);
                          }}
                        /> {' '}
                        Auction Admin
                      </Label>
                  }
                    {props.customertypes.length > 0 && checkboxes.length > 1 && props.customertypes.map((value, key) => {
                      return (
                          <Label check className="typeCheckbox" key = {key}>
                            <Input 
                              type="checkbox" 
                              className = 'px3Checkbox' 
                              checked={  checkboxes.find((e)=>e.typeid === value.typeid).value  }
                              value={value.typeid} 
                              onChange={(e) => {
                                let origArray = userdata.types;
                                let newTypeArray = origArray;

                                let orgCheckboxArray = checkboxes;
                                let newCheckboxArray = orgCheckboxArray;
                                newCheckboxArray = orgCheckboxArray.filter((ee)=>ee.typeid !== e.target.value);

                                if(userdata.types.indexOf(e.target.value) > -1){
                                    newTypeArray = origArray.filter(type => type !== e.target.value);
                                    newCheckboxArray.push({typeid: e.target.value, value: false})
                                }
                                else {
                                  newTypeArray.push(e.target.value)
                                  newCheckboxArray.push({typeid: e.target.value, value: true})
                                }
                                
                                NewCustomerOnChange({name: "types", value:newTypeArray}, 'array');
                        
                                setCheckboxes(newCheckboxArray);
                              }}
                            /> {' '}
                            {value.name}
                          </Label>
                      );
                    })}
                  </FormGroup>
                </Col>
              </Row>              
            }

            <Row><Col md="12">&nbsp;</Col></Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label>Notes (Shift + Enter for new line):</label>
                  <Input
                    cols="80"
                    value={userdata.notes}
                    placeholder="Here can be your notes"
                    rows="4"
                    type="textarea"
                    name="notes"
                    readOnly={!userdata.newcustomer}
                    autoComplete = "off"
                    onChange={ e => NewCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
            </Row>
          </KeyboardEventHandler>
          </Form>
      </CardBody>
      <CardFooter>
        <Button 
        className="btn-fill" 
        color="info" 
        type="button" 
        onClick={e => CreateCustomer() }
        >
          Add User
        </Button>
      </CardFooter>
    </Card>
  )
}
export default AddCustomer;