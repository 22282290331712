import React from "react";

import { 
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CustomInput,  } from "reactstrap";

import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import XTable from "../../components/custom/table";
import { useHistory } from "react-router-dom";
import { SellerType } from "utils/defaults";
import { getLoggedInUser } from "utils/useToken";
import { formatNumber } from "utils/functions";

function CustomerList(props){
  let history = useHistory();

  const RowClick = (t) => GoToCustomerProfile(t.index)

  const GoToCustomerProfile = (index) =>{
    history.push({
      pathname: '/auction/customer-profile',
      state: {edituserdata: props.userlist[index], itemeditmode: true, prevPage:"customers"}
    })
  }
  
  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "firstname",
      Cell: (props) => (
        <span>{props.row.original.firstname + ' ' + props.row.original.lastname} </span>
      ),
    },
    {
      Header: "Number",
      accessor: "biddernumber",
      className: "t-cell-2 text-centered",
    },
   
    {
      Header: "Username",
      accessor: "username",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "Seller",
      accessor: row => Number( (row.auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid)).types.includes(SellerType.typeid)),
      className: "t-cell-3 text-centered mwTable-15",
      Cell: (props) => (
        <span>{
          props.value ? "Yes" : "No"
        } </span>
      ),
    },
    {
      Header: "View",
      accessor: 'id',
      className: "t-cell-4 text-center mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) => (
        <span  onClick={() => GoToCustomerProfile(props.row.index) }><i className="tim-icons icon-pencil"></i></span>
      )
    },
  ];

  if(props.view){
    listHeader.splice(1, 0, {
      Header: "Status",
      accessor: row => Number((row.auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid)).active),
      className: "t-cell-3 text-centered mwTable-15",
      Cell: (props) => (
        <span className = "specialhover">{
          props.value === 1 ? "Active" : <span style={{color:'red'}}>Deactivated</span>
        } </span>
      ),
    });

    listHeader.splice(2, 0, {
      Header: "Bought",
      accessor: row => (row.auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid)).totalbought,
      className: "t-cell-2 text-centered",
      Cell: (props) => formatNumber(props.value)
    }
   );

    listHeader.splice(3, 0, { 
      Header: "Sold",
      accessor: row => (row.auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid)).totalsold,
      className: "t-cell-2 text-centered",
      Cell: (props) => formatNumber(props.value)
    },)

    listHeader.splice(4, 0, { 
      Header: "Net",
      accessor: row => (parseFloat((row.auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid)).totalsold * 0.85) - parseFloat((row.auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid)).totalbought) ).toFixed(2), //TODO: CHANGE COMMISION TO VARIABLE
      className: "t-cell-2 text-centered",
      Cell: (props) => formatNumber(props.value)
    },)

  }
    
  
  return (
    <>
     <Card>
      <CardHeader>
        <CardTitle tag="h4">Customer List</CardTitle>
      </CardHeader>
      <CardBody>
        {/* <span className="float-left ">
          <CustomInput
            checked={props.liveDataStatus}
            className="table-search-switch"
            type="switch"
            name="customSwitch2CustomerList"
            id="customSwitch2CustomerList"
            onChange={props.setLiveData}
            label="Show Live Data"
          />
        </span> */}

<div id="managerTable">
        <XTable defaultPageSize ={props.rowCount ? props.rowCount : 100} RowClick = {RowClick} columns={listHeader} loading={false} data={props.userlist} filterbutton={props.filter} defaultSort = {props.view ? [{ id: "biddernumber", desc: false}] : [{ id: "biddernumber", desc: true}] } />
        </div>
      </CardBody>
    </Card>
</>

  )
}
export default CustomerList;