
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useLazyQuery } from "@apollo/client";

import { GET_USER_LOGIN } from "../graphql/queries/users";
import { isEmptyObject } from "jquery";
import { useHistory } from "react-router-dom";
import { SubDirectory } from "utils/defaults";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";


function Login({setToken, SetNavBarData})  { 
const [getUserLogin, { data, error, loading, onError }] = useLazyQuery(GET_USER_LOGIN);

const [username, setUserName] = useState();
const [password, setPassword] = useState();
const [result, setResult] = useState();
const [selectedAuction, setAuction] = useState();
const [selectedCompany, setCompany] = useState();
const [selectedCompanyName, setCompanyName] = useState();
const [companyadmin, setCompanyAdmin] = useState(false);
const [userdata,  setUserData] = useState({})

let history = useHistory();

  const TryLogin = () => {
    if(username !== undefined && password !== undefined){
      getUserLogin({ variables: {username: username,  password: password}})
    }
  }

  const CheckCompanyAdminAccess = (cid) => {
    const index = userdata.companylist.map(c => c.companyid).indexOf(cid);
    if(userdata.companylist[index].sec === 1)  //company admin - show continue button 
      setCompanyAdmin( () => true );
    else //not company admin - hide continue buttonn annd show auction dropdownn
      setCompanyAdmin( () => false );
    
    setAuction ( () => undefined);
    setCompany(() => cid);
    setCompanyName(() => userdata.companylist[index].companyname)
  }

  const TryLoginStep2 = () => {
    // if(companyadmin){
    //   getAllAuctions({variables: {companyid: selectedCompany}})
    //   .then((e) => {
    //     let tmpArray = [];
    //     e.data.getAllAuctions.map((al) => {
    //         tmpArray.push(al);
    //     });
    //     FinalizeLogin(tmpArray);
    //   })
    // }
    // else
      FinalizeLogin();
  }

  const FinalizeLogin = (companyAuctions = undefined) => {
    let auctionadmin = false;

    if(companyadmin) auctionadmin = true;
    else{
      const index = userdata.auctionlist.map(a => a.auctionid).indexOf(selectedAuction);
      if(userdata.auctionlist[index].auctionsec === 1) //auction admin 
        auctionadmin = true;
      else //not auction admin
        auctionadmin = false;
    }

    const loginobject = {
      logintoken: userdata.logintoken,
      name: userdata.name,
      userid: userdata.userid,
      px3: {
        company: companyadmin,
        auction: auctionadmin,
        main: userdata.main,
      },
      auctionid: selectedAuction,
      company: selectedCompany,
      companyname: selectedCompanyName,
    }

    // if(!companyadmin) {
      let tmpArray = [];
      if(userdata.auctionlist){
        userdata.auctionlist.map((al) => {
          if(al.companyid === selectedCompany)
            return tmpArray.push(al);
          else return false
        });
      }
      //loginobject.auctionlist = userdata.auctionlist;
      loginobject.auctionlist = tmpArray;
    // }
    // else{
    //   let tmpArray = [];
    //   companyAuctions.map((al) => {
    //     tmpArray.push({
    //       auctionid: al.auctionid,
    //       auctionname: al.nickname,
    //       companyid: selectedCompany
    //     })
    //   });
    //   loginobject.auctionlist = tmpArray;
    // }

    let auctioninfo = loginobject.auctionlist.find(a => a.auctionid === loginobject.auctionid);

    SetNavBarData(prevState => {
      return{ ...prevState, Name: loginobject.name, AuctionName: auctioninfo ? auctioninfo.auctionname : "", CompanyName: selectedCompanyName}
    })

    localStorage.setItem('px3auction', JSON.stringify(loginobject));
    setToken(loginobject);
    if(history.location.pathname === "/" + SubDirectory + "/login") history.push("/" + SubDirectory + "/dashboard");
  }

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setResult( () => undefined );

         const obj = {
          logintoken: data.getUserLogin.logintoken,
          name: data.getUserLogin.firstname + ' ' + data.getUserLogin.lastname,
          userid: data.getUserLogin.userid,
          companylist: JSON.parse(data.getUserLogin.companyid),
          auctionlist: JSON.parse(data.getUserLogin.auctionlist),
          main: data.getUserLogin.mainsec,
        };

        SetNavBarData(prevState => {
          return{ ...prevState, Name: obj.name}
        })

        if(data.getUserLogin.mainsec === 13){
          //Mike W Access or Master Admin Access
          //TODO: Might be able to trim down some of this data. If master account, may not need auctionns and companies
          obj.px3 = {
            main: data.getUserLogin.mainsec,
            company: true,
            auction: true
          };
          obj.company = 0;
          obj.auctionid = 0;

          localStorage.setItem('px3auction', JSON.stringify(obj));
          setToken(obj);
        }
        else{
          //Company Admin, Auction Admin, Normal  User
          setUserData( ()=> obj );
        }
        if(history.location.pathname === "/" + SubDirectory + "/login") history.push("/" + SubDirectory + "/dashboard");
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } 
      else if (!loading && error) {
        PopupError(error.message);
      }
    }
  }, [loading, data, error, onError, setToken, history, SetNavBarData]);

  const onEnter = (event, go) => event.key === 'Enter' && go()

    return (
        <div className="content">
          <Row style={{'margin': '0'}}>
          <Col lg="6" md="8" sm = "12" className="mw-Login">
              <Card>
              <CardHeader>
                <CardTitle tag="h4">CMC Auctions - Login</CardTitle>
              </CardHeader>
                <CardBody>
                  <Form>
                    <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => TryLogin() }>
                      <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>Username</label>
                          <Input
                            placeholder="Username"
                            type="text"
                            name="username"
                            autoComplete = "off"
                            onChange={e => setUserName(e.target.value)}
                            onKeyPress={e => onEnter(e)}
                            autoFocus 
                            disabled={!isEmptyObject(userdata) === true ? true : false}
                            />
                        </FormGroup>
                      </Col>
                      </Row>
                      <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label >
                            Password
                          </label>
                          <Input
                            placeholder="" 
                            type="password" 
                            name="password"
                            autoComplete = "off"
                            onChange={e => setPassword(e.target.value)}
                            disabled={!isEmptyObject(userdata) === true ? true : false}
                            />
                        </FormGroup>
                      </Col>
                      </Row>
                    </KeyboardEventHandler>

                    {result &&
                      <Row>
                        <Col className="pr-md-1" md="12">
                          <span style={{color:'red'}}>{result}</span>
                        </Col> 
                      </Row>
                    }

                  {userdata && userdata.companylist &&
                      <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                        <label > Company </label>
                      <select 
                        className='form-control mw-select'
                        onChange={e => CheckCompanyAdminAccess(e.target.value) }
                      >
                        <option value=''>Select a Company</option>
                          {
                            userdata.companylist.map((company) => {
                              return(
                                <option key={company.companyid} value={company.companyid}>{company.companyname}</option>
                              )
                            })
                          }
                      </select>
                      </FormGroup>
                      </Col>
                    </Row>
                  }

                  {userdata && userdata.auctionlist && selectedCompany && !companyadmin &&
                      <Row>
                      <Col className="pr-md-1" md="12">
                        <FormGroup>
                        <label > Auction </label>
                      <select
                      onChange={e => setAuction(e.target.value) }
                      className='form-control mw-select'>
                        <option value=''>Select an Auction</option>
                          {
                            userdata.auctionlist.map((auctioninfo) => {
                              if(auctioninfo.companyid === selectedCompany) {
                                return(
                                  <option key={auctioninfo.auctionid} value={auctioninfo.auctionid}>{auctioninfo.auctionname}</option>
                                )
                              }
                              else return false
                            })
                          }
                      </select>
                      </FormGroup>
                      </Col>
                    </Row>
                  }
                  </Form>
                </CardBody>
                <CardFooter>

                  { isEmptyObject(userdata) &&
                    <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick={
                      () => { TryLogin() }
                    }
                    >
                      Login
                    </Button> 
                  }

                  { ( (selectedAuction && selectedCompany && companyadmin === false) || (selectedCompany && companyadmin) ) &&
                    <Button 
                    className="btn-fill" 
                    color="info" 
                    type="button" 
                    onClick={
                      () => { TryLoginStep2() }
                    }
                    >
                      Continue
                    </Button>
                  }
                  
                  <Button 
                  className="btn-fill" 
                  color="info" 
                  type="button" 
                  style={{float: 'right'}}
                  >
                    More Info
                  </Button>
                  <br style={{clear:'both'}} />
                  <p style={{'paddingTop': '20px', 'textAlign': 'center', color:'#FFF'}}>
                    Forgot Password
                  </p>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }

  // Login.propTypes = {
  //   setToken: PropTypes.func.isRequired
  // }

export default Login;
