import { gql } from '@apollo/client';

export const GET_TYPES = gql`
query getAllTypes($companyid : String!, $activefilter: Boolean, $target: Float) {
  getAllTypes(companyid: $companyid, activefilter: $activefilter, target: $target) {
    typeid   
    name
    description
    active
    target
    lastmodifiedbyid
    lastupdated
  }
}
`;