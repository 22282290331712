import { getLoggedInUser } from "utils/useToken";
import { GET_PAYMENT_METHODS } from "graphql/queries/paymentmethods";
import { GET_REPORT_PAYMENTS } from "graphql/queries/payment";
import { useEffect, useState } from "react";
import { formatNumber } from "utils/functions";
import { useLazyQuery, useQuery } from "@apollo/client";
import XTable from "components/custom/table";
import { GET_UNSOLD_ITEMS } from "graphql/queries/items";

const {  CardBody, Card, Col, Row, FormGroup, CardHeader, CardTitle } = require("reactstrap");

function UnsoldItemsReport(){
  const { data: pmd, loading: pml, error: pmerror, onError:  pmonerror } = useQuery(GET_UNSOLD_ITEMS,{
    variables: {
      auctionid: getLoggedInUser().auctionid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [pmlist, setPmList] = useState([]);

  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered",
      accessor: "name",
    },
    {
      Header: "Price",
      accessor: "price",
      className: "t-cell-2 text-centered",
      Cell: (props) =>(
        <span>{formatNumber(props.value)}</span>
      )
    },
    {
      Header: "Qty",
      accessor: "qty",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "Description",
      accessor: "description",
      className: "t-cell-3 text-centered"
    },
    {
      Header: "Seller",
      accessor: "sellerbiddernumber",
      className: "t-cell-4 text-center mwTable-15"
    },
  ];

  useEffect(() => {
    const onCompleted = (pmd) => { 
      if(pmd){
        setPmList( () => pmd.getUnsoldItems);
      }
    }
    if (onCompleted || pmonerror) {
      if (onCompleted && !pml && !pmerror) {
        onCompleted(pmd);
      } else if (pmonerror && !pml && pmerror) {
        pmonerror(pmerror);
      }
    }
  }, [pml, pmd, pmerror, pmonerror]);


  return (
    <div className="content">
      <Card>
      <CardHeader>
        <CardTitle tag="h4">Unsold Items</CardTitle>
      </CardHeader>
        <CardBody>

            {pmlist &&
              <XTable  defaultPageSize={9999} columns={listHeader} loading={false} data={pmlist} filterbutton={false} />
            }
        </CardBody>
      </Card>
    </div>
  )
}

export default UnsoldItemsReport;