import React,  { useState, useEffect } from "react";
import {ITEM_ADDED, ITEM_DELETED, ITEM_DELETED_ADMIN, ITEM_UPDATED } from "../../graphql/subscriptions/items";
import { GET_ALL_ITEMS, GET_ITEM_TYPES } from "../../graphql/queries/items";

import { useQuery } from "@apollo/client";
import { 
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CustomInput,
  Button,  } from "reactstrap";

import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import XTable from "../../components/custom/table";
import { getLoggedInUser } from "utils/useToken";
import Item from "components/custom/Item";
import { formatNumber } from "utils/functions";

export const ItemList = (props) => {
  let getByBidderNumber = 0; 
  if(props.biddernumber) getByBidderNumber = props.biddernumber;

  const [itemview, setItemView] = useState("list");

  const { loading, error, data, subscribeToMore, onError, refetch } = useQuery(GET_ALL_ITEMS,{
    variables: {
      auctionid: getLoggedInUser().auctionid,
      biddernumber: parseInt(getByBidderNumber),
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const { data: types } = useQuery(GET_ITEM_TYPES,{
    variables: {
      companyid: getLoggedInUser().companyid,
      target: 1
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });
  const [itemlist, setItemList] = useState([{}]);
  const [itemsbought, setItemsBought] = useState([{}]);
  const [itemssold, setItemsSold] = useState([{}]);
  const [modaldata, setModalData] = useState({});
  const [itemviewtype, setItemViewType] = useState("all")

  const [itemtotal, setItemTotal] = useState({
    boughttotal: 0,
    soldtotal: 0,
    all: 0
  })

  const RowClick = (t) => toggle(t);

  const toggle = (t) => {
    let editItemId = t.original.itemid;
    if(editItemId !== undefined){
      const index = itemlist.map(item => item.itemid).indexOf(editItemId);
      setModalData(() => itemlist[index]);
      setItemView("item");
    }
  }

  const listHeader = [
    {
      Header: "Item",
      className: "t-cell-1 text-centered",
      accessor: "name",
    },
    {
      Header: "Price",
      accessor: "price",
      className: "t-cell-2 text-centered",
      Cell: (props) =>(
        <span>{
          props.row.original.qty > 1 ? formatNumber(props.value * props.row.original.qty) + " (" + formatNumber(props.value) + "/ea)" : formatNumber(props.value)
          }</span>
      )
    },
    {
      Header: "Qty",
      accessor: "qty",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "Buyer",
      accessor: "buyerbiddernumber",
      className: "t-cell-3 text-centered mwTable-15"
    },
    {
      Header: "Seller",
      accessor: "sellerbiddernumber",
      className: "t-cell-4 text-center mwTable-15"
    },
    {
      Header: "Edit",
      accessor: 'itemid',
      className: "t-cell-4 text-center mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) => (
        <span itemID={props.value} onClick={ () => toggle(props.row) }><i className="tim-icons icon-pencil"></i></span>
      )
    },
  ];

  const [livedata, setLiveData] = useState(true);
  const toggleLiveData = () => {
    if(!livedata)  refetch()
    setLiveData(!livedata);
  };
  
  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        let itemarray = [];
        let buyeritemarray = [];
        let selleritemarray = [];

        let boughttotal = 0, soldtotal = 0, all = 0;

        data.getAllItems.map((item) => {
          const TheItem = {
            sellerbiddernumber:item.sellerbiddernumber, 
            buyerbiddernumber :item.buyerbiddernumber,
            price: item.price.toFixed(2),
            description: item.description,
            name: item.name,
            qty: item.qty,
            lastmodifiedbyid: item.lastmodifiedbyid,
            auctionid: item.auctionid,
            itemid: item.itemid,
            datetime: item.datetime,
            lastupdated: item.lastupdated,
            sellername: item.sellername,
            buyername: item.buyername,
            typeid: item.typeid,
            lastmodifiedbyuser: item.lastmodifiedbyuser
          }
          
          itemarray.push(TheItem)

          // all += parseFloat(TheItem.price); //TODO: SUBTRACT

          if(getByBidderNumber > 0){
            if(item.buyerbiddernumber === getByBidderNumber){
              boughttotal += parseFloat(TheItem.price) * TheItem.qty;

              // return(
                buyeritemarray.push( TheItem )
              // )
            }
            if(item.sellerbiddernumber === getByBidderNumber){
              soldtotal += parseFloat(TheItem.price) * TheItem.qty;

              // return(
                selleritemarray.push( TheItem )
              // )
            }
          }
        })

        if(getByBidderNumber > 0){
          setItemsBought(() => buyeritemarray);
          setItemsSold(() => selleritemarray);
        }

        setItemTotal({
          boughttotal,
          soldtotal,
          all: soldtotal - boughttotal
        })

        return(
          setItemList(() => itemarray)
        )
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);


  useEffect(() => {
    let itemCreateUnsubscribe;
    if(livedata){

     itemCreateUnsubscribe = subscribeToMore({
        document: ITEM_ADDED,
        variables:{auctionid: getLoggedInUser().auctionid, biddernumber: getByBidderNumber},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newItem = subscriptionData.data.itemCreated;
          const mergedResullt = [newItem, ...prev.getAllItems];
          const returnResult = Object.assign({}, prev, { getAllItems: mergedResullt })
          return {
            getAllItems: returnResult
          }
        },
      }); 
    }

    if (itemCreateUnsubscribe) return () => itemCreateUnsubscribe()
  }, [subscribeToMore, data, livedata, getByBidderNumber]);

  useEffect(() => {
    let itemDeleteUnsubscribe;

    if(livedata){
      if(!props.auctionAdmin){
        itemDeleteUnsubscribe = subscribeToMore({
          document: ITEM_DELETED,
          variables:{auctionid: getLoggedInUser().auctionid, biddernumber: getByBidderNumber},
          updateQuery: (prev, { subscriptionData }) => {
            const newItem = subscriptionData.data.itemDeleted;
            let OldList = [...prev.getAllItems];
            const newList = OldList.filter((item) => item.itemid !== newItem);  //ES6
            return {
              getAllItems: newList
            };
          },
        }); 
      }
      else{
        itemDeleteUnsubscribe = subscribeToMore({
          document: ITEM_DELETED_ADMIN,
          variables:{auctionid: getLoggedInUser().auctionid, biddernumber: getByBidderNumber},
          updateQuery: (prev, { subscriptionData }) => {
            const newItem = subscriptionData.data.itemDeletedAdmin;
            let OldList = [...prev.getAllItems];
            const newList = OldList.filter((item) => item.itemid !== newItem);  //ES6
            return {
              getAllItems: newList
            };
          },
        }); 
      }
    }

    if (itemDeleteUnsubscribe) return () => itemDeleteUnsubscribe()
  }, [subscribeToMore, data, livedata, getByBidderNumber, props.auctionAdmin]);

  useEffect(() => {
    let itemUpdateUnsubscribe;

    if(livedata){
     itemUpdateUnsubscribe = subscribeToMore({
        document: ITEM_UPDATED,
        variables:{auctionid: getLoggedInUser().auctionid, biddernumber: getByBidderNumber},
        updateQuery: (prev, { subscriptionData }) => {
          const newItem = subscriptionData.data.itemUpdated;
          let MainList = [...prev.getAllItems];
          const index = MainList.map(item => item.itemid).indexOf(newItem.itemid);
          if(index === -1)
            MainList.unshift(newItem);
          else
            MainList[index] = newItem;
          return {
            getAllItems: MainList
          }
        }
      }); 
    }

    if (itemUpdateUnsubscribe) return () => itemUpdateUnsubscribe()
  }, [subscribeToMore, data, livedata, getByBidderNumber]);

  return (
    <>
    {itemview === 'list' &&
    <Card>
      <CardHeader>
        <CardTitle tag="h4">{props.title}</CardTitle>


        {getByBidderNumber > 0 && itemviewtype && 
          <span className="itemTotal">
            {itemviewtype.toUpperCase()} Total: { " " }
            { itemviewtype === "all" ? formatNumber(itemtotal.all) : itemviewtype === "bought" ? formatNumber(itemtotal.boughttotal) : itemviewtype === "sold" ? formatNumber(itemtotal.soldtotal) : formatNumber(itemtotal.all) }
          </span>
        }
        {getByBidderNumber > 0 && 
        <>
          <Button 
          className="btn-fill" 
          color="info" 
          type="button" 
          onClick = { () => setItemViewType("all")}
          >
            All
          </Button>

          <Button 
          className="btn-fill" 
          color="info" 
          type="button"
          onClick = { () => setItemViewType("bought")} 
          >
            Bought
          </Button>

          <Button
          className="btn-fill" 
          color="info" 
          type="button"
          onClick = { () => setItemViewType("sold")}
          >
            Sold
          </Button>
        </>
        }
      </CardHeader>
      <CardBody>
        {/* <span className="float-left ">
          <CustomInput
            checked={livedata}
            className="table-search-switch"
            type="switch"
            name="customSwitch266"
            id="customSwitch266"
            onChange={toggleLiveData}
            label="Show Live Data"
          />
        </span> */}

        <XTable RowClick={RowClick} columns={listHeader} loading={false} data={itemviewtype === "all" ? itemlist : itemviewtype === "bought" ? itemsbought : itemviewtype === "sold" ? itemssold : itemlist} filterbutton={true} />
      </CardBody>
    </Card>
    }


    {itemview === 'item' &&
        <Item itemdata={modaldata} editmode = {props.editmode} types={types} setItemView={setItemView} setFocus={props.setFocus}  itemUpdated = {props.itemUpdated} />
    }
</>
  );
};

