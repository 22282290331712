import { getLoggedInUser } from "utils/useToken";
import React from "react";
import { useHistory } from "react-router-dom";
import { SubDirectory } from "utils/defaults";

const { CardHeader, CardBody, CardFooter, Card, Col, Row, CardTitle, FormGroup, Button } = require("reactstrap");

function SwitchAuction(props){
  let history = useHistory();

  let tmpAuction;
  const SelectAuction = () => {
    if(tmpAuction !== "" && tmpAuction){
      let currentStorage = getLoggedInUser();
      currentStorage.auctionid = tmpAuction;

      localStorage.setItem('px3auction', JSON.stringify(currentStorage));
      
      let auctioninfo = {};

      if(getLoggedInUser().auctionid)
        auctioninfo = getLoggedInUser().auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid);
      else
        auctioninfo.auctionname = "";
      
      props.SetNavBarData(prevState => {
        return{ ...prevState, AuctionName: auctioninfo.auctionname}
      });

      history.push("/" + SubDirectory + "/dashboard");
      // history.goBack();
    }
  }

  return (
    <div className="content">
    <Row style={{'margin': '0'}}>
    <Col lg="6" md="8" sm = "12" className="mw-Login">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Auction Selection</CardTitle>
          </CardHeader>
          <CardBody>
            <p>Please select an auction before continuing</p>
            <FormGroup>
              <select 
                onChange={(e) => tmpAuction = e.target.value}
                className='form-control mw-select'>
                <option value=''>Select an Auction</option>
                  {
                    getLoggedInUser().auctionlist.filter(e => e.companyid === getLoggedInUser().company).map((auctioninfo) => {
                        return(
                          <option key={auctioninfo.auctionid} value={auctioninfo.auctionid}>{auctioninfo.auctionname}</option>
                        )
                    })
                  }
              </select>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button 
              className="btn-fill" 
              color="info" 
              type="button" 
              onClick={ () => SelectAuction()}
            >
              Continue
            </Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </div>
  )
}

export default SwitchAuction;