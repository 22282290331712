
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { getLoggedInUser } from "utils/useToken";
import { useQuery } from "@apollo/client";
import { GET_ALL_COMPANY_USERS } from "graphql/queries/users";
import { USER_COMPANY_ADDED } from "graphql/subscriptions/users";
import { USER_UPDATED } from "graphql/subscriptions/users";
import AddCompanyUser from "./AddCompanyUser";
import CompanyUserList from "./CompanyUserList";

function CompanyUsers()  { 
  const [view, setView] = useState(3);
  const [userlist, setUserList] = useState([{}]);


  const { data: cud, loading: cul, error: cuerror, onError:  cuonerror, subscribeToMore, refetch } = useQuery(GET_ALL_COMPANY_USERS,{
    variables: {
      companyid: getLoggedInUser().company,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [livedataCL, setLiveDataCL] = useState(true);
  const toggleLiveDataCL = () => {
    if(!livedataCL)  refetch()
    setLiveDataCL(!livedataCL); 
  };

  useEffect(() => {
    const onCompleted = (cud) => { 
      if(cud){
        setUserList( () => cud.getAllUserCompany);
      }
    }
    if (onCompleted || cuonerror) {
      if (onCompleted && !cul && !cuerror) {
        onCompleted(cud);
      } else if (cuonerror && !cul && cuerror) {
        cuonerror(cuerror);
      }
    }
  }, [cul, cud, cuerror, cuonerror]);


  useEffect(() => {
    let userCreateUnsubscribe;
    if(livedataCL){
     userCreateUnsubscribe = subscribeToMore({
        document: USER_COMPANY_ADDED,
        variables:{companyid: getLoggedInUser().company},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }
          const newUser = subscriptionData.data.userCreated;
          const mergedResullt = [newUser, ...prev.getAllUserCompany];
          const returnResult = Object.assign({}, prev, { getAllUserCompany: mergedResullt })
          return {
            getAllUserCompany: returnResult
          }
        },
      }); 
    }
  
    if (userCreateUnsubscribe) return () => userCreateUnsubscribe()
  }, [subscribeToMore, cud, livedataCL ]);


  useEffect(() => {
    let userUpdateUnsubscribe;

    if(livedataCL){
     userUpdateUnsubscribe = subscribeToMore({
        document: USER_UPDATED,
        variables:{companyid: getLoggedInUser().company},
        updateQuery: (prev, { subscriptionData }) => {
          const newuser = subscriptionData.data.userUpdated;
          let MainList = [...prev.getAllUserCompany];
          const index = MainList.map(user => user.userid).indexOf(newuser.userid);
    
          MainList[index] = newuser;
          return {
            getAllUserCompany: MainList
          }
        }
      }); 
    }

    if (userUpdateUnsubscribe) return () => userUpdateUnsubscribe()
  }, [subscribeToMore, cud, livedataCL]);

      return (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Select Your View</h4>
                    <Button onClick={()=>setView(1)} color="secondary">Add User</Button>
                    <Button onClick={()=>setView(2)} color="secondary">Users</Button>
                    <Button onClick={()=>setView(3)} color="secondary">All</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {view === 3 ?
              <div>
                <Row>
                  <Col lg="6" md="12" sm = "12" >
                    <AddCompanyUser />
                  </Col>
                  <Col lg="6" md="12" sm = "12" >
                    <CompanyUserList setLiveData={toggleLiveDataCL} liveDataStatus={livedataCL} userlist = {userlist} />
                  </Col>
                </Row>
              </div> : 
              <Row>
                <Col md="12">
                  {
                    view === 1 ? <AddCompanyUser />:
                    view === 2 ?  <CompanyUserList setLiveData={toggleLiveDataCL} liveDataStatus={livedataCL} userlist = {userlist} /> : ''
                  }
                </Col>
              </Row>
            }
          </div>
      );
    }

export default CompanyUsers;
