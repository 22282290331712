import { useMutation } from "@apollo/client";
import { UPDATE_SETTINGS } from "graphql/mutations/customers";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import { PopupError } from "utils/functions";
import { getLoggedInUser } from "utils/useToken";

function Settings(props){
  const alert = useAlert();
  let history = useHistory();

  const [ItemFields, setFieldOrder] = useState(
    props.UserSettings.auctionadmin.ItemFields
  );

  //Not currently used
  const [fields, setFields] = useState({
    "name": true,
    "seller": true,
    "quantity": true,
    "buyer": true,
    "price": true,
    "type": true,
    "description": true
  });

  const LocOnChange = (value, loc) =>{
    //If the value is already assigned, unassign it
    let ChangeIndex = undefined;
    if(fields[value] === true){
      Object.keys(ItemFields).map((e, index) => {
        if(ItemFields[e.toString()] === value) {
          ChangeIndex = e;
        }
      })

      if(ChangeIndex !== undefined){
        setFieldOrder(prevState => {
          return { ...prevState, [ChangeIndex.toString()]: ItemFields[loc]}
        })

        setFieldOrder(prevState => {
          return { ...prevState, [loc.toString()]: value}
        })
      }
    }
  }

  const [result, setResult] = useState([]);

  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });

  const UpdateSettings = () => {
    const updatedSettings = {
      auctionadmin: { ItemFields }
    }
    updateSettings({ variables: { thedata: JSON.stringify(updatedSettings), userid: getLoggedInUser().userid } })
      .then((data2) => {
          setResult( () => ["Customer Updated Successfully"])
        }
      )
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if(result){
      if(result.length > 0){
        result.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [result, alert]);

  const ShowOption = (loc) => {
    return (
      <FormGroup>
        <label>Location Area {loc} </label>
        <select 
          name="loc1"
          id="loc1"
          className="optionSelect form-control"
          value={ItemFields[loc]}
          onChange={(e) => LocOnChange(e.target.value, loc)}
        >
          {/* <option value="">Not Set</option> */}
          <option value="name">Name</option>
          <option value="seller">Seller</option>
          <option value="quantity">Quantity</option>
          <option value="buyer">Buyer</option>
          <option value="price">Price</option>
          <option value="type">Type</option>
          <option value="description">Description</option>
        </select>
      </FormGroup>
    )
  }

  return (
    <Card className='printhide' >
    <CardHeader>
      <CardTitle tag="h4">
        Add New Item Settings
        <br />
      </CardTitle>
    </CardHeader>
      <CardBody>
          <Form>
            <Row>
              <Col className="pr-md-1" md="4">
                { ShowOption(1) }
              </Col>
              <Col className="pr-md-1" md="4">
                { ShowOption(2) }
              </Col>
              <Col className="pr-md-1" md="4">
                { ShowOption(3) }
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="4">
                { ShowOption(4) }
              </Col>
              <Col className="pr-md-1" md="4">
                { ShowOption(5) }
              </Col>
              <Col className="pr-md-1" md="4">
                { ShowOption(6) }
              </Col>
            </Row>
            <Row>
              <Col className="pr-md-1" md="12">
                { ShowOption(7) }
              </Col>
            </Row>
          </Form>
      </CardBody>
      <CardFooter>
        <Button 
        className="btn-fill" 
        color="info" 
        type="button" 
        onClick={e => {
          e.preventDefault();
          UpdateSettings();
        }}
        >
          Update Settings
        </Button>

      </CardFooter>
    </Card>
  )
}
export default Settings;