import { getLoggedInUser } from "utils/useToken";
import { GET_PAYMENT_METHODS } from "graphql/queries/paymentmethods";
import { GET_REPORT_PAYMENTS } from "graphql/queries/payment";
import { useEffect, useState } from "react";
import { formatNumber } from "utils/functions";
import { useLazyQuery, useQuery } from "@apollo/client";
import XTable from "components/custom/table";

const {  CardBody, Card, Col, Row, FormGroup } = require("reactstrap");

function PaymentReport(){
  const [getPaymentReport, { data, error, loading, onError}] = useLazyQuery(GET_REPORT_PAYMENTS, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      console.log("ERROR", err.message);
    },
  });

  const { data: pmd, loading: pml, error: pmerror, onError:  pmonerror } = useQuery(GET_PAYMENT_METHODS,{
    variables: {
      companyid: getLoggedInUser().company,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [method, setMethod] = useState();
  const [reportdata, setReportData] = useState();
  const [pmlist, setPmList] = useState([]);

  const GetReportdata =  () =>{
    getPaymentReport({ 
      variables: {auctionid: getLoggedInUser().auctionid,  methodid: method},
    })
  }

  const listHeader = [
    {
      Header: "Name",
      className: "t-cell-1 text-centered mwTable-15",
      accessor: "name",
      Cell: (propsa) => (
        propsa.value ? <span>{propsa.value}</span> : ''
      )
    },
    {
      Header: "Address",
      accessor: "address",
      className: "t-cell-4 text-center ",
      Cell: (propsa) => (
        <span>{propsa.value}</span>
      )
    },
    {
      Header: "Amount",
      accessor: "amount",
      className: "t-cell-2 text-centered",
      Cell: (propsa) => (
        <span>{propsa.value ? formatNumber(propsa.value) : ""}</span>
      )
    },
    {
      Header: "Fees",
      accessor: "feeamount",
      className: "t-cell-3 text-centered mwTable-15",
      Cell: (propsa) => (
        <span>{propsa.value ? formatNumber(propsa.value) : ""}</span>
      )
    },
    {
      Header: "Type",
      accessor: "transactiontype",
      className: "t-cell-4 text-center mwTable-15",
      Cell: (propsa) => (
        <span>{propsa.value}</span>
      )
    },
    {
      Header: "Ref#",
      accessor: "refnumber",
      className: "t-cell-4 text-center mwTable-15",
      Cell: (propsa) => (
        <span>{propsa.value}</span>
      )
    },
  ];

  useEffect(() => {
    if(method){
      GetReportdata();
    }
  }, [method]);

  useEffect(() => {
    const onCompleted = (pmd) => { 
      if(pmd){
        setPmList( () => pmd.getAllPaymentMethods);
      }
    }
    if (onCompleted || pmonerror) {
      if (onCompleted && !pml && !pmerror) {
        onCompleted(pmd);
      } else if (pmonerror && !pml && pmerror) {
        pmonerror(pmerror);
      }
    }
  }, [pml, pmd, pmerror, pmonerror]);

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setReportData(() => data.getPaymentReport);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);

  return (
    <div className="content">
      <Card>
        <CardBody>
          <Row >
            <Col className="pr-md-1" md="4">
              <FormGroup>
                <label>Payment Method: </label>
                <select className='form-control'
                  name="methodid"
                  value={method}
                  onChange={ e => { setMethod(e.target.value) } }
                >
                  <option value={0}>Select a Method</option>
                  {pmlist && pmlist.length > 0 &&
                      pmlist.map((type) => {
                      return(
                        <option
                        key={type.paymentmethodid} value={type.paymentmethodid}>{type.name + ' - ' + type.rate.toFixed(2) +'%'}</option>
                      )
                    })
                  }
                </select>
              </FormGroup>
            </Col>
          </Row>
            {reportdata &&
              <XTable  defaultPageSize={9999} columns={listHeader} loading={false} data={reportdata} filterbutton={false} />
            }
        </CardBody>
      </Card>
    </div>
  )
}

export default PaymentReport;