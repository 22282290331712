import { gql } from '@apollo/client';

export const CREATE_TYPE = gql`
mutation createType($thedata : CreateTypeInput!) {
  createType(data: $thedata) {
    typeid   
    name
    description
    active
    target
    lastmodifiedbyid
    lastupdated
  }
}
`;

export const UPDATE_TYPE = gql`
mutation updateType($thedata : UpdateTypeInput!) {
  updateType(data: $thedata) {
    typeid
  }
}
`;
