import { gql } from '@apollo/client';

export const UPDATED_STATS = gql`
subscription updatestats($auctionid: String!, $biddernumber: Float) {
  updatestats(auctionid: $auctionid, biddernumber: $biddernumber) 
}
`;

export const UPDATED_AUCTIONSTATS = gql`
subscription updateauctionstats($auctionid: String!) {
  updateauctionstats(auctionid: $auctionid) 
}
`;