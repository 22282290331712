import { gql } from '@apollo/client';

export const TYPE_ADDED = gql`
subscription TypeCreated($companyid: String!) {
  typeCreated(companyid: $companyid) {
    typeid   
    name
    description
    active
    target
    lastmodifiedbyid
    lastupdated
  }
}
`;


export const TYPE_UPDATED = gql`
subscription TypeUpdated($companyid: String!) {
  typeUpdated(companyid: $companyid){
    typeid   
    name
    description
    active
    target
    lastmodifiedbyid
    lastupdated
  } 
}
`;
