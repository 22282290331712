import { gql } from '@apollo/client';

export const PAYMENTMETHOD_ADDED = gql`
subscription PaymentMethodCreated($companyid: String!) {
  paymentMethodCreated(companyid: $companyid) {
    name
    url
    username
    password
    rate
    lastmodifiedbyid
    paymentmethodid
    lastupdated
    active
    type
  }
}
`;

export const PAYMENTMETHOD_UPDATED = gql`
subscription PaymentMethodUpdated($companyid: String!) {
  paymentMethodUpdated(companyid: $companyid){
    name
    url
    username
    password
    rate
    lastmodifiedbyid
    paymentmethodid
    lastupdated
    active
    type
  } 
}
`;
