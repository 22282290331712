import { useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { getLoggedInUser } from "utils/useToken";
import {stateArray, countryArray} from "utils/arrays";
import {DefaultUserPassword} from "utils/defaults";
import { handleInputFocus } from "utils/functions";
import { CREATE_USER } from "../../graphql/mutations/customers";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  
} from "reactstrap";

import { useAlert } from "react-alert";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";

function AddCompanyUser(props){
  const alert = useAlert();
  const inputRef = useRef();

  const [result, setResult] = useState([]);

  const [createUser] = useMutation(CREATE_USER, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });

  const ResetInput = () => {
    setUserInfo({
      userid: '',
      firstname: '',
      lastname: '',
      emailaddress: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: 0,
      country: 'US',
      phone: '',
      biddernumber: 0,
      emailoptin: false,
      types:[],
      auctionadmin:false,
      notes:'',
      newcustomer:true,
      username: '',
      password: DefaultUserPassword,
      lastmodifiedbyid: getLoggedInUser().userid,
      auctionid: "0",
      companyid: getLoggedInUser().company,
      lastmodifiedbyuser: getLoggedInUser().name,
      auctioncustomer: false,
    })
  }

  const UpdateUser = () => {
      setResult([]);
      createUser({ variables: { thedata: edituserdata } })
      .then((data2) => {
          if(!data2.errors){
            setResult( () => [edituserdata.firstname + " " + edituserdata.lastname + " - Customer Created Successfully"])
            setTimeout( () => ResetInput(), 500);
            inputRef.current.focus();
          }
        }
      )
      .catch((err) => console.log(err));

  }

  const [edituserdata, setUserInfo] = useState({
    userid: '',
    firstname: '',
    lastname: '',
    emailaddress: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: 0,
    country: 'US',
    phone: '',
    biddernumber: 0,
    emailoptin: false,
    types:[],
    auctionadmin:false,
    notes:'',
    newcustomer:true,
    username: '',
    password: DefaultUserPassword,
    lastmodifiedbyid: getLoggedInUser().userid,
    auctionid: "0",
    companyid: getLoggedInUser().company,
    lastmodifiedbyuser: getLoggedInUser().name,
    auctioncustomer: false,
  });

  useEffect(() => {
    if(edituserdata.newcustomer){
      const RandomNumber =  Math.floor(1000 + Math.random() * 9000);
      let FirstCharacter;

      if(edituserdata.firstname !== '')
        FirstCharacter = (edituserdata.firstname).charAt(0);

      if(edituserdata.firstname !== '' && edituserdata.lastname !== ''){
        const NewUsername = FirstCharacter + edituserdata.lastname + RandomNumber
        setUserInfo({...edituserdata, username: NewUsername});
      }
    }
  }, [edituserdata.firstname, edituserdata.lastname]);

  const UpdateCustomerOnChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseInt(value);
      }

      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }

      setUserInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setUserInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

  // useEffect(() => {
  //   inputRef.current.focus();
  // })

    useEffect(() => {
    if(result){
      if(result.length > 0){
        result.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [result, alert]);

  // const onEnter = (event, go) => {
  //   console.log("HIT ENTER")
  //   event.key === 'Enter' && go()
  // } 

  return (
      <Card>
          <CardHeader>
            <CardTitle tag="h4">Customer Information</CardTitle>
            <CardTitle tag="h4">Username: {edituserdata.username} <span className='mw-Right'> Default Password: {DefaultUserPassword}</span></CardTitle>
          </CardHeader>
        <CardBody>
          <KeyboardEventHandler
            handleKeys={['enter']}
            onKeyEvent={() => UpdateUser() }>
          <Form>
            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* First Name:</label>
                  <input
                    className="form-control"
                    placeholder="First Name"
                    type="text"
                    name="firstname"
                    value={edituserdata.firstname}
                    autoComplete = "off"
                    ref={inputRef}
                    autoFocus
                    onChange={ e => UpdateCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                  <label >
                    * Last Name:
                  </label>
                  <Input
                    placeholder="Last Name" 
                    type="text" 
                    name="lastname"
                    value={edituserdata.lastname}
                    autoComplete = "off"
                    onChange={ e => UpdateCustomerOnChange(e) }
                    />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label>Email:</label>
                  <Input
                    value={edituserdata.emailaddress}
                    placeholder="Email Address"
                    type="text"
                    name="emailaddress"
                    autoComplete = "off"
                    onChange={ e => UpdateCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* Address:</label>
                  <Input
                    value={edituserdata.address1}
                    placeholder="Address"
                    type="text"
                    name="address1"
                    autoComplete = "off"
                    onChange={ e => UpdateCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                  <label>* City:</label>
                  <Input
                    value={edituserdata.city}
                    placeholder="City"
                    type="text"
                    name="city"
                    autoComplete = "off"
                    onChange={ e => UpdateCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label>* State:</label>
                  <select className='form-control'
                    name="state"
                    value={edituserdata.state}
                    onChange={ e => UpdateCustomerOnChange(e) }
                  >
                    <option value="">Select State</option>
                    {stateArray.map((value, key) => {
                      return (
                        <option value={value.abbreviation} key={key}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* Zip Code:</label>
                  <Input
                    placeholder="12345"
                    type="number"
                    name="zip"
                    value={edituserdata.zip}
                    autoComplete = "off"
                    onFocus={handleInputFocus}
                    onChange={ e => UpdateCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                  <label >
                    Address 2:
                  </label>
                  <Input
                    placeholder="Address 2" 
                    type="text" 
                    name="address2"
                    value={edituserdata.address2}
                    autoComplete = "off"
                    onChange={ e => UpdateCustomerOnChange(e) }
                    />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label>* Country:</label>
                  <select className='form-control'
                    name="country"
                    onChange={ e => UpdateCustomerOnChange(e) }
                  >
                    {countryArray.map((value, key) => {
                      return (
                        <option value={value.code} key={key}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Phone:</label>
                  <Input
                    placeholder="Phone"
                    type="text"
                    name="phone"
                    value={edituserdata.phone}
                    autoComplete = "off"
                    onChange={ e => UpdateCustomerOnChange(e) }
                  />
                </FormGroup>
              </Col>
      
              <Col  md="4">
                <FormGroup>
                  <label >
                    Email Opt In:
                  </label>
                  <select className='form-control'
                    name="emailoptin"
                    value={edituserdata.emailoptin}
                    onChange={ e => UpdateCustomerOnChange(e, 'boolean') }
                  >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>

          </Form>
          </KeyboardEventHandler>
        </CardBody>
        <CardFooter>
          <Button 
            className="btn-fill" 
            color="info" 
            type="button" 
            // onKeyDown={e => onEnter(e, UpdateUser())}
            // tabIndex="0"
            // onKeyDown={e => console.log("I PRESS KEY")}
            onClick={e => {
              e.preventDefault();
              UpdateUser();
            }}
            >
              Add User
            </Button>
        </CardFooter>
      </Card>
  )
}

export default AddCompanyUser;