import Stats from "components/custom/Stats";
import { getLoggedInUser } from "utils/useToken";
import { ItemList } from "./auction/itemlist";
import { PaymentList } from "./PaymentList";

const {Col, Row } = require("reactstrap");

function Dashboard(){
  let getByBidderNumber = -1; 
  const a = getLoggedInUser().auctionlist.find(i => i.auctionid  === getLoggedInUser().auctionid)
  if(a)  getByBidderNumber = a.biddernumber



  return (
    <div className="content">
    <Row>
        <Col lg="6" md="12" sm = "12" >
          <Stats biddernumber={getByBidderNumber}  page={"dashboard"} />
          <PaymentList editmode = {false} biddernumber = {getByBidderNumber}  /> 
        </Col>
        <Col lg="6" md="12" sm = "12" >
          <ItemList editmode={false} title = {"Auction Item List"} biddernumber={getByBidderNumber} auctionAdmin = {false} />
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard;