import { gql } from '@apollo/client';

export const GET_COMPANY_INFO = gql`
query getCompanyById($companyid : String!, $requestinguserid : String!) {
  getCompanyById(companyid: $companyid, requestinguserid: $requestinguserid) {
   name
   registrationid
   datetimecreated
   email
   datetimeexpire
   lastupdated
   address
   address2
   city
   state
   zip
   phone
   notes
   contactname
   companyid
   lastmodifiedbyid
   adminid
   active
   adminname
   users{
    userid
    name
   }
  }
}
`;