import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { formatDate } from "utils/date";
import { getLoggedInUser } from "utils/useToken";
import { useAlert } from "react-alert";

import { useMutation, useQuery } from "@apollo/client";
import { GET_COMPANY_INFO } from "graphql/queries/company";
import React, { useState, useEffect } from "react";
import { UPDATE_COMPANY } from "graphql/mutations/company";

import {stateArray} from "utils/arrays";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { format, parseISO } from "date-fns";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";

function CompanyInfo(){
  const alert = useAlert();

  const { data: companyd, loading: companyl, error: companyerror, onError:  companyonerror } = useQuery(GET_COMPANY_INFO,{
    variables: {
      companyid: getLoggedInUser().company,
      requestinguserid: getLoggedInUser().userid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [companyinfo, setCompanyInfo] = useState({});
  const [companyInfoError, setCompanyInfoError] = useState([]);

  const [updateCompanyInfo] = useMutation(UPDATE_COMPANY, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });

  const disableCompany = () =>{
    tryUpdate("disable");
  }

  const enableCompany = () =>{
    tryUpdate("enable");
  }

  const tryUpdate = (disable = "") =>{
    let edt = '';
    if(companyinfo.datetimeexpire !== "") edt = parseISO(companyinfo.datetimeexpire);

    let active = companyinfo.active;
    if(disable === "disable") active = false;
    else if (disable === "enable") active = true;

    setCompanyInfoError([]);
    updateCompanyInfo({
      variables: {thedata: {
        address: companyinfo.address,
        address2: companyinfo.address2,
        city: companyinfo.city,
        companyid: companyinfo.companyid,
        contactname: companyinfo.contactname,
        email: companyinfo.email,
        name: companyinfo.name,
        lastmodifiedbyid: getLoggedInUser().userid,
        notes: companyinfo.notes,
        phone: companyinfo.phone,
        registrationid: companyinfo.registrationid,
        state:  companyinfo.state,
        zip: companyinfo.zip,
        datetimeexpire: edt,
        active: active,
        adminid: companyinfo.adminid
      }}
    }).then( (res) =>{
      setCompanyInfo(prevState => {
        return { ...prevState,active: active }
      })
      if(!res.errors){
        setCompanyInfoError(() => ["Company Info Updated Successfully"]);
      }
    });
  }

  useEffect(() => {
    if(companyInfoError){
      if(companyInfoError.length > 0){
        companyInfoError.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [companyInfoError, alert]);
  
  useEffect(() => {
    const onCompleted = (companyd) => { 
      if(companyd){
        setCompanyInfo( () => companyd.getCompanyById);
      }
    }
    if (onCompleted || companyonerror) {
      if (onCompleted && !companyl && !companyerror) {
        onCompleted(companyd);
      } else if (companyonerror && !companyl && companyerror) {
        companyonerror(companyerror);
      }
    }
  }, [companyl, companyd, companyerror, companyonerror]);


  return(
<>


    <Card>
    <CardHeader>
      <CardTitle tag="h4">Company Profile <span className='float-right'>Status: {(companyinfo.active) ? "Active" : "Inactive"}</span></CardTitle>

      <Row style={{width:'100%'}}>
        <Col className="pr-md-1" lg="6" md="12" sm="12">
          <span>Company ID: <br /> {companyinfo.companyid} </span> <br /><br />
          <span>Registration ID: {companyinfo.registrationid} </span> <br />
        </Col>

        <Col className="pr-md-1" lg="6" md="12" sm="12">

          {getLoggedInUser().px3.main === 13 && companyinfo.users ?
            <FormGroup>
              <label>* Admin:</label>
            <select className='form-control'
              name="adminuserid"
              value={companyinfo.adminid}
              onChange={ (e) => {
                setCompanyInfo(prevState => {
                  return { ...prevState, adminid: e.target.value}
                })
              }}
            >
            {companyinfo.users.map((value, key) => {
              return (
                <option value={value.userid} key={key}>
                  {value.name}
                </option>
              );
            })}
              
            </select>
          </FormGroup>
          :
            <span>Admin: {companyinfo.adminname}</span>
          }

          <br />

          <span>Registration Date: {formatDate(companyinfo.datetimecreated)} </span> <br />
          <span>Last Updated: {formatDate(companyinfo.lastupdated)} </span> <br />
          <span>Expiration Date: {formatDate(companyinfo.datetimeexpire)}</span><br />
        </Col>
      </Row>
    </CardHeader>
      <CardBody>
        <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => tryUpdate() }>
          <Form>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>* Company Name:</label>
                  <Input
                    value={companyinfo.name}
                    placeholder="Company Name"
                    type="text"
                    autoFocus
                    autoComplete = "off"
                    onChange={ (e) => {
                      setCompanyInfo(prevState => {
                        return { ...prevState, name: e.target.value}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col  md="6">
                <FormGroup>
                  <label>* Contact Name:</label>
                  <Input
                    value={companyinfo.contactname}
                    placeholder="Contact Name"
                    type="text"
                    autoComplete = "off"
                    onChange={ (e) => {
                      setCompanyInfo(prevState => {
                        return { ...prevState, contactname: e.target.value}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>* Email:</label>
                  <Input
                    value={companyinfo.email}
                    placeholder="Email"
                    type="email"
                    autoComplete = "off"
                    onChange={ (e) => {
                      setCompanyInfo(prevState => {
                        return { ...prevState, email: e.target.value}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col  md="6">
                <FormGroup>
                  <label>* Phone:</label>
                  <Input
                    value={companyinfo.phone}
                    placeholder="123-456-7890"
                    type="tel"
                    autoComplete = "off"
                    onChange={ (e) => {
                      setCompanyInfo(prevState => {
                        return { ...prevState, phone: e.target.value}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>* Address:</label>
                  <Input
                    value={companyinfo.address}
                    placeholder="Address"
                    type="text"
                    autoComplete = "off"
                    onChange={ (e) => {
                      setCompanyInfo(prevState => {
                        return { ...prevState, address: e.target.value}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col  md="6">
                <FormGroup>
                  <label>Address 2</label>
                  <Input
                    value={companyinfo.address2}
                    placeholder="Address 2"
                    type="text"
                    autoComplete = "off"
                    onChange={ (e) => {
                      setCompanyInfo(prevState => {
                        return { ...prevState, address2: e.target.value}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>* City:</label>
                  <Input
                  value={companyinfo.city}
                    placeholder="City"
                    type="text"
                    autoComplete = "off"
                    onChange={ (e) => {
                      setCompanyInfo(prevState => {
                        return { ...prevState, city: e.target.value}
                      })
                    }}
                  />
                </FormGroup>
              </Col>
              <Col  md="4">
                <FormGroup>
                      <label>* State:</label>
                      <select className='form-control'
                        name="state"
                        value={companyinfo.state}
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, state: e.target.value}
                          })
                        }}
                      >
                      {stateArray.map((value, key) => {
                        return (
                          <option value={value.abbreviation} key={key}>
                            {value.name}
                          </option>
                        );
                      })}
                        
                      </select>
                    </FormGroup>

              </Col>
              <Col  md="4">
                <FormGroup>
                  <label>* Zip:</label>
                  <Input
                  value={companyinfo.zip}
                    placeholder="Zip"
                    type="text"
                    autoComplete = "off"
                    onChange={ (e) => {
                      if(e.target.value !== ""){
                        setCompanyInfo(prevState => {
                          return { ...prevState, zip: parseInt(e.target.value)}
                        })
                      }
                      else{
                        setCompanyInfo(prevState => {
                          return { ...prevState, zip: parseInt(0)}
                        })
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            {getLoggedInUser().px3.main === 13 && 
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>Update Expiration Date:</label>
                    <Datetime 
                      value={parseISO(companyinfo.datetimeexpire)}
                      onClose={(e) => {
                        if(e._d !== undefined){
                          setCompanyInfo(prevState => {
                            return { ...prevState, datetimeexpire: format(e._d, "yyyy-MM-dd HH:mm")}
                          })
                        }
                        else{
                          setCompanyInfo(prevState => {
                            return { ...prevState, datetimeexpire: ''}
                          })
                        }
                      }}
                    /> 
                  </FormGroup>
                </Col>
              </Row>
            }
            {getLoggedInUser().px3.main === 13 && 
              <>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Notes  (Shift + Enter for new line):</label>
                      <Input
                      value={companyinfo.notes}
                        cols="80"
                        placeholder="Notes"
                        rows="4"
                        type="textarea"
                        name="description"
                        autoComplete = "off"
                        onChange={ (e) => {
                          setCompanyInfo(prevState => {
                            return { ...prevState, notes: e.target.value}
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            }
          </Form>
        </KeyboardEventHandler>
      </CardBody>
      <CardFooter>
        <Button 
        className="btn-fill" 
        color="info" 
        type="button" 
        onClick ={ tryUpdate }
        >
          Update
        </Button>

        {getLoggedInUser().px3.main === 13 &&
          <Button 
          className="btn-fill float-right" 
          color="danger" 
          type="button" 
          onClick ={ 
            (companyinfo.active) ? disableCompany  : enableCompany
          }
          >
            {(companyinfo.active) ? 'Disable' : 'Enable'}
          </Button>
        }
      </CardFooter>
    </Card>
 
</>
  )
}
export default CompanyInfo;