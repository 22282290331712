
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { GET_ITEM_TYPES } from "../graphql/queries/items";
import { useQuery } from "@apollo/client";
import {ItemList} from './auction/itemlist';
import { getLoggedInUser } from "utils/useToken";
import AddItem from "./auction/additem";
import TypeList from "./company/typesinfo";
import { GET_USER_SETTINGS } from "graphql/queries/users";

function AuctionCustomer(props)  { 
  const { data: types, refetch} = useQuery(GET_ITEM_TYPES,{
    variables: {
      companyid: getLoggedInUser().company,
      target: 1
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });
  
  const { data: ud, loading: ul, error: uerror, onError:  uonerror } = useQuery(GET_USER_SETTINGS,{
    variables: {
      companyid: getLoggedInUser().company,
      userid: getLoggedInUser().userid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [usersettings, setUserSettings] = useState();

  useEffect(() => {
    const onCompleted = (ud) => { 
      if(ud){
        if(ud.getUserCompanyById.settings != null && ud.getUserCompanyById.settings != "")
          setUserSettings( () => JSON.parse(ud.getUserCompanyById.settings))
        else
          setUserSettings( () => JSON.parse('{"auctionadmin": {"ItemFields" :{"1": "name","2": "seller","3": "quantity", "4": "buyer","5": "price","6": "type","7": "description"}}}'))
      }
    }
    if (onCompleted || uonerror) {
      if (onCompleted && !ul && !uerror) {
        onCompleted(ud);
      } else if (uonerror && !ul && uerror) {
        uonerror(uerror);
      }
    }
  }, [ul, ud, uerror, uonerror]); //NOTE: DONT ADD PROPS - Causes Memory Leak

  const [view, setView] = useState("all");
  const [itemUpdated, setFocus] = useState(false);

  const UpdateView = (view) => {
    setView(view);
  }

      return (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Select Your View</h4>
                    <Button onClick={()=>UpdateView("additem")} color="secondary">Add Item</Button>
                    <Button onClick={()=>UpdateView("itemlist")} color="secondary">Items</Button>
                    <Button onClick={()=>UpdateView("all")} color="secondary">All</Button>
                    <Button onClick={()=>UpdateView("types")} color="secondary">Types</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {view === "all" ?
              <div>
                <Row>
                  <Col lg="6" md="12" sm = "12" >
                    {  usersettings ? <AddItem types={types} itemUpdated={itemUpdated} usersettings={usersettings} /> : null }
                  </Col>
                  <Col lg="6" md="12" sm = "12" >
                    <ItemList editmode ={true} title = {"Auction Item List"}  auctionAdmin = {true} itemUpdated={itemUpdated} setFocus={setFocus} />
                  </Col>
                </Row>
              </div> : 
              <Row>
                <Col md="12">
                  {
                    view === "additem" && usersettings ? <AddItem types={types} itemUpdated={itemUpdated} usersettings={usersettings} /> :
                    view === "itemlist" ?  <ItemList editmode={true} title = {"Auction Item List"} auctionAdmin = {true} itemUpdated={itemUpdated} setFocus={setFocus}   /> :
                    view === "types" ? <TypeList AuctionRefetch={refetch} /> 
                    : ''
                  }
                </Col>
              </Row>
            }
          </div>
      );
    }

export default AuctionCustomer;
