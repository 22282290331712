import { useMutation } from "@apollo/client";
import { DEACTIVATE_ALL_USERS } from "graphql/mutations/customers";
import React, {useState} from "react";
import { SAConfirm } from "utils/functions";
import { getLoggedInUser } from "utils/useToken";
import { PaymentList } from "./PaymentList";
import PaymentReport from "./PaymentReport";
import Reports from "./Reports";
import AuctionStats from "./status/auctionstats";

const { CardBody, Card, Col, Row, Button } = require("reactstrap");

function Status(){
  const [deactivateAllUsers] = useMutation(DEACTIVATE_ALL_USERS, {
    onError: (err) =>{
      console.log(err)
    }
  });

  const TryDeactivate = () => {
    SAConfirm("Confirm", "Are you sure you want to deactivate all customers that have no items bought or sold?.", () => {
      deactivateAllUsers({ variables: { auctionid: getLoggedInUser().auctionid} })
    })
  }
  const [view, setView] = useState("stats");
  const UpdateView = (view) => {
    setView(view);
    // ResetFilters();
    // setFilters(prevState => {
    //   return { ...prevState, [view]: true}
    // })
  }
  return (
    <div className="content">
      <Row className="printhide">
        <Col md="12">
          <Card>
            <CardBody>
              <h4>Select Your View</h4>
              <Button onClick={()=>UpdateView("stats")} color="secondary">Stats</Button>
              <Button onClick={()=>UpdateView("payments")} color="secondary">Payments</Button>
              <Button onClick={()=>UpdateView("flags")} color="secondary">Flags</Button>
              <Button onClick={()=>UpdateView("reports")} color="secondary">Reports</Button>
              <Button onClick={()=>TryDeactivate()} color="secondary">Deactivate All No Buy/Sell </Button>
              {view === "stats" &&
                <Button onClick={()=>window.print()} color="secondary">Print Stats</Button>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    
    <Row>
        <Col md="12">
          {
            view === 'stats' ? <AuctionStats /> :
            view === 'payments' ? <PaymentList showall = {true} /> : 
            view === 'flags' ? 'FLAGS' : 
            view === 'reports' ? <Reports /> : 
            ''
          }
        </Col>
      </Row>
    </div>
  )
}

export default Status;