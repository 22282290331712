import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_INFO } from "graphql/queries/users";
import { useEffect, useState } from "react";
import { getLoggedInUser } from "utils/useToken";
import {stateArray, countryArray} from "utils/arrays";
import { handleInputFocus } from "utils/functions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import { GET_TYPES } from "graphql/queries/types";
import { UPDATE_USER } from "graphql/mutations/customers";

import { useAlert } from "react-alert";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useHistory } from "react-router-dom";
import { SellerType } from "utils/defaults";
import { PopupError } from "utils/functions";
import Settings from "./Settings";

function EditProfile(props){
  const alert = useAlert();
  let history = useHistory();

  let RunTypeQuery = true;
  if(props.auctionedit)
    RunTypeQuery = false;

  const { data: td, loading: tl, error: terror, onError:  tonerror } = useQuery(GET_TYPES,{
    variables: {
      companyid: getLoggedInUser().company,
      activefilter: true,
      target: 2
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    skip: RunTypeQuery
  });

  const [customertypelist, setCustomerTypes] = useState([{}]);
  const [result, setResult] = useState([]);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  
  useEffect(() => {
    const onCompleted = (td) => { 
      if(td){
        setCustomerTypes( () => td.getAllTypes);
        setCustomerTypes(customertypes => [...customertypes, SellerType])
      }
    }
    if (onCompleted || tonerror) {
      if (onCompleted && !tl && !terror) {
        onCompleted(td);
      } else if (tonerror && !tl && terror) {
        tonerror(terror);
      }
    }
  }, [tl, td, terror, tonerror]);

  const [checkboxes, setCheckboxes] = useState([])

  useEffect(() => {
    if(customertypelist.length > 0) {
      if(props.auctionedit){
        const currentauction = props.auctionmodaldata.auctionlist.find((e)=>e.auctionid === getLoggedInUser().auctionid)
      
        let auctionAdmin = false;
        if(currentauction.types.indexOf("auctionadmin") > -1) auctionAdmin = true


        setCheckboxes(checkboxes => [...checkboxes, {typeid:"auctionadmin", value:auctionAdmin}]);

        customertypelist.map(obj => {
        if(obj.typeid !== undefined){
          let setValue = false;
          if(currentauction.types.indexOf(obj.typeid) > -1) setValue = true;
           return setCheckboxes(checkboxes => [...checkboxes, {typeid:obj.typeid, value:setValue}])
        }
        else return false
      });

      const userstuff = Object.assign({}, props.auctionmodaldata, {types:currentauction.types.filter(a => a !== "auctionadmin"), auctioncustomeractive: currentauction.active, auctionadmin: auctionAdmin, password1: '', password2: '', newcustomer: false, auctioncustomer: true})
      setUserInfo( () => userstuff)
      }
    }
}, [customertypelist, props.auctionedit, props.auctionmodaldata]);


  const UpdateUser = () => {
    const updateData = {
      address: edituserdata.address,
      address2: edituserdata.address2,
      auctionadmin: edituserdata.auctionadmin,
      auctioncustomer: edituserdata.auctioncustomer,
      newcustomer: edituserdata.newcustomer,
      firstname: edituserdata.firstname,
      lastname: edituserdata.lastname,
      email: edituserdata.email,
      city: edituserdata.city,
      state: edituserdata.state,
      country: edituserdata.country,
      zip: edituserdata.zip,
      auctioncustomerid: edituserdata.auctioncustomerid,
      biddernumber: edituserdata.biddernumber,
      phone: edituserdata.phone,
      emailoptin: edituserdata.emailoptin,
      notes: edituserdata.notes,
      password1: edituserdata.password1,
      password2: edituserdata.password2,
      // securitylevel: edituserdata.securitylevel,
      // active: edituserdata.active,
      userid: edituserdata.userid,
  
      lastmodifiedbyid: getLoggedInUser().userid,
      // lastloginip:
      companyid: getLoggedInUser().company,
      types: edituserdata.types,
      auctioncustomeractive: edituserdata.auctioncustomeractive,
      ucactive: edituserdata.ucactive
    }

      setResult([]);
      updateUser({ variables: { thedata: updateData } })
      .then((data2) => {
          if(!data2.errors){
            setResult( () => [edituserdata.firstname + " " + edituserdata.lastname + " - Customer Updated Successfully"])
            if(props.prevPage)
              history.push({pathname: "/auction/" + props.prevPage})
          }
        }
      )
      .catch((err) => console.log(err));

  }

  const { data: ud, loading: ul, error: uerror, onError:  uonerror } = useQuery(GET_USER_INFO,{
    variables: {
      companyid: getLoggedInUser().company,
      userid: props.userid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
    skip: !RunTypeQuery
  });

  const [edituserdata, setUserInfo] = useState();

  const UpdateCustomerOnChange = (e, type = '') => {
    if(type !== "array"){
      let value = e.target.value;
      if(e.target.type === 'number'){
        if(value === '') value = 0;
        else value = parseInt(value);
      }

      if(type === 'boolean') {
        if(typeof(value) === "string"){
          const a = (value === 'true')
          value = a;
        }
      }

      setUserInfo(prevState => {
        return { ...prevState, [e.target.name]: value}
      })
    }
    else{
      setUserInfo(prevState => {
        return { ...prevState, [e.name]:e.value}
      })   
    }
  }

    useEffect(() => {
    if(result){
      if(result.length > 0){
        result.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [result, alert]);

  useEffect(() => {
    const onCompleted = (ud) => { 
      if(ud){
        if(!props.auctionedit){
          const currentauction = ud.getUserCompanyById.auctionlist.find((e)=>e.auctioncustomerid === ud.getUserCompanyById.id)
          console.log("Current Auction", currentauction)
          var userstuff1 = Object.assign({}, ud.getUserCompanyById, {password1: '', password2: '', newcustomer: false, auctioncustomer: false, auctioncustomeractive: currentauction.active})
          if(userstuff1.settings !== "" && userstuff1.settings)
            userstuff1.settings = JSON.parse(userstuff1.settings);
          else 
            userstuff1.settings = JSON.parse('{"auctionadmin": {"ItemFields" :{"1": "name","2": "seller","3": "quantity", "4": "buyer","5": "price","6": "type","7": "description"}}}');

          setUserInfo( () => userstuff1)

          if(props.setPrintReady && props.printReady){
            if(!props.printReady.profile){
              setTimeout( () => 
                props.setPrintReady(prevState => {
                  return { ...prevState, profile: true}
                })  
              , 500);
            }
          }
        }
      }
    }
    if (onCompleted || uonerror) {
      if (onCompleted && !ul && !uerror) {
        onCompleted(ud);
      } else if (uonerror && !ul && uerror) {
        uonerror(uerror);
      }
    }
  }, [ul, ud, uerror, uonerror]); //NOTE: DONT ADD PROPS - Causes Memory Leak

  return (
    <>
    {edituserdata &&
      <Card>
          <CardHeader>
            <CardTitle tag="h4">Customer Information</CardTitle>
            <span style={{float:"left"}}>Username: {edituserdata.username}</span>
            <span className='printhide' style={{float:"right", fontSize:"26px"}}>{edituserdata.auctioncustomeractive ? <span style={{color:"lime"}}>Active</span> : <span style={{color:"red"}}>Deactivated </span> }</span>
          </CardHeader>
        <CardBody>
          <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => UpdateUser() }>
            <Form>
              <Row>
                <Col className="pr-md-1 col-print-4" md="4">
                  <FormGroup>
                    <label>* First Name:</label>
                    <Input
                      placeholder="First Name"
                      type="text"
                      name="firstname"
                      value={edituserdata.firstname}
                      autoComplete = "off"
                      autoFocus
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label >
                      * Last Name:
                    </label>
                    <Input
                      placeholder="Last Name" 
                      type="text" 
                      name="lastname"
                      value={edituserdata.lastname}
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                      />
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label>Email:</label>
                    <Input
                      value={(edituserdata.email ? edituserdata.email : '')}
                      placeholder="Email Address"
                      type="text"
                      name="email"
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="pr-md-1 col-print-4" md="4">
                  <FormGroup>
                    <label>* Address:</label>
                    <Input
                      value={edituserdata.address}
                      placeholder="Address"
                      type="text"
                      name="address"
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label>* City:</label>
                    <Input
                      value={edituserdata.city}
                      placeholder="City"
                      type="text"
                      name="city"
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label>* State:</label>
                    <select className='form-control'
                      name="state"
                      value={edituserdata.state}
                      onChange={ e => UpdateCustomerOnChange(e) }
                    >
                      <option value="">Select State</option>
                      {stateArray.map((value, key) => {
                        return (
                          <option value={value.abbreviation} key={key}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="pr-md-1 col-print-4" md="4">
                  <FormGroup>
                    <label>* Zip Code:</label>
                    <Input
                      placeholder="12345"
                      type="number"
                      name="zip"
                      value={edituserdata.zip}
                      autoComplete = "off"
                      onFocus={handleInputFocus}
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label >
                      Address 2:
                    </label>
                    <Input
                      placeholder="Address 2" 
                      type="text" 
                      name="address2"
                      value={edituserdata.address2}
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                      />
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label>* Country:</label>
                    <select className='form-control'
                      name="country"
                      onChange={ e => UpdateCustomerOnChange(e) }
                    >
                      {countryArray.map((value, key) => {
                        return (
                          <option value={value.code} key={key}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="pr-md-1 col-print-4" md="4">
                  <FormGroup>
                    <label>Phone:</label>
                    <Input
                      placeholder="Phone"
                      type="text"
                      name="phone"
                      value={edituserdata.phone}
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
                {props.auctionedit &&
                  <Col className="pr-md-1 col-print-4" md="4">
                    <FormGroup>
                      <label>Bidder Number:</label>
                      <Input
                        placeholder="0"
                        type="number"
                        name="biddernumber"
                        value={edituserdata.biddernumber}
                        autoComplete = "off"
                        onFocus={handleInputFocus}
                        onChange={ e => UpdateCustomerOnChange(e) }
                      />
                    </FormGroup>
                  </Col>
                }
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label >
                      Email Opt In:
                    </label>
                    <select className='form-control'
                      name="emailoptin"
                      value={edituserdata.emailoptin}
                      onChange={ e => UpdateCustomerOnChange(e, 'boolean') }
                    >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="pr-md-1 col-print-4" md="4">
                  <FormGroup  className="printhide">
                    <label>New Password:</label>
                    <Input
                      type="password"
                      name="password1"
                      value={edituserdata.password1}
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup  className="printhide">
                  <label>Confirm Password:</label>
                    <Input
                      type="password"
                      name="password2"
                      value={edituserdata.password2}
                      autoComplete = "off"
                      onChange={ e => UpdateCustomerOnChange(e) }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {props.auctionedit &&
              <>
              <Row>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label> User Company Status: </label>
                    <select className='form-control'
                      name="ucactive"
                      value={edituserdata.ucactive}
                      onChange={ e => UpdateCustomerOnChange(e, 'boolean') }
                    >
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col className = "col-print-4" md="4">
                  <FormGroup>
                    <label> User Auction Status: </label>
                    <select className='form-control'
                      name="auctioncustomeractive"
                      value={edituserdata.auctioncustomeractive}
                      onChange={ e => UpdateCustomerOnChange(e, 'boolean') }
                    >
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
                {customertypelist.length > 0 && checkboxes.length > 0 &&
                  <Row>
                    <Col className = "pr-md-1" md="12">
                    <label>Customer Type:</label>
                      <FormGroup check>
                      {getLoggedInUser().px3.company &&
                          <Label check>
                            <Input 
                              type="checkbox" 
                              className = 'px3Checkbox' 
                              value = {1}
                              checked={  checkboxes.find((e)=>e.typeid === "auctionadmin").value  }
                              onChange = {() => {
                                let newValue = false;
                                if(!edituserdata.auctionadmin)
                                  newValue = true;
                              
                                let orgCheckboxArray = checkboxes;
                                let newCheckboxArray = orgCheckboxArray;

                                newCheckboxArray = orgCheckboxArray.filter(type => type.typeid !== 'auctionadmin');
                                newCheckboxArray.push({typeid: 'auctionadmin', value: newValue})

                                UpdateCustomerOnChange({name: "auctionadmin", value: newValue}, 'array')

                                setCheckboxes(newCheckboxArray);
                              }}
                            /> {' '}
                            Auction Admin
                          </Label>
                      }
                        {customertypelist.length > 0 && checkboxes.length > 1 && customertypelist.map((value, key) => {
                          return (
                              <Label check className="typeCheckbox" key = {key}>
                                <Input 
                                  type="checkbox" 
                                  className = 'px3Checkbox' 
                                  checked={  checkboxes.find((e)=>e.typeid === value.typeid).value  }
                                  value={value.typeid} 
                                  onChange={(e) => {
                                    let origArray = edituserdata.types;
                                    let newTypeArray = origArray;

                                    let orgCheckboxArray = checkboxes;
                                    let newCheckboxArray = orgCheckboxArray;
                                    newCheckboxArray = orgCheckboxArray.filter((ee)=>ee.typeid !== e.target.value);

                                    //Only 
                                    if(edituserdata.types.indexOf(e.target.value) > -1){
                                        newTypeArray = origArray.filter(type => type !== e.target.value);
                                        newCheckboxArray.push({typeid: e.target.value, value: false})
                                    }
                                    else {
                                      newTypeArray.push(e.target.value)
                                      newCheckboxArray.push({typeid: e.target.value, value: true})
                                    }
                                    
                                    UpdateCustomerOnChange({name: "types", value:newTypeArray}, 'array');
                            
                                    setCheckboxes(newCheckboxArray);
                                  }}
                                /> {' '}
                                {value.name}
                              </Label>
                          );
                        })}
                      </FormGroup>
                    </Col>
                  </Row>              
                }

                <Row><Col md="12">&nbsp;</Col></Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>Notes:</label>
                      <Input
                        cols="80"
                        value={edituserdata.notes}
                        placeholder="Here can be your notes"
                        rows="4"
                        type="textarea"
                        name="notes"
                        autoComplete = "off"
                        onChange={ e => UpdateCustomerOnChange(e) }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
              }
            </Form>
          </KeyboardEventHandler>
        </CardBody>
        <CardFooter>
          <Button 
            className="btn-fill" 
            color="info" 
            type="button" 
            onClick={e => {
              e.preventDefault();
              UpdateUser();
            }}
            >
              Update User
            </Button>
        </CardFooter>
      </Card>
    }

    { !props.auctionedit && (getLoggedInUser().px3.auction || getLoggedInUser().px3.company) && edituserdata &&  <Settings UserSettings = {edituserdata.settings} /> }
  </>
  )
}

export default EditProfile;