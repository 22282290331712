import { getLoggedInUser } from "utils/useToken";
import { GET_PAYMENT_METHODS } from "graphql/queries/paymentmethods";
import { GET_REPORT_PAYMENTS } from "graphql/queries/payment";
import { useEffect, useState } from "react";
import { formatNumber } from "utils/functions";
import { useLazyQuery, useQuery } from "@apollo/client";
import XTable from "components/custom/table";
import PaymentReport from "./PaymentReport";
import AuctionStats from "./status/auctionstats";
import UnsoldItemsReport from "./UnsoldItemsReport";
import AttendanceReport from "./AttendanceReport";

const {  CardBody, Card, Col, Row, FormGroup, Button } = require("reactstrap");

function Reports(){
  const [activeReport, setActiveReport] = useState(0);

  return (
    <div className="content">
      <Card className="printhide">
        <CardBody>
          <Row>
            <Col className="pr-md-1" md="4">
              <FormGroup>
                <label>Select a Report: </label>
                <select className='form-control'
                  name="activeReport"
                  value={activeReport}
                  onChange={ e => { setActiveReport(parseInt(e.target.value)) } }
                >
                  <option value={0}>Select a Report</option>
                  <option value={1}>Payment Report</option>
                  <option value={2}>Auction Stats</option>
                  <option value={3}>Unsold Items</option>
                  <option value={4}>Attendance</option>
                
                </select>
              </FormGroup>
            </Col>

            <Col className="pre-md-1" md="4">
              {activeReport > 0 && <Button onClick={()=>window.print()} style={{marginTop:"20px"}} color="secondary">Print Report</Button> }
            </Col>
          </Row>
          
        </CardBody>
      </Card>
      {activeReport > 0 &&
        activeReport === 1 ? <PaymentReport /> :
        activeReport === 2 ? <AuctionStats /> :
        activeReport === 3 ? <UnsoldItemsReport /> :
        activeReport === 4 ? <AttendanceReport /> :
        ""
      }
    </div>
  )
}

export default Reports;