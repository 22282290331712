import { gql } from '@apollo/client';

export const GET_ALL_ITEMS = gql`
query getAllItems($auctionid : String!, $biddernumber: Float, $limit: Float) {
  getAllItems(auctionid: $auctionid, biddernumber: $biddernumber, limit: $limit) {
    sellerbiddernumber 
    buyerbiddernumber 
    price
    description
    name
    qty
    lastmodifiedbyid
    auctionid
    itemid
    datetime
    lastupdated
    sellername
    buyername
    typeid
		lastmodifiedbyuser
  }
}
`;

export const GET_ITEM = gql`
query getItemById($thedata : String!) {
  getItemById(itemid: $thedata) {
    itemid
    auctionid
    sellerbiddernumber
    buyerbiddernumber
    price
    description
    name
    qty
    lastmodifiedbyid
    sellername
    buyername
    typeid
  }
}
`;

export const GET_UNSOLD_ITEMS = gql`
query getUnsoldItems($auctionid : String!) {
  getUnsoldItems(auctionid: $auctionid) {
    sellerbiddernumber 
    buyerbiddernumber 
    price
    description
    name
    qty
    lastmodifiedbyid
    auctionid
    itemid
    datetime
    lastupdated
    sellername
    buyername
    typeid
		lastmodifiedbyuser
  }
}
`;


export const GET_ITEM_TYPES = gql`
query getAllTypes($companyid : String, $target: Float) {
  getAllTypes(companyid: $companyid, target: $target) {
    name
    typeid
  }
}
`;

