import { useLocation } from "react-router-dom";
import { getLoggedInUser } from "utils/useToken";
import EditProfile from "../components/custom/EditProfile";

const { CardHeader, CardBody, CardFooter, Card, Col, Row } = require("reactstrap");

function Profile(){
  const location = useLocation();
  let userid = getLoggedInUser().userid
  if(location.state) userid = location.state.userid;

  return (
    <div className="content">
    <Row>
        <Col md="12">
          <Card>
            <CardHeader>Profile</CardHeader>
            <CardBody>
              <EditProfile auctionedit={false} userid={userid} />
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
    
      </Row>
    </div>
  )
}

export default Profile;