import EditProfile from "components/custom/EditProfile";
import NewPayment from "components/custom/NewPayment";
import Stats from "components/custom/Stats";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ItemList } from "./auction/itemlist";
import { PaymentList } from "./PaymentList";
import Receipt from "./Receipt";
import { DEACTIVATE_USER } from "graphql/mutations/customers";
import { getLoggedInUser } from "utils/useToken";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import { PopupError } from "utils/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const { CardBody, Card, Col, Row, Button } = require("reactstrap");

function CustomerProfile(props){
  const alert = useAlert();

  const [deactivateUser] = useMutation(DEACTIVATE_USER, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });

  const location = useLocation();
  let history = useHistory();

  const [view, setView] = useState(1);
  const [paymentstats, setPaymentStats] = useState({});
  const [userdata, setUserData] = useState();

  let itemEditMode = false;
  if(location.state.itemeditmode) itemEditMode = true;

  const [profileresult, setProfileResult] = useState([]);

  const redirect = () => {
    history.push({pathname: "/auction/customers", state: {checkout: true}})
  }

  const deactivate = () =>{
    //deactivate customer
    deactivateUser({ variables: { auctionid: getLoggedInUser().auctionid, biddernumber: location.state.edituserdata.biddernumber } })
    .then( (e) => {
      if(!e.errors){
        setProfileResult(['Customer Deactivated Successfully']);
        let auctionlist = userdata.auctionlist.map(e => (e.auctionid === getLoggedInUser().auctionid ? Object.assign({}, e, { active: false }) : e));

        setUserData(prevState => {
          return { ...prevState, auctionlist}
        })
      }
    })
    .catch((err) => setProfileResult(['Customer Deactivated Successfully']));
  }

  useEffect(() => {
    if(profileresult){
      if(profileresult.length > 0){
        profileresult.map((x) => {
          return alert.show(x);
        })
      }
    }
  }, [profileresult, alert]);

  useEffect(() => {
    if(location.state.edituserdata)
      setUserData( () => location.state.edituserdata);
  }, [location.state.edituserdata]);

  return (
    <div className="content">
      <Row className="printhide">
          <Col md="12">
            <Card>
              <CardBody>
                <h4>Select Your View</h4>
                <Button onClick={()=>setView(1)} color="secondary">Customer Information</Button>
                <Button onClick={()=>setView(2)} color="secondary">Customer Item List</Button>
                <Button onClick={()=>setView(3)} color="secondary">Checkout / Stats</Button>
                <Button onClick={deactivate} color="secondary">Deactivate</Button>
                <Button onClick={()=>setView(4)} color="secondary">Print Receipt</Button>
                <Button onClick={redirect} color="secondary">Customer List</Button>

              </CardBody>
            </Card>
          </Col>
        </Row>

      <Row>
        { 
          view === 1 && userdata ? <EditProfile auctionedit={true} auctionmodaldata = {userdata} prevPage={location.state.prevPage} /> :
          view === 2 ? <ItemList editmode = {itemEditMode} biddernumber = {location.state.edituserdata.biddernumber} title={"Customer Item List"} auctionAdmin = {false} />  :
          view === 3 ? 
          <>
            <Col lg="6" md="12" sm = "12" >
              <NewPayment auctionedit={true} editmode = {true} data = {location.state.edituserdata} stats = {paymentstats} />
              <Stats data = {location.state.edituserdata} setPaymentStats = {setPaymentStats} biddernumber={location.state.edituserdata.biddernumber}  page={"newpayment"} />
            </Col>
            <Col lg="6" md="12" sm = "12" >
              <PaymentList editmode = {true} biddernumber = {location.state.edituserdata.biddernumber}  /> 
            </Col>
          </> :
          view === 4 ?  <Receipt biddernumber = {location.state.edituserdata.biddernumber} userid={location.state.edituserdata.userid} />
          : ''
        }
      </Row>
    </div> 
  )
}

export default CustomerProfile;