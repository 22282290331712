import { gql } from '@apollo/client';

export const UPDATE_COMPANY = gql`
mutation updateCompany($thedata : UpdateCompanyInput!) {
  updateCompany(data: $thedata) {
    companyid
  }
}
`;

export const CREATE_COMPANY = gql`
mutation createCompany($thedata : CreateCompanyInput!) {
  createCompany(data: $thedata) {
    companyid
  }
}
`;
