import { gql } from '@apollo/client';

export const GET_STATS = gql`
query getCustomerStats($auctionid : String!, $biddernumber: Float) {
  getCustomerStats(auctionid: $auctionid, biddernumber: $biddernumber) {
    itemboughtcount
    itemsoldcount
    itemboughttotal
    itemsoldtotal
    itemtotal
    totalremaining
    totalpaid
    totalfeespaid
    auctionpaid
    auctionstatus
    auctionbiddernumber
    itemunsoldcount
    auctionfees
    totalremaining
    totalfeespaid
    totalpaid
  }
}
`;

export const GET_AUCTION_STATS = gql`
query getAuctionsStats($auctionid : String!, $companyid : String!) {
  getAuctionsStats(auctionid: $auctionid, companyid: $companyid) {
    itemcount     
    itemboughttotal     
    itemsoldtotal     
    checknumber
    totalcustomerspaid    
    itemunsoldcount     
    paymentfees     
    activecustomers     
    inactivecustomers     
    totalregistered     
    totalcommission     
    totalpaidout      
    totalcollected  
    totalregprofit    
    paymentinfo{
      name
      count
      total
      fees
    }
    iteminfo{
      name
      count
      total
      fees
    }
    customerinfo{
      name
      count
      total
      fees
    }
  }
}
`;