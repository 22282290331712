import { gql } from '@apollo/client';

export const CREATE_AUCTION = gql`
mutation createAuction($thedata : CreateAuctionInput!) {
  createAuction(data: $thedata) {
    auctionid
  }
}
`;


export const UPDATE_AUCTION = gql`
mutation updateAuction($thedata : UpdateAuctionInput!) {
  updateAuction(data: $thedata) {
    auctionid
  }
}
`;
