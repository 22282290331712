import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { getLoggedInUser } from "utils/useToken";
import { GET_STATS } from "graphql/queries/stats";
import { UPDATED_STATS } from "graphql/subscriptions/stats";
import { formatNumber } from "utils/functions";

function Stats(props){
  const { data: sd, loading: sl, error: serror, onError:  sonerror, subscribeToMore, refetch: statRefetch } = useQuery(GET_STATS,{
    variables: {
      companyid: getLoggedInUser().company,
      biddernumber: props.biddernumber,
      auctionid: getLoggedInUser().auctionid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [stats, setStats] = useState();

  useEffect(() => {
    const onCompleted = (sd) => { 
      if(sd){
        setStats( () => sd.getCustomerStats[0]);
        if(props.page === "newpayment")
          props.setPaymentStats(() => sd.getCustomerStats[0]);

          if(props.setPrintReady && props.printReady){
            if(!props.printReady.stats){
              setTimeout( () => 
                props.setPrintReady(prevState => {
                  return { ...prevState, stats: true}
                })  
              , 500);
            }
          }
      }
    }
    if (onCompleted || sonerror) {
      if (onCompleted && !sl && !serror) {
        onCompleted(sd);
      } else if (sonerror && !sl && serror) {
        sonerror(serror);
      }
    }
  }, [sl, sd, serror, sonerror]); //NOTE: DONT ADD PROPS - Causes Memory Leak


//   this.unsubscribe = subscribeToMore({
//     document: <SUBSCRIBE_GRAPHQL>,
//     variables: { test: 'blah' },
//     updateQuery: (prev) => {
//       refetch();
//       return prev;
//     },     
//   });
// }


useEffect(() => {
  let statUpdateUnsubscribe;

   statUpdateUnsubscribe = subscribeToMore({
      document: UPDATED_STATS,
      variables:{auctionid: getLoggedInUser().auctionid, biddernumber:  props.biddernumber},
      updateQuery: (prev, { subscriptionData }) => {
        console.log("UPDATING STATS");
        statRefetch();
        return prev;
      }
    }); 

  if (statUpdateUnsubscribe) return () => statUpdateUnsubscribe()
}, [subscribeToMore, sd, props.biddernumber, statRefetch]);

  return (
    <>
    {stats &&
    <Row>
        <Col md="12">
          <Card>
            <CardHeader tag="h4">Stats <span style={{float:"right"}}>( BIDDER #: {props.biddernumber} )</span> </CardHeader>
            <CardBody>
              <Row>
                <Col className="pr-md-1 col-print-2" md="2">
                  <FormGroup>
                    <label>Items Bought</label>
                    <Input
                      type="text"
                      name="name"
                      value={ stats.itemboughtcount }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col className="pr-md-1 col-print-2" md="2">
                  <FormGroup>
                    <label>Bought Total</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.itemboughttotal) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col className="pr-md-1 col-print-2" md="2">
                  <FormGroup>
                    <label>Items Sold</label>
                    <Input
                      type="text"
                      name="name"
                      value={ stats.itemsoldcount }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
               
                <Col className="pr-md-1 col-print-2" md="2">
                  <FormGroup>
                    <label>Sold Total</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.itemsoldtotal) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Gross Total</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.itemtotal) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              
              <Row>
              <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Total Fees Paid</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.totalfeespaid) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Commission Fee 15%</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.auctionfees) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>

                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Net Total</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.itemtotal - stats.auctionfees) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
               
                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Remaining Total</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.totalremaining) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Paid</label>
                    <Input
                      type="text"
                      name="name"
                      value={ stats.auctionpaid ? 'Yes' : 'No'}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Auction Status</label>
                    <Input
                      type="text"
                      name="name"
                      value={ (stats.auctionstatus) ? 'Active' : 'Inactive' }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Unsold Items</label>
                    <Input
                      type="text"
                      name="name"
                      value={ stats.itemunsoldcount }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-md-1 col-print-3" md="3">
                  <FormGroup>
                    <label>Total Paid</label>
                    <Input
                      type="text"
                      name="name"
                      value={ formatNumber(stats.totalpaid) }
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      </Row>
      }
    </>
  )
}

export default Stats;