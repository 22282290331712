import { gql } from '@apollo/client';

export const GET_PAYMENTS = gql`
query getUserPayments($auctionid : String!, $biddernumber: Float!) {
  getUserPayments(auctionid: $auctionid, biddernumber: $biddernumber) {
    datetime
    method
    methodrate
    amount
    feeamount
    notes
    type
    paymentid
    biddernumber
    auctionid
    referencenumber
    name
  }
}
`;

export const GET_REPORT_PAYMENTS = gql`
query getPaymentReport($auctionid : String!, $methodid: String!) {
  getPaymentReport(auctionid: $auctionid, methodid: $methodid) {
    name
    address
    amount
    feeamount
    refnumber
    transactiontype
  }
}
`;