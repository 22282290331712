
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import CustomerList from './users/CustomerList';
import { getLoggedInUser } from "utils/useToken";
import AddCustomer from "./users/AddCustomer";
import { GET_TYPES } from "graphql/queries/types";
import { useQuery } from "@apollo/client";
import { GET_ALL_COMPANY_USERS } from "graphql/queries/users";
import { USER_COMPANY_ADDED } from "graphql/subscriptions/users";
import { USER_UPDATED } from "graphql/subscriptions/users";
import { SellerType } from "utils/defaults";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Customers()  { 
  let location = useLocation();

  const [view, setView] = useState((location && location.state) ? 2 : 3);
  const [customertypelist, setCustomerTypes] = useState([]);
  const [userlist, setUserList] = useState([{}]);
  const [auctioncustomerlist, setAuctionCustomerList] = useState([]);


  const { data: td, loading: tl, error: terror, onError:  tonerror } = useQuery(GET_TYPES,{
    variables: {
      companyid: getLoggedInUser().company,
      activefilter: true,
      target: 2
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const { data: cud, loading: cul, error: cuerror, onError:  cuonerror, subscribeToMore, refetch } = useQuery(GET_ALL_COMPANY_USERS,{
    variables: {
      companyid: getLoggedInUser().company,
      auctionid: getLoggedInUser().auctionid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [livedataCL, setLiveDataCL] = useState(true);
  const toggleLiveDataCL = () => {
    if(!livedataCL)  refetch()
    setLiveDataCL(!livedataCL); 
  };

  useEffect(() => {
    const onCompleted = (cud) => { 
      if(cud){
        setUserList( () => cud.getAllUserCompany);

        //map auction customer list
        let auctioncustomerlist = [];

        cud.getAllUserCompany.map((value, key) => {
          if(value.auctionlist){
            const main = value.auctionlist.find(a => a.auctionid === getLoggedInUser().auctionid);
              if(main){
                  const a = Object.assign({}, value, {biddernumber: main.biddernumber, auctioncustomerid: main.id})
                  return ( auctioncustomerlist.push(a) );
              }
            }

            return true
          })
            setAuctionCustomerList(() => auctioncustomerlist);
            console.log(auctioncustomerlist)
      }
    }
    if (onCompleted || cuonerror) {
      if (onCompleted && !cul && !cuerror) {
        onCompleted(cud);
      } else if (cuonerror && !cul && cuerror) {
        cuonerror(cuerror);
      }
    }
  }, [cul, cud, cuerror, cuonerror]);

  useEffect(() => {
    const onCompleted = (td) => { 
      if(td){
        setCustomerTypes( () => td.getAllTypes);
        setCustomerTypes(customertypes => [...customertypes, SellerType])
      }
    }
    if (onCompleted || tonerror) {
      if (onCompleted && !tl && !terror) {
        onCompleted(td);
      } else if (tonerror && !tl && terror) {
        tonerror(terror);
      }
    }
  }, [tl, td, terror, tonerror]);

  useEffect(() => {
    let userCreateUnsubscribe;
    if(livedataCL){
     userCreateUnsubscribe = subscribeToMore({
        document: USER_COMPANY_ADDED,
        variables:{companyid: getLoggedInUser().company},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }
          const newUser = subscriptionData.data.userCreated;
          const mergedResullt = [newUser, ...prev.getAllUserCompany];
          const returnResult = Object.assign({}, prev, { getAllUserCompany: mergedResullt })
          return {
            getAllUserCompany: returnResult
          }
        },
      }); 
    }
  
    if (userCreateUnsubscribe) return () => userCreateUnsubscribe()
  }, [subscribeToMore, cud, livedataCL ]);


  useEffect(() => {
    let userUpdateUnsubscribe;

    if(livedataCL){
     userUpdateUnsubscribe = subscribeToMore({
        document: USER_UPDATED,
        variables:{companyid: getLoggedInUser().company},
        updateQuery: (prev, { subscriptionData }) => {
          const newuser = subscriptionData.data.userUpdated;
          let MainList = [...prev.getAllUserCompany];
          const index = MainList.map(user => user.userid).indexOf(newuser.userid);
    
          MainList[index] = newuser;
          return {
            getAllUserCompany: MainList
          }
        }
      }); 
    }

    if (userUpdateUnsubscribe) return () => userUpdateUnsubscribe()
  }, [subscribeToMore, cud, livedataCL]);

      return (
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Select Your View</h4>
                    <Button onClick={()=>setView(1)} color="secondary">Add Customer</Button>
                    <Button onClick={()=>setView(2)} color="secondary">Customers</Button>
                    <Button onClick={()=>setView(3)} color="secondary">All</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {view === 3 ?
              <div>
                <Row>
                  <Col lg="6" md="12" sm = "12" >
                    <AddCustomer autocompleteList = {userlist} customertypes={customertypelist} />
                  </Col>
                  <Col lg="6" md="12" sm = "12" >
                    <CustomerList  rowCount={10} setLiveData={toggleLiveDataCL} liveDataStatus={livedataCL} userlist = {auctioncustomerlist} customertypes={customertypelist}  />
                  </Col>
                </Row>
              </div> : 
              <Row>
                <Col md="12">
                  {
                    view === 1 ? <AddCustomer autocompleteList = {userlist} customertypes={customertypelist} />:
                    view === 2 ?  <CustomerList setLiveData={toggleLiveDataCL} liveDataStatus={livedataCL} userlist = {auctioncustomerlist} customertypes={customertypelist} view={"all"}/> : ''
                  }
                </Col>
              </Row>
            }
          </div>
      );
    }

export default Customers;
