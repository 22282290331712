import React,  { useState, useEffect } from "react";

import { useQuery } from "@apollo/client";
import { 
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CustomInput,  } from "reactstrap";

import { getLoggedInUser } from "utils/useToken";
import { formatDate } from "utils/date";
import XTable from "components/custom/table";
import { GET_PAYMENTS } from "graphql/queries/payment";
import { PAYMENT_ADDED } from "graphql/subscriptions/payments";
import PaymentDetails from "components/custom/PaymentDetails";
import { PAYMENT_DELETED } from "graphql/subscriptions/payments";
import { formatNumber } from "utils/functions";

export const PaymentList = (props) => {
  let getByBidderNumber = 0; 
  if(props.biddernumber) getByBidderNumber = props.biddernumber;

  const [paymentview, setPaymentView] = useState("list");

  const { loading, error, data, subscribeToMore, onError, refetch } = useQuery(GET_PAYMENTS,{
    variables: {
      auctionid: getLoggedInUser().auctionid,
      biddernumber: parseInt(getByBidderNumber),
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

 
  const [paymentlist, setPaymentList] = useState([{}]);

  const [modaldata, setModalData] = useState({});

  const toggle = (t) => {
    let editPaymentID = t.original.paymentid
    if(editPaymentID !== undefined){
      const index = paymentlist.map(item => item.paymentid).indexOf(editPaymentID);
      setModalData(() => paymentlist[index]);
      setPaymentView("item");
    }
  }

  const RowClick = (t) => toggle(t);

  const listHeader = [
    {
      Header: "DateTime",
      className: "t-cell-1 text-centered",
      accessor: "datetime",
      Cell: (propsa) => (
        propsa.value ? <span>{formatDate(propsa.value)}</span> : ''
      )
    },
    {
      Header: "Amount",
      accessor: "amount",
      className: "t-cell-2 text-centered",
      Cell: (propsa) => (
        <span>{propsa.value ? formatNumber(propsa.value) : ""}</span>
      )
    },
    {
      Header: "Fees",
      accessor: "feeamount",
      className: "t-cell-3 text-centered mwTable-10",
      Cell: (propsa) => (
        <span>{propsa.value ? formatNumber(propsa.value) : "0"}</span>
      )
    },
    {
      Header: "Total Paid",
      // accessor: "feeamount",
      className: "t-cell-3 text-centered mwTable-10",
      Cell: (propsa) => (
        <span>
          {<span>{formatNumber(parseFloat(propsa.row.original.amount) +  parseFloat(propsa.row.original.feeamount))} </span>}
        </span>
      )
    },
    {
      Header: "Method",
      accessor: "method",
      className: "t-cell-4 text-center mwTable-15",
      Cell: (propsa) => (
        <span>{propsa.value}</span>
      )
    },
    {
      Header: "Rate",
      accessor: "methodrate",
      className: "t-cell-4 text-center mwTable-10",
      Cell: (propsa) => (
        <span>{propsa.value ? propsa.value.toFixed(2) +  '%' : '0'}</span>
      )
    },
  ];

  if(props.showall) listHeader.splice(1, 0, {
      Header: "Bidder",
      accessor: "biddernumber",
      className: "t-cell-4 text-center mwTable-10"
  })

  if(props.showall) listHeader.splice(1, 0, {
    Header: "Name",
    accessor: "name",
    className: "t-cell-4 text-center mwTable-15"
})

  if(!props.print) listHeader.push(
    {
      Header: "Edit",
      accessor: 'paymentid',
      className: "t-cell-4 text-center mwTable-10 printhide",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (propsa) => (
        <span paymentid={propsa.value} onClick={ () => toggle(propsa.row) }><i className="tim-icons icon-pencil"></i></span>
      )
    },
  )
  
  if(props.print) listHeader.push(
    {
      Header: "Reference Number",
      accessor: 'referencenumber',
      className: "t-cell-4 text-center mwTable-10",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (propsa) => (
        propsa.value ? <span>{propsa.value}</span> : ''
      )
    },
  )


  const [livedata, setLiveData] = useState(true);
  const toggleLiveData = () => {
    if(!livedata)  refetch()
    setLiveData(!livedata);
  };
  
  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        setPaymentList(() => data.getUserPayments);
        
        if(props.setPrintReady && props.printReady){
          if(!props.printReady.payments){
            setTimeout( () => 
              props.setPrintReady(prevState => {
                return { ...prevState, payments: true}
              })  
            , 500);
          }
        }
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]); //NOTE: DONT ADD PROPS - Causes Memory Leak


  useEffect(() => {
    let paymentCreateUnsubscribe;
    if(livedata){

     paymentCreateUnsubscribe = subscribeToMore({
        document: PAYMENT_ADDED,
        variables:{auctionid: getLoggedInUser().auctionid, biddernumber: getByBidderNumber},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newItem = subscriptionData.data.paymentCreated;
          const mergedResullt = [newItem, ...prev.getUserPayments];
          const returnResult = Object.assign({}, prev, { getUserPayments: mergedResullt })
          return {
            getUserPayments: returnResult
          }
        },
      }); 
    }

    if (paymentCreateUnsubscribe) return () => paymentCreateUnsubscribe()
  }, [subscribeToMore, data, livedata, getByBidderNumber]);


  useEffect(() => {
    let paymentDeleteUnsubscribe;

    if(livedata){
     paymentDeleteUnsubscribe = subscribeToMore({
        document: PAYMENT_DELETED,
        variables:{auctionid: getLoggedInUser().auctionid, biddernumber: getByBidderNumber},
        updateQuery: (prev, { subscriptionData }) => {
          const newItem = subscriptionData.data.paymentDeleted;
          console.log("NEWITEM", newItem);
          let OldList = [...prev.getUserPayments];
          console.log("OLDLIST", OldList);
          const newList = OldList.filter((item) => item.paymentid !== newItem);  //ES6
          return {
            getUserPayments: newList
          };
        },
      }); 
    }

    if (paymentDeleteUnsubscribe) return () => paymentDeleteUnsubscribe()
  }, [subscribeToMore, data, livedata, getByBidderNumber]);

  return (
    <>
    {paymentview === 'list' &&
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Customer Payments</CardTitle>
      </CardHeader>
      <CardBody>
        {/* <span className="float-left ">
          <CustomInput
            checked={livedata}
            className="table-search-switch"
            type="switch"
            name="customSwitch233"
            id="customSwitch233"
            onChange={toggleLiveData}
            label="Show Live Data"
          />
        </span> */}

        {paymentlist &&
          <XTable RowClick={RowClick} columns={listHeader} loading={false} data={paymentlist} filterbutton={true} />
        }
      </CardBody>
    </Card>
    }


    {paymentview === 'item' &&
        <PaymentDetails data={modaldata} editmode = {props.editmode} setPaymentView={setPaymentView} />
    }
</>
  );
};

