import Swal from 'sweetalert2'
import validator from 'validator' 

export const validatePhoneNumber = (number) => {
  const isValidPhoneNumber = validator.isMobilePhone(number)
  return (isValidPhoneNumber)
}

export const validateEmail = (email) => {
  const isValidEmail = validator.isEmail(email)
  return (isValidEmail)
}

export const handleInputFocus = (event) => event.target.select();

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
   object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

export const formatNumber = (q) => {
  if(q){
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(q);
  }
  else return 0
 } 

 export function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
charactersLength));
 }
 return result;
}

export const SAlert = (title, input, label, icon, callback = undefined) => {
  Swal.fire({
    title: title,
    icon: icon,
    input: input,
    inputLabel: label,
    showCancelButton: true,
    allowOutsideClick: false,
    inputValidator: (value) => {
      if (!value) {
        return 'Please entered required information'
      }
      else{
        if(isNaN(value) || value.length < 4)
          return 'Please enter a numeric value that has a least four digits';
        else{
          if(callback){
            callback(value);
          }
        }
      }
    }
  })
}

export const PopupError = (errorArray) => {
  let ErrorList = "";
  if(Array.isArray(errorArray)){
    if(errorArray.length > 0){
      errorArray.map((e) => {
        return ErrorList += e + "<br />";
      })
    }
  }
  else{
    ErrorList = errorArray
  }

  SAText("Error", ErrorList, "error");
}

export const SAText = (title, text, icon, callback = undefined) => {
  Swal.fire({
    icon: icon,
    title: title,
    html: text,
  })
  .then(() => {
    if(callback)
      callback();
  })
}

export const SAConfirmReason = (title, text, callback = undefined) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    input: "textarea",
    showCancelButton: true,
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#d33',
    confirmButtonText: 'Agree and Continue',
    inputValidator: (value) => {
      if(value.trim() !== "")
        callback(value)
      else
        return 'Please provide a reason for the rejection of this auction.';
    }
  })
}

export const SAConfirm = (title, text, callback = undefined) => {
  Swal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#d33',
    confirmButtonText: 'Continue'
  }).then((result) => {
    if (result.isConfirmed) {
      if(callback)
        callback();
    }
  })
}