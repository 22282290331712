import { gql } from '@apollo/client';

export const GET_AUCTION_LIST = gql`
query getAllAuctions($companyid : String!) {
  getAllAuctions(companyid: $companyid) {
    auctionid
    nickname
    location
    password
    datetimestart
    datetimeend
    active
    description
    lastmodifiedbyid
    code
  }
}
`;