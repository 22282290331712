import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import { CREATE_ITEM } from "../graphql/mutations/items";
import { useMutation } from "@apollo/client";
import { getLoggedInUser } from "utils/useToken";
import { handleInputFocus } from "utils/functions";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { SAConfirm } from "utils/functions";
import { PopupError } from "utils/functions";

function StressTest(props){

  const [createItem] = useMutation(CREATE_ITEM, {
    onError: (err) =>{
      // PopupError(err.message.split(","));
      console.log(err.message);
    }
  });


  const ConfirmAddItem = () => {
    const BuyerList = [22,23,24,25];
    const ItemListName = [
      "Card Trick",
      "Magic Books",
      "Cups and Balls",
      "Magic Silks",
      "Posters"
    ];

    
    let tmpItemData = {
      auctionid: getLoggedInUser().auctionid,
      buyerbiddernumber: BuyerList[Math.floor(Math.random() * (4 - 0 + 0) + 0)],
      description: "Auto Insert",
      lastmodifiedbyid: getLoggedInUser().userid,
      name: ItemListName[Math.floor(Math.random() * (4 - 0 + 0) + 0)],
      price: Math.floor(Math.random() * (100 - 1 + 1) + 1),
      qty: 1,
      sellerbiddernumber: 3,
      typeid: "0"
    };

    console.log(tmpItemData);

    createItem({ variables: { thedata: tmpItemData } })
    .then((data2) => {
      console.log("Success")
     }
    )
    .catch((err) => console.log(err));

    TheTimer = setTimeout(() => {
      ConfirmAddItem();
    }, 30000);
  }

  let TheTimer;
const StartStress = () => {
  ConfirmAddItem();
}

const StopStress = () => {
  window.location.href = "/stress-test";
}

  return (
  <div className="content">
    <Row style={{'margin': '0'}}>
      <Col lg="3" md="8" sm = "12">
        <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Stress Test
          </CardTitle>
        </CardHeader>
          <CardBody>
          <Button 
            className="btn-fill" 
            color="info" 
            type="button" 
            onClick={e => {
              e.preventDefault();
              StartStress();
            }}
            >
              Start Stress Test
            </Button>

            <Button 
            className="btn-fill" 
            color="info" 
            type="button" 
            onClick={e => {
              e.preventDefault();
              StopStress();
            }}
            >
              Stop Stress Test
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
  )
}
export default StressTest;