
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { formatDate } from "utils/date";
import { DELETE_PAYMENT } from "graphql/mutations/payment";

function PaymentDetails(props){
  const alert = useAlert();

  let [deletePayment] = useMutation(DELETE_PAYMENT);

  const [result, setResult] = useState([]);

  const DeletePayment = (paymentid) => {
    deletePayment({
      variables: {thedata: paymentid}
    }).then( () => {
      setResult(() => ["Payment Deleted Successfully"]);
      props.setPaymentView("list");
      }
    );
  }

useEffect(() => {
  if(result){
    if(result.length > 0){
      result.map((x) => {
        return alert.show(x);
      })
    }
  }
}, [result, alert]);

  return (
    <>
    {props.data &&
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Payment Info</CardTitle>
          <Button onClick={()=>props.setPaymentView("list")} color="secondary">Back to Payment List</Button>
        </CardHeader>
        <CardBody>
          <Form>
              <Row>
              <Col md="12">
                <Card>
                <CardBody>
                <Row>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              type="text"
                              name="name"
                              value={props.data.name}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>Bidder Number</label>
                            <Input
                              type="text"
                              name="name"
                              value={props.data.biddernumber}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                    
                      </Row>

                      <Row>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>DateTime</label>
                            <Input
                              type="text"
                              name="name"
                              value={formatDate(props.data.datetime)}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>Method</label>
                            <Input
                              type="text"
                              name="name"
                              value={props.data.method}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>Payment Type</label>
                            <Input
                              type="text"
                              name="name"
                              value={ props.data.type }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label> Amount </label>
                            <Input
                              placeholder="0" 
                              type="number" 
                              step="0.01"
                              name="amount"
                              autoComplete = "off"
                              value= {props.data.amount.toFixed(2) }
                              readOnly={true}
                              />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>Reference #</label>
                            <Input
                              value={props.data.referencenumber}
                              placeholder="0000"
                              type="number"
                              readOnly={true}
                              name="referencenumber"
                              autoComplete = "off"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>Fees from Payment</label>
                            <Input
                              type="number"
                              name="paymentfees"
                              value={props.data.feeamount.toFixed(2)}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>

                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Notes</label>
                            <Input
                              cols="80"
                              value={props.data.notes}
                              placeholder="Enter Notes"
                              rows="4"
                              type="textarea"
                              name="notes"
                              readOnly={true}
                              autoComplete = "off"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                  </CardBody>
                </Card>
              </Col>
              </Row>
          </Form>
        </CardBody>
        <CardFooter>
          {props.editmode &&
            <>
              <Button style={{float:"right"}} color="danger" onClick={() => DeletePayment(props.data.paymentid)}>Delete Payment</Button>
            </>
          }
        </CardFooter>
      </Card>
    }
  </>
  )
}

export default PaymentDetails;