import { gql } from '@apollo/client';

export const CREATE_ITEM = gql`
mutation createItem($thedata : CreateItemInput!) {
  createItem(data: $thedata) {
    success
  }
}
`;


export const UPDATE_ITEM = gql`
mutation updateItem($thedata : UpdateItemInput!) {
  updateItem(data: $thedata) {
    itemid
  }
}
`;


export const DELETE_ITEM = gql`
mutation deleteItem($thedata : String!) {
  deleteItem(itemid: $thedata) 

}
`;
