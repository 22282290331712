
import { useMutation } from "@apollo/client";
import { DELETE_ITEM } from "graphql/mutations/items";
import { useAlert } from "react-alert";
import { UPDATE_ITEM } from "graphql/mutations/items";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { formatDate } from "utils/date";
import { handleInputFocus } from "utils/functions";
import { getLoggedInUser } from "utils/useToken";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { PopupError } from "utils/functions";

function Item(props){
  const alert = useAlert();
  const inputRef = useRef()

  let [updateItem] = useMutation(UPDATE_ITEM, {
    onError: (err) =>{
      PopupError(err.message.split(","));
    }
  });
  let [deleteItem] = useMutation(DELETE_ITEM);

  const [result, setResult] = useState([]);
  const [itemdata, setItemData] = useState({});

  const UpdateItem = (itemid) => {
    setResult([]);
    const updateitem = {
      itemid: itemid,
      auctionid: getLoggedInUser().auctionid,
      name: itemdata.buyerbiddernumber > 0 ? itemdata.name : itemdata.name + " - No Sale" ,
      price: itemdata.buyerbiddernumber > 0 ? parseFloat(itemdata.price) : 0,
      qty: itemdata.qty,
      buyerbiddernumber: itemdata.buyerbiddernumber,
      sellerbiddernumber: itemdata.sellerbiddernumber,
      description: itemdata.description,
      lastmodifiedbyid: getLoggedInUser().userid,
      typeid: itemdata.typeid,
      lastmodifiedbyuser:getLoggedInUser().name
    };

    if(updateitem.buyerbiddernumber > 0 && updateitem.name.includes("- No Sale")){
      updateitem.name = updateitem.name.replace(" - No Sale", "");
    }

    updateItem({
      variables: {thedata: updateitem}
    }).then((data2) => {
      if(!data2.errors){
        setResult(() => ["Item Updated Successfully"]);
        props.setItemView("list")
        props.setFocus(!props.itemUpdated);
      }
    })
  }

  const DeleteItem = (itemid) => {
    deleteItem({
      variables: {thedata: itemid}
    }).then( () => {
      setResult(() => ["Item Deleted Successfully"]);
      props.setItemView("list");
      }
    );
  }

  useEffect(() => {
    if(props.itemdata) {
      setItemData(props.itemdata);
    }
}, [props.itemdata]);

useEffect(() => {
  if(result){
    if(result.length > 0){
      result.map((x) => {
        return alert.show(x);
      })
    }
  }
}, [result, alert]);

  return (
    <>
    {props.itemdata &&
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Item Info</CardTitle>
          <Button onClick={()=>props.setItemView("list")} color="secondary">Back to Item List</Button>
        </CardHeader>
        <CardBody>
          <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => UpdateItem(itemdata.itemid) }>
            <Form>
                <Row>
                <Col md="12">
                  <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Item</CardTitle>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <span>Buyer: {itemdata.buyername ? itemdata.buyername : 'Not on Record'}</span> <br />
                        <span>Seller: {itemdata.sellername ? itemdata.sellername : 'Not on Record'}</span>
                      </Col>

                      <Col className="pr-md-1" md="6">
                        <span>DateTime: {formatDate(itemdata.datetime)}</span> <br />
                        <span>Modified By: {itemdata.lastmodifiedbyuser}</span>
                      </Col>
                    </Row>
                  </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col className="pr-md-1" md="6">
                            <FormGroup>
                              <label>Auction ID (disabled)</label>
                              <Input
                                defaultValue={getLoggedInUser().auctionid}
                                disabled
                                placeholder="Auction"
                                type="text"
                                autoComplete = "off"
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-1" md="6">
                            <FormGroup>
                              <label>Auction Name (disabled)</label>
                              <Input
                                defaultValue={ getLoggedInUser().auctionlist[(getLoggedInUser().auctionlist).map(a => a.auctionid).indexOf(getLoggedInUser().auctionid)].auctionname }
                                disabled
                                placeholder="Auction"
                                type="text"
                                autoComplete = "off"
                              />
                            </FormGroup>
                          </Col>
                          </Row>
                          <Row>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>* Item: </label>
                              <input
                                className="form-control"
                                autoFocus
                                ref={inputRef}
                                placeholder="Item Name"
                                type="text"
                                name="name"
                                value={itemdata.name}
                                readOnly={!props.editmode}
                                autoComplete = "off"
                                onChange={ (e) => {
                                  setItemData(prevState => {
                                    return { ...prevState, name: e.target.value}
                                  })
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pl-md-1" md="4">
                            <FormGroup>
                              <label>* Current Seller: 
                                {/* {itemdata.sellername && <span style={{'font-size': 'smaller'}}>( {itemdata.sellername} )</span> }  */}
                              </label>
                              <Input
                                value={itemdata.sellerbiddernumber}
                                placeholder="Seller Number"
                                onFocus={handleInputFocus}
                                type="number"
                                readOnly={!props.editmode}
                                name="sellerbiddernumber"
                                autoComplete = "off"
                                onChange={ (e) => {
                                  if(e.target.value !== ""){
                                    setItemData(prevState => {
                                      return { ...prevState, sellerbiddernumber: parseInt(e.target.value)}
                                    })
                                  }
                                  else{
                                    setItemData(prevState => {
                                      return { ...prevState, sellerbiddernumber: parseInt(0)}
                                    })
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <label>* Quantity:</label>
                              <Input
                                value={itemdata.qty}
                                placeholder="Qty"
                                type="number"
                                readOnly={!props.editmode}
                                onFocus={handleInputFocus}
                                name="qty"
                                autoComplete = "off"
                                onChange={ (e) => {
                                  if(e.target.value !== ""){
                                    setItemData(prevState => {
                                      return { ...prevState, qty: parseInt(e.target.value)}
                                    })
                                  }
                                  else{
                                    setItemData(prevState => {
                                      return { ...prevState, qty: parseInt(1)}
                                    })
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-md-1" md="4">
                            <FormGroup>
                              <label>Current Buyer:
                                {/* {itemdata.buyername && <span style={{'font-size': 'smaller'}}>( {itemdata.buyername} ) </span> } */}
                              </label>
                              <Input
                                value={itemdata.buyerbiddernumber}
                                placeholder="Buyer Number"
                                type="number"
                                name="buyerbiddernumber"
                                readOnly={!props.editmode}
                                onFocus={handleInputFocus}
                                autoComplete = "off"
                                onChange={ (e) => {
                                  if(e.target.value !== ""){
                                    setItemData(prevState => {
                                      return { ...prevState, buyerbiddernumber: parseInt(e.target.value)}
                                    })
                                  }
                                  else{
                                    setItemData(prevState => {
                                      return { ...prevState, buyerbiddernumber: parseInt(0)}
                                    })
                                  }
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pl-md-1" md="4">
                            <FormGroup>
                              <label >
                               * Price:
                              </label>
                              <Input
                                placeholder="0" 
                                type="number" 
                                step="0.01"
                                name="price"
                                readOnly={!props.editmode}
                                onFocus={handleInputFocus}
                                autoComplete = "off"
                                value= {itemdata.price ? itemdata.price: 0}
                                onChange={(e) => {
                                  if(e.target.value !== ""){
                                    setItemData(prevState => {
                                      return { ...prevState, price: parseFloat(e.target.value)}
                                    })
                                  }
                                  else{
                                    setItemData(prevState => {
                                      return { ...prevState, price: parseFloat(0)}
                                    })
                                  }
                                }}
                                />
                            </FormGroup>
                          </Col>
                          <Col className="pl-md-1" md="4">
                            <FormGroup>
                              <label>Type:</label>
                              <select className='form-control'
                                name="typeid"
                                value={itemdata.typeid}
                                readOnly={!props.editmode}
                                onChange={ (e) => {
                                  setItemData(prevState => {
                                    return { ...prevState, typeid: e.target.value}
                                  })
                                }}
                              >
                                <option value='0'>Normal</option>
                                {props.types && 
                                    props.types.getAllTypes.map((type) => {
                                    return(
                                      <option
                                      key={type.typeid} value={type.typeid}>{type.name}</option>
                                    )
                                  })
                                }
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Description (Shift + Enter for new line):</label>
                              <Input
                                cols="80"
                                value={itemdata.description}
                                placeholder="Here can be your description"
                                rows="4"
                                type="textarea"
                                name="description"
                                readOnly={!props.editmode}
                                autoComplete = "off"
                                onChange={ (e) => {
                                  setItemData(prevState => {
                                    return { ...prevState, description: e.target.value}
                                  })
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
            </Form>
          </KeyboardEventHandler>
        </CardBody>
        <CardFooter>
          {props.editmode &&
            <>
              <Button 
                className="btn-fill" 
                color="info" 
                type="button" 
                onClick = {() => UpdateItem(itemdata.itemid)}
              >Update Item </Button> 
              <Button style={{float:"right"}} color="danger" onClick={() => DeleteItem(itemdata.itemid)}>Delete Item</Button>
            </>
          }
        </CardFooter>
      </Card>
    }
  </>
  )
}

export default Item;