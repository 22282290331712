import { gql } from '@apollo/client';

export const AUCTION_ADDED = gql`
subscription AuctionCreated($companyid: String!) {
  auctionCreated(companyid: $companyid) {
    auctionid
    nickname
    location
    password
    datetimestart
    datetimeend
    active
    description
    lastmodifiedbyid
  }
}
`;

export const AUCTION_UPDATED = gql`
subscription AuctionUpdated($companyid: String!) {
  auctionUpdated(companyid: $companyid){
    auctionid
    nickname
    location
    password
    datetimestart
    datetimeend
    active
    description
    lastmodifiedbyid
  } 
}
`;
