import React,  { useState, useEffect } from "react";
import {ITEM_ADDED, ITEM_DELETED, ITEM_DELETED_ADMIN, ITEM_UPDATED } from "../../graphql/subscriptions/items";
import { GET_ALL_ITEMS } from "../../graphql/queries/items";

import { useQuery } from "@apollo/client";
import { 
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  } from "reactstrap";

import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import XTable from "../../components/custom/table";
import { formatNumber } from "utils/functions";
import { getLoggedInUser } from "utils/useToken";

export const DBItemList = (props) => {
  let getByBidderNumber = 0; 
  if(props.biddernumber) getByBidderNumber = props.biddernumber;

  const { loading, error, data, subscribeToMore, onError } = useQuery(GET_ALL_ITEMS,{
    variables: {
      auctionid: props.auctionid,
      biddernumber: parseInt(getByBidderNumber),
      limit: 5,
    },
    skip: !props.auctionid ? true : false,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [itemlist, setItemList] = useState([{}]);

  const listHeader = [
    {
      Header: "Item",
      className: "t-cell-1 text-centered db-wrap",
      accessor: "name",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      // getCellProps: (state, rowInfo, column) => {
      //   return {
      //     style:{
      //       color:"red"
      //       // wordBreak: "break-all"
      //     }
      //   };
      // },
    },
    {
      Header: "Price",
      accessor: "price",
      className: "t-cell-2 text-centered",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
      Cell: (props) =>(
        <div>
            {formatNumber(props.value * props.row.original.qty)} 
            {props.row.original.qty > 1 && 
              <span style={{fontSize:"20px"}}> ({props.row.original.qty})</span>
            }
          </div>
      )
    },
    {
      Header: "Buyer",
      accessor: "buyerbiddernumber",
      className: "t-cell-3 text-centered mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
    },
    {
      Header: "Seller",
      accessor: "sellerbiddernumber",
      className: "t-cell-4 text-center mwTable-15",
      disableFilters: true,
      disableSortBy: true,
      notShowSortingDisplay: true,
    },
  ];

  useEffect(() => {
    const onCompleted = (data) => { 
      if(data){
        let itemarray = [];

        data.getAllItems.map((item) => {
          return(
            itemarray.push({
              sellerbiddernumber:item.sellerbiddernumber, 
              buyerbiddernumber :item.buyerbiddernumber,
              price: item.price.toFixed(2),
              description: item.description,
              name: item.name,
              qty: item.qty,
              lastmodifiedbyid: item.lastmodifiedbyid,
              auctionid: item.auctionid,
              itemid: item.itemid,
              datetime: item.datetime,
              lastupdated: item.lastupdated,
              sellername: item.sellername,
              buyername: item.buyername,
              typeid: item.typeid,
              lastmodifiedbyuser: item.lastmodifiedbyuser
            })
          )
        })

        setItemList(() => itemarray);
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data);
      } else if (onError && !loading && error) {
        onError(error);
      }
    }
  }, [loading, data, error, onError]);


  useEffect(() => {
    let itemCreateUnsubscribe;
    if(props.auctionid){
     itemCreateUnsubscribe = subscribeToMore({
        document: ITEM_ADDED,
        variables:{auctionid: props.auctionid, biddernumber: getByBidderNumber},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newItem = subscriptionData.data.itemCreated;
          const mergedResullt = [newItem, ...prev.getAllItems];
          const returnResult = Object.assign({}, prev, { getAllItems: mergedResullt })
          return {
            getAllItems: returnResult
          }
        },
      }); 
    }

    if (itemCreateUnsubscribe) return () => itemCreateUnsubscribe()
  }, [subscribeToMore, data, getByBidderNumber, props.auctionid]);

  
  useEffect(() => {
    let itemDeleteUnsubscribe;

    if(props.auctionid){
      itemDeleteUnsubscribe = subscribeToMore({
        document: ITEM_DELETED_ADMIN,
        variables:{auctionid: getLoggedInUser().auctionid, biddernumber: getByBidderNumber},
        updateQuery: (prev, { subscriptionData }) => {
          const newItem = subscriptionData.data.itemDeletedAdmin;
          let OldList = [...prev.getAllItems];
          const newList = OldList.filter((item) => item.itemid !== newItem);  //ES6
          return {
            getAllItems: newList
          };
        },
      }); 
    }

    if (itemDeleteUnsubscribe) return () => itemDeleteUnsubscribe()
  }, [subscribeToMore, data,  getByBidderNumber, props.auctionid]);
  
  
  useEffect(() => {
    let itemUpdateUnsubscribe;

    if(props.auctionid){
     itemUpdateUnsubscribe = subscribeToMore({
        document: ITEM_UPDATED,
        variables:{auctionid: props.auctionid, biddernumber: getByBidderNumber},
        skip: !props.auctionid ? true : false,
        updateQuery: (prev, { subscriptionData }) => {
          const newItem = subscriptionData.data.itemUpdated;
          console.log("UPDATED ITEM")
          let MainList = [...prev.getAllItems];
          const index = MainList.map(item => item.itemid).indexOf(newItem.itemid);
          if(index === -1)
            MainList.push(newItem);
          else
            MainList[index] = newItem;
          return {
            getAllItems: MainList
          }
        }
      }); 
    }

    if (itemUpdateUnsubscribe) return () => itemUpdateUnsubscribe()
  }, [subscribeToMore, data,  getByBidderNumber, props.auctionid]);


  return (
    <>
    <Card>
      <CardHeader>
        <CardTitle tag="h4">{props.title}</CardTitle>
      </CardHeader>
      <CardBody className='DBTable'>
        <XTable columns={listHeader} loading={false} data={itemlist} filterbutton={false} />
      </CardBody>
    </Card>
  </>
  );
};

