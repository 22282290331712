import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { getLoggedInUser } from "utils/useToken";
import { GET_AUCTION_STATS } from "graphql/queries/stats";
import { formatNumber } from "utils/functions";
import { UPDATED_AUCTIONSTATS } from "graphql/subscriptions/stats";

function AuctionStats(props){
  const { data: sd, loading: sl, error: serror, onError:  sonerror, subscribeToMore, refetch: statRefetch } = useQuery(GET_AUCTION_STATS,{
    variables: {
      companyid: getLoggedInUser().company,
      auctionid: getLoggedInUser().auctionid,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-and-network',
  });

  const [stats, setStats] = useState();

  useEffect(() => {
    const onCompleted = (sd) => { 
      if(sd){
        setStats( () => sd.getAuctionsStats);
      }
    }
    if (onCompleted || sonerror) {
      if (onCompleted && !sl && !serror) {
        onCompleted(sd);
      } else if (sonerror && !sl && serror) {
        sonerror(serror);
      }
    }
  }, [sl, sd, serror, sonerror]);


useEffect(() => {
  let statUpdateUnsubscribe;

   statUpdateUnsubscribe = subscribeToMore({
      document: UPDATED_AUCTIONSTATS,
      variables:{auctionid: getLoggedInUser().auctionid},
      updateQuery: (prev, { subscriptionData }) => {
        console.log("UPDATING STATS");
        statRefetch();
        return prev;
      }
    }); 

  if (statUpdateUnsubscribe) return () => statUpdateUnsubscribe()
}, [subscribeToMore, sd, statRefetch]);

  return (
    <>
      {stats &&
        <Row>
          <Col md="12">
            <Card>
              <CardHeader tag="h4">Auction Stats</CardHeader>
              <CardBody>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Total Items</label>
                      <Input
                        type="text"
                        name="name"
                        value={ stats.itemcount }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Active Customers</label>
                      <Input
                        type="text"
                        name="name"
                        value={ stats.activecustomers }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Deactivated Customers</label>
                      <Input
                        type="text"
                        name="name"
                        value={ stats.inactivecustomers }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Registered Customers</label>
                      <Input
                        type="text"
                        name="name"
                        value={ stats.totalregistered }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Commision</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.totalcommission) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Registration Fees</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.totalregprofit) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Item Total</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.itemboughttotal) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  {/* <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Sold Total</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.itemsoldtotal) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col> */}

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Unsold Items</label>
                      <Input
                        type="text"
                        name="name"
                        value={ stats.itemunsoldcount }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Total Paid Customers</label>
                      <Input
                        type="text"
                        name="name"
                        value={ stats.totalcustomerspaid }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Total Collected</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.totalcollected) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Total Paid Out</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.totalpaidout) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Audit Check |  <span style={{fontSize:"smaller", color:"lime"}}>Check Audit For End Auction</span></label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.checknumber) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  {/* <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Collection Remaming</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.totalcollectionremaining) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>

                <Row>
                  {/* <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Payout Remaining</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.totalpayoutremaining) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col> */}

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Payment Fees</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber(stats.paymentfees) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Profit Before Expenses</label>
                      <Input
                        type="text"
                        name="name"
                        value={ formatNumber( (parseFloat(stats.totalcommission) + parseFloat(stats.totalregprofit)) ) }
                        readOnly={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                
                {stats.paymentinfo.length > 0 &&
                  <Row>
                    <Col className="pr-md-1" md="3"><label>Payment Activity</label></Col>
                    <Col className="pr-md-1" md="3"><label>Count</label></Col>
                    <Col className="pr-md-1" md="3"><label>Total</label></Col>
                    <Col className="pr-md-1" md="3"><label>Fees</label></Col>
                  </Row>
                }
                {stats.paymentinfo.length > 0 &&
                  stats.paymentinfo.map((p) => {
                    return(
                      <Row key={p.name}>
                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ p.name }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ p.count }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ formatNumber(p.total) }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ formatNumber(p.fees) }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )
                  })
                }

                {stats.iteminfo.length > 0 &&
                  <Row>
                    <Col className="pr-md-1" md="4"><label>Item Activity</label></Col>
                    <Col className="pr-md-1" md="4"><label>Count</label></Col>
                    <Col className="pr-md-1" md="4"><label>Total</label></Col>
                  </Row>
                }
                {stats.iteminfo.length > 0 &&
                  stats.iteminfo.map((p) => {
                    return(
                      <Row key={p.name}>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ p.name }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ p.count }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ formatNumber(p.total) }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )
                  })
                }

                {stats.customerinfo.length > 0 &&
                  <Row>
                    <Col className="pr-md-1" md="4"><label>Customer Activity</label></Col>
                    <Col className="pr-md-1" md="4"><label>Count</label></Col>
                  </Row>
                }
                {stats.customerinfo.length > 0 &&
                  stats.customerinfo.map((p) => {
                    return(
                      <Row key={p.name}>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ p.name }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <Input
                              type="text"
                              name="name"
                              value={ p.count }
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )
                  })
                }
        
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
      }
    </>
  )
}

export default AuctionStats;